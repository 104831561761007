import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import PropTypes from 'prop-types'
import { capitalizeFirstLetter } from '../common/utils'

import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { getDevices, useMounted } from '../common/restAPI'

const lookupAttr = (item, key) =>
  item
    .getAttributesList()
    .map((attribute) => attribute.split(':'))
    .find(([k, value]) => k === key)?.[1]

function hasAttrAndValue(item, key) {
  for (const element of item.getAttributesList()) {
    if (element == key) {
      return true
    }
  }
  return false
}

const getValueAsCheck = (item, key) => (hasAttrAndValue(item, key) ? '✔️' : '')

const supportsQnn = (device) => hasAttrAndValue(device, 'framework:qnn')

function getFormFactor(device) {
  const format = lookupAttr(device, 'format')

  switch (format) {
    case undefined:
      return
    case 'xr':
      return 'XR'
    case 'iot':
      return 'IoT'
    case 'phone':
    case 'tablet':
      return 'Mobile'
    default:
      return capitalizeFirstLetter(format)
  }
}

function sortDevicesByFormFactorThenName(a, b) {
  const formFactorA = getFormFactor(a)
  const formFactorB = getFormFactor(b)

  if (formFactorA === formFactorB) {
    return a.getName().localeCompare(b.getName())
  }

  return formFactorA.localeCompare(formFactorB)
}

export default function ViewDevices() {
  const [deviceList, setDeviceList] = useState([])

  useEffect(() => {
    document.title = 'View Devices | AI Hub'
  }, [])

  useEffect(() => {
    let [mountState, tearDownMounted] = useMounted()
    let defaultParams = {}
    getDevices(
      mountState,
      (deviceListPb) => {
        setDeviceList(deviceListPb.getDevicesList())
      },
      (err) => {
        console.log(err)
      },
      defaultParams,
    )

    return tearDownMounted
  }, [])

  const qualcommDevices = deviceList
    .filter((device) => device.getAttributesList().some((attribute) => attribute.startsWith('chipset:qualcomm')))
    .filter((device) => getFormFactor(device) !== undefined)
    .sort(sortDevicesByFormFactorThenName)

  return (
    <div className="main" data-testid="viewdevices-main">
      <Typography variant="h5" style={{ marginBottom: '0.5em' }}>
        Devices
      </Typography>

      <TableContainer component={Paper}>
        <Table className="table">
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2}>Device Type</TableCell>
              <TableCell rowSpan={2}>Device Name</TableCell>
              <TableCell rowSpan={2}>Chipset</TableCell>
              <TableCell rowSpan={2}>Operating System</TableCell>
              <TableCell colSpan={3} align="center">
                Runtime Support
              </TableCell>
              <TableCell colSpan={2} align="center">
                Hexagon Tensor Processor
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">TensorFlow Lite</TableCell>
              <TableCell align="center">ONNX Runtime</TableCell>
              <TableCell align="center">AI Engine Direct (QNN)</TableCell>
              <TableCell>Fp16 Precision</TableCell>
              <TableCell>Version</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {qualcommDevices.map((device, index) => (
              <DeviceRow key={`${device.getName()}_${index}`} device={device} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

function DeviceRow({ device }) {
  const formFactor = getFormFactor(device)
  const name = device.getName()
  const description = device.getSocDescription()
  const os = `${capitalizeFirstLetter(lookupAttr(device, 'os'))} ${device.getOs()}`
  const qnn = getValueAsCheck(device, 'framework:qnn')
  const onnx = getValueAsCheck(device, 'framework:onnx')
  const tflite = getValueAsCheck(device, 'framework:tflite')
  const hexagon = lookupAttr(device, 'hexagon')
  const computeTypes = getValueAsCheck(device, 'htp-supports-fp16:true')

  return (
    <TableRow>
      <TableCell>{formFactor}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>{os}</TableCell>
      <TableCell align="center">{tflite}</TableCell>
      <TableCell align="center">{onnx}</TableCell>
      <TableCell align="center">{qnn}</TableCell>
      <TableCell align="center">{computeTypes}</TableCell>
      <TableCell>{hexagon}</TableCell>
    </TableRow>
  )
}

DeviceRow.propTypes = {
  device: PropTypes.object.isRequired,
}
