import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ArticleIcon from '@mui/icons-material/Article'
import DownloadIcon from '@mui/icons-material/Download'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HandymanRounded from '@mui/icons-material/HandymanRounded'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import PersonIcon from '@mui/icons-material/Person'
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { downloadFile, getJob, getModel, useMounted } from '../common/restAPI'
import { HeaderPaper, modelMetadataKeyToString, modelTypeToString } from '../common/utils'
import ApiPB from '../qai_hub/public_api_pb'
import { NotFoundPage } from '../routes/not-found-page'
import ListItemWithIcon from './ListItemWithIcon'

export default function ViewModel(props) {
  const modelId = props.modelId
  const [modelPb, setModelPb] = useState()
  const [jobPb, setJobPb] = useState()
  const [trigger404, setTrigger404] = useState(false)

  useEffect(() => {
    let [mountState, tearDownMounted] = useMounted()

    getModel(
      modelId,
      mountState,
      (modelPb) => {
        console.log('Producer', modelPb.getProducerId())
        setModelPb(modelPb)
        document.title = `${modelPb.getName()} Model (${modelTypeToString(modelPb.getModelType())}) | AI Hub`
        getJob(
          modelPb.getProducerId(),
          mountState,
          (jobPb) => {
            if (jobPb.getJobCase() === ApiPB.Job.JobCase.COMPILE_JOB) {
              setJobPb(jobPb.getCompileJob())
            } else if (jobPb.getJobCase() === ApiPB.Job.JobCase.LINK_JOB) {
              setJobPb(jobPb.getLinkJob())
            } else if (jobPb.getJobCase() === ApiPB.Job.JobCase.QUANTIZE_JOB) {
              setJobPb(jobPb.getQuantizeJob())
            }
          },
          () => {},
        )
      },
      () => {
        setTrigger404(true)
      },
    )

    return tearDownMounted
  }, [])

  const downloadModel = (e) => {
    e.preventDefault()
    downloadFile(`models/${modelId}/download/`)
    return false
  }

  if (trigger404) {
    return <NotFoundPage />
  } else if (modelPb) {
    const metadata = modelPb.getMetadata()?.getEntriesMap()
    const metadataEntries = metadata
      ?.getEntryList()
      .map(([keyInt, value], index) => [modelMetadataKeyToString(keyInt), value])
      .sort()
    return (
      <div className="main">
        <Typography variant="h5" style={{ marginBottom: '0.5em' }}>
          Model
        </Typography>
        <HeaderPaper>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100%', minWidth: '300px', whiteSpace: 'nowrap' }}
          >
            <Grid container columns={{ xs: 6, sm: 12 }}>
              <Grid item align="left" xs={6} sx={{ pt: 0.4 }}>
                <Breadcrumbs
                  component={'div'}
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link to="/models/" variant="primary" style={{ marginLeft: '8px' }}>
                    Models
                  </Link>
                  <span className="idbox">{modelPb.getModelId()}</span>
                </Breadcrumbs>
              </Grid>
              <Grid item align="right" xs zeroMinWidth style={{ whiteSpace: 'nowrap' }}>
                <div style={{ display: 'flex', alignItems: 'right', justifyContent: 'right' }}>
                  {
                    <div className="vertical-align">
                      <Button
                        onClick={downloadModel}
                        sx={{ pt: 0.3, pb: 0.3, display: { xs: 'none', sm: 'flex' } }}
                        size="small"
                        startIcon={<DownloadIcon />}
                        variant="outlined"
                      >
                        Download
                      </Button>
                    </div>
                  }
                </div>
              </Grid>
            </Grid>
          </Grid>
        </HeaderPaper>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  <ListItemWithIcon caption="Name" icon={<ArticleIcon />}>
                    <Typography color="black">{modelPb.getName()}</Typography>
                  </ListItemWithIcon>
                </List>
              </Grid>
              <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  <ListItemWithIcon caption="Creator" icon={<PersonIcon />}>
                    <Typography color="black">{modelPb.getOwner().getEmail()}</Typography>
                  </ListItemWithIcon>
                </List>
              </Grid>
              <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  <ListItemWithIcon caption="Creation Time" icon={<AccessTimeIcon />}>
                    <Typography>{modelPb.getCreationTime().toDate().toLocaleString()}</Typography>
                  </ListItemWithIcon>
                </List>
              </Grid>
              <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                  <ListItemWithIcon caption="Type" icon={<TypeSpecimenIcon />}>
                    <Typography color="black">{modelTypeToString(modelPb.getModelType())}</Typography>
                  </ListItemWithIcon>
                </List>
              </Grid>
              {jobPb && (
                <Grid item align="left" lg={4} sm={6} xs={12} columns={12} style={{ paddingTop: '0px' }}>
                  <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItemWithIcon caption="Producing Job" icon={<HandymanRounded />}>
                      <div className="vertical-align-elements">
                        <div className="scrollable">
                          <Link to={`/jobs/${modelPb.getProducerId()}/`}>{jobPb.getName()}</Link>
                        </div>
                        <div style={{ marginLeft: '5px', display: 'inline', fontSize: '0.9em' }}>
                          <span className="idbox">{modelPb.getProducerId()}</span>
                        </div>
                      </div>
                    </ListItemWithIcon>
                  </List>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        {metadataEntries && metadataEntries.length > 0 && (
          <Accordion id="metadataPanel">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography>Metadata</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ overflow: 'hidden' }}>
              <Table className="table" style={{ tableLayout: 'auto' }}>
                <TableHead>
                  <TableRow key="header">
                    <TableCell>Key</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metadataEntries.map(([key, value], index) => (
                    <TableRow key={key}>
                      <TableCell>
                        <div className="ellipsis" title={key}>
                          {key}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="ellipsis" title={value}>
                          {value}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    )
  } else {
    return <div className="main"></div>
  }
}

ViewModel.propTypes = {
  modelId: PropTypes.string.isRequired,
}
