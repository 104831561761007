import LoopIcon from '@mui/icons-material/Loop'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { useIntervalEffect } from '../common/hooks/use-interval-effect'
import { getJobSummaries, useMounted } from '../common/restAPI'
import { RUNNING_JOB_STATES } from '../common/utils'
import { NotFoundPage } from '../routes/not-found-page'
import TableCompileJobs from './TableCompileJobs'
import TableInferenceJobs from './TableInferenceJobs'
import TableLinkJobs from './TableLinkJobs'
import TableProfileJobs from './TableProfileJobs'
import TableQuantizeJobs from './TableQuantizeJobs'

const cookies = new Cookies()
const rowsPerPageCookieName = 'rowsPerPage-ListJobs'

function SpinningLoopIcon() {
  return (
    <LoopIcon
      sx={{
        'width': '18px',
        'animation': 'spin 2s linear infinite',
        '@keyframes spin': {
          '0%': {
            transform: 'rotate(360deg)',
          },
          '100%': {
            transform: 'rotate(0deg)',
          },
        },
      }}
    />
  )
}

export default function ListJobs(props) {
  const defaultRowsPerPage = 10
  const rowsPerPageFromCookie = Number.parseInt(cookies.get(rowsPerPageCookieName))
  const currentRowsPerPage = Number.isNaN(rowsPerPageFromCookie) ? defaultRowsPerPage : rowsPerPageFromCookie

  const [numActiveCompileJobs, setNumActiveCompileJobs] = useState(0)
  const [numActiveQuantizeJobs, setNumActiveQuantizeJobs] = useState(0)
  const [numActiveLinkJobs, setNumActiveLinkJobs] = useState(0)
  const [numActiveProfileJobs, setNumActiveProfileJobs] = useState(0)
  const [numActiveInferenceJobs, setNumActiveInferenceJobs] = useState(0)
  const [totalQueryCount, setTotalQueryCount] = useState()

  // States for different tabs
  const [compileState, setCompileState] = useState({})
  const [quantizeState, setQuantizeState] = useState({})
  const [linkState, setLinkState] = useState({})
  const [profileState, setProfileState] = useState({})
  const [inferenceState, setInferenceState] = useState({})

  // We keep page navigation parameters as a single state, since partial
  // updates can flicker weirdly
  const [query, setQuery] = useState({
    page: props.page ?? 0,
    rowsPerPage: props.rowsPerPage ?? currentRowsPerPage,
    jobType: props.jobType ?? 'compile',
    filteredOwner: props.filteredOwner,
  })

  const [trigger404, setTrigger404] = useState(false)
  const POLL_INTERVAL = 5000 // 5 seconds

  const [fetchingJobList, setFetchingJobList] = useState(false)
  const updateJobs = (mountState) => {
    if (fetchingJobList) {
      // Don't keep requesting a job list if the last one hasn't come back yet
      return
    }

    let defaultParams = {}

    if (query?.filteredOwner && query.filteredOwner.kind === 'user') {
      defaultParams.creator = query.filteredOwner.owner
    }

    // Empty fetch, only for query total to populate tabs with actively
    // running job count
    getJobSummaries(
      0,
      0,
      mountState,
      (jobSummaryListPb) => {
        setNumActiveCompileJobs(jobSummaryListPb.getTotalQueryCount())
      },
      undefined, // ignore errors
      { ...defaultParams, type: 'compile', state: RUNNING_JOB_STATES },
    )

    getJobSummaries(
      0,
      0,
      mountState,
      (jobSummaryListPb) => {
        setNumActiveQuantizeJobs(jobSummaryListPb.getTotalQueryCount())
      },
      undefined, // ignore errors
      { ...defaultParams, type: 'quantize', state: RUNNING_JOB_STATES },
    )

    getJobSummaries(
      0,
      0,
      mountState,
      (jobSummaryListPb) => {
        setNumActiveLinkJobs(jobSummaryListPb.getTotalQueryCount())
      },
      undefined, // ignore errors
      { ...defaultParams, type: 'link', state: RUNNING_JOB_STATES },
    )

    getJobSummaries(
      0,
      0,
      mountState,
      (jobSummaryListPb) => {
        setNumActiveProfileJobs(jobSummaryListPb.getTotalQueryCount())
      },
      undefined, // ignore errors
      { ...defaultParams, type: 'profile', state: RUNNING_JOB_STATES },
    )

    getJobSummaries(
      0,
      0,
      mountState,
      (jobSummaryListPb) => {
        setNumActiveInferenceJobs(jobSummaryListPb.getTotalQueryCount())
      },
      undefined, // ignore errors
      { ...defaultParams, type: 'inference', state: RUNNING_JOB_STATES },
    )

    // Fetch actual jobs for the selected tab
    if (query?.jobType && props.userInfo.user) {
      getJobSummaries(
        query.page,
        query.rowsPerPage,
        mountState,
        (jobSummaryListPb) => {
          // Check that at least the first entry on this page is
          // inside the total query count. If not, raise 404.
          // Page 0 should always be valid, so that new users
          // do not get a 404.
          const onValidPage = query.page * query.rowsPerPage < jobSummaryListPb.getTotalQueryCount() || query.page == 0
          if (!onValidPage) {
            setTrigger404(true)
          } else {
            const state = {
              jobSummaryList: jobSummaryListPb.getJobSummariesList(),
              totalQueryCount: jobSummaryListPb.getTotalQueryCount(),
              loaded: true,
            }
            setTotalQueryCount(jobSummaryListPb.getTotalQueryCount())

            switch (query.jobType) {
              case 'compile':
                setCompileState(state)
                break
              case 'profile':
                setProfileState(state)
                break
              case 'inference':
                setInferenceState(state)
                break
              case 'quantize':
                setQuantizeState(state)
                break
              case 'link':
                setLinkState(state)
            }
          }
          setFetchingJobList(false)
        },
        () => {
          setFetchingJobList(false)
        },
        { ...defaultParams, type: query.jobType },
      )
      setFetchingJobList(true)
    }
  }

  const updateQueryAndPageURL = useCallback((newQuery) => {
    setQuery(newQuery)
    updatePageURL(newQuery)
  }, [])

  // Updates the page's URL based on the supplied query object.
  const updatePageURL = (newQuery) => {
    let url = '/jobs/'
    let extensions = new URLSearchParams()
    if (newQuery.page >= 1) {
      // Only one-based in the URL
      extensions.set('page', (newQuery.page + 1).toString())
    }
    if (newQuery.rowsPerPage != defaultRowsPerPage) {
      extensions.set('rowsPerPage', newQuery.rowsPerPage.toString())
    }
    if (newQuery.jobType !== 'profile') {
      extensions.set('type', newQuery.jobType)
    } else {
      extensions.delete('type')
    }
    if (newQuery.filteredOwner) {
      extensions.set('ownerKind', newQuery.filteredOwner.kind)
      extensions.set('ownerName', newQuery.filteredOwner.owner)
    }

    let ext_str = extensions.toString()
    if (ext_str.length > 0) {
      url = `${url}?${ext_str}`
    }

    window.history.replaceState(null, '', url)
  }

  useEffect(() => {
    document.title = 'Jobs | AI Hub'
  }, [])

  useIntervalEffect(
    () => {
      let [mountState, tearDownMounted] = useMounted()

      updateJobs(mountState)

      return () => {
        tearDownMounted()
        setFetchingJobList(false)
      }
    },
    [query],
    POLL_INTERVAL,
  )

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (newPage != query.page) {
        updateQueryAndPageURL({ ...query, page: newPage })
      }
    },
    [query],
  )

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      const newRowsPerPage = event.target.value ?? currentRowsPerPage
      if (query.rowsPerPage !== newRowsPerPage) {
        let newPage = query.page
        let queryUpdate = { rowsPerPage: newRowsPerPage }
        cookies.set(rowsPerPageCookieName, newRowsPerPage)
        if (totalQueryCount < query.page * newRowsPerPage) {
          newPage = Math.floor(totalQueryCount / newRowsPerPage)
          queryUpdate.page = newPage
        }
        updateQueryAndPageURL({ ...query, ...queryUpdate })
      }
    },
    [currentRowsPerPage, query],
  )

  const handleChangeSelectedTab = useCallback(
    (event, newValue) => {
      // Reset page too
      updateQueryAndPageURL({ ...query, page: 0, jobType: newValue })
    },
    [query],
  )

  const handleChangeCreator = useCallback(
    (event) => {
      const owner = event.target.value
      if (query.owner != owner) {
        // Reset the page too
        updateQueryAndPageURL({ ...query, page: 0, filteredOwner: owner })
      }
    },
    [query],
  )

  const makeTabLabel = (label, numActiveJobs) => {
    return (
      <div className="vertical-align-elements">
        <div>{label}</div>
        {numActiveJobs > 0 && (
          <>
            <div style={{ marginLeft: '10px' }}>(</div>
            <div>
              <SpinningLoopIcon />
            </div>
            <div style={{ marginLeft: '3px' }}>{numActiveJobs}</div>
            <div>&nbsp;)</div>
          </>
        )}
      </div>
    )
  }

  if (trigger404) {
    return <NotFoundPage />
  } else {
    let main
    let state
    const options = {
      page: query.page,
      rowsPerPage: query.rowsPerPage,
      userInfo: props.userInfo,
      filteredOwner: query.filteredOwner,
      handleChangePage,
      handleChangeRowsPerPage,
      handleChangeCreator,
    }

    const isZeroJobsVisible = totalQueryCount === undefined ? false : 0 === totalQueryCount

    switch (query.jobType) {
      case 'compile':
        state = compileState
        main = <TableCompileJobs {...options} {...state} />
        break
      case 'profile':
        state = profileState
        main = <TableProfileJobs {...options} {...state} />
        break
      case 'inference':
        state = inferenceState
        main = <TableInferenceJobs {...options} {...state} />
        break
      case 'quantize':
        state = quantizeState
        main = <TableQuantizeJobs {...options} {...state} />
        break
      case 'link':
        state = linkState
        main = <TableLinkJobs {...options} {...state} />
        break
    }

    const tabStyle = { height: '50px' }
    return (
      <div className="main" data-testid="listjobs-main">
        <Typography variant="h5" style={{ marginBottom: '0.5em' }}>
          Jobs
        </Typography>
        <Paper>
          <Box>
            <Tabs value={query.jobType} onChange={handleChangeSelectedTab} aria-label="job tabs">
              <Tab label={makeTabLabel('Compile', numActiveCompileJobs)} value="compile" style={tabStyle} />
              <Tab label={makeTabLabel('Quantize', numActiveQuantizeJobs)} value="quantize" style={tabStyle} />
              <Tab label={makeTabLabel('Link', numActiveLinkJobs)} value="link" style={tabStyle} />
              <Tab label={makeTabLabel('Profile', numActiveProfileJobs)} value="profile" style={tabStyle} />
              <Tab label={makeTabLabel('Inference', numActiveInferenceJobs)} value="inference" style={tabStyle} />
            </Tabs>
          </Box>
        </Paper>
        {state.loaded && main}

        {isZeroJobsVisible && (
          <Box display="flex" sx={{ justifyContent: 'center', fontSize: '1.5rem' }}>
            <Box display="flex" gap={3} p={2} alignItems="center" sx={{ flexDirection: 'column', fontSize: '1.5rem' }}>
              <Box>Want to try running models on real devices?</Box>
              <Button sx={{ px: 6 }} href="/account/" variant="contained">
                Get started
              </Button>
            </Box>
          </Box>
        )}
      </div>
    )
  }
}

ListJobs.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  jobType: PropTypes.string,
  userInfo: PropTypes.object,
  filteredOwner: PropTypes.object,
}
