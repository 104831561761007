// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.0
//   protoc               v3.20.3
// source: public_api.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import Long from "long";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "qai_hub.api";

export enum JobState {
  JOB_STATE_UNSPECIFIED = 0,
  JOB_STATE_CREATED = 1,
  /** JOB_STATE_DONE - End States */
  JOB_STATE_DONE = 10,
  JOB_STATE_FAILED = 30,
  /** JOB_STATE_OPTIMIZING_MODEL - In Progress States */
  JOB_STATE_OPTIMIZING_MODEL = 50,
  JOB_STATE_PROVISIONING_DEVICE = 60,
  JOB_STATE_MEASURING_PERFORMANCE = 70,
  JOB_STATE_RUNNING_INFERENCE = 80,
  JOB_STATE_QUANTIZING_MODEL = 90,
  JOB_STATE_LINKING_MODELS = 100,
  UNRECOGNIZED = -1,
}

export function jobStateFromJSON(object: any): JobState {
  switch (object) {
    case 0:
    case "JOB_STATE_UNSPECIFIED":
      return JobState.JOB_STATE_UNSPECIFIED;
    case 1:
    case "JOB_STATE_CREATED":
      return JobState.JOB_STATE_CREATED;
    case 10:
    case "JOB_STATE_DONE":
      return JobState.JOB_STATE_DONE;
    case 30:
    case "JOB_STATE_FAILED":
      return JobState.JOB_STATE_FAILED;
    case 50:
    case "JOB_STATE_OPTIMIZING_MODEL":
      return JobState.JOB_STATE_OPTIMIZING_MODEL;
    case 60:
    case "JOB_STATE_PROVISIONING_DEVICE":
      return JobState.JOB_STATE_PROVISIONING_DEVICE;
    case 70:
    case "JOB_STATE_MEASURING_PERFORMANCE":
      return JobState.JOB_STATE_MEASURING_PERFORMANCE;
    case 80:
    case "JOB_STATE_RUNNING_INFERENCE":
      return JobState.JOB_STATE_RUNNING_INFERENCE;
    case 90:
    case "JOB_STATE_QUANTIZING_MODEL":
      return JobState.JOB_STATE_QUANTIZING_MODEL;
    case 100:
    case "JOB_STATE_LINKING_MODELS":
      return JobState.JOB_STATE_LINKING_MODELS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return JobState.UNRECOGNIZED;
  }
}

export function jobStateToJSON(object: JobState): string {
  switch (object) {
    case JobState.JOB_STATE_UNSPECIFIED:
      return "JOB_STATE_UNSPECIFIED";
    case JobState.JOB_STATE_CREATED:
      return "JOB_STATE_CREATED";
    case JobState.JOB_STATE_DONE:
      return "JOB_STATE_DONE";
    case JobState.JOB_STATE_FAILED:
      return "JOB_STATE_FAILED";
    case JobState.JOB_STATE_OPTIMIZING_MODEL:
      return "JOB_STATE_OPTIMIZING_MODEL";
    case JobState.JOB_STATE_PROVISIONING_DEVICE:
      return "JOB_STATE_PROVISIONING_DEVICE";
    case JobState.JOB_STATE_MEASURING_PERFORMANCE:
      return "JOB_STATE_MEASURING_PERFORMANCE";
    case JobState.JOB_STATE_RUNNING_INFERENCE:
      return "JOB_STATE_RUNNING_INFERENCE";
    case JobState.JOB_STATE_QUANTIZING_MODEL:
      return "JOB_STATE_QUANTIZING_MODEL";
    case JobState.JOB_STATE_LINKING_MODELS:
      return "JOB_STATE_LINKING_MODELS";
    case JobState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum TensorDtype {
  TENSOR_DTYPE_UNSPECIFIED = 0,
  TENSOR_DTYPE_FLOAT32 = 1,
  TENSOR_DTYPE_FLOAT16 = 8,
  TENSOR_DTYPE_INT32 = 2,
  TENSOR_DTYPE_INT64 = 3,
  TENSOR_DTYPE_INT8 = 4,
  TENSOR_DTYPE_UINT8 = 5,
  TENSOR_DTYPE_INT16 = 6,
  TENSOR_DTYPE_UINT16 = 7,
  UNRECOGNIZED = -1,
}

export function tensorDtypeFromJSON(object: any): TensorDtype {
  switch (object) {
    case 0:
    case "TENSOR_DTYPE_UNSPECIFIED":
      return TensorDtype.TENSOR_DTYPE_UNSPECIFIED;
    case 1:
    case "TENSOR_DTYPE_FLOAT32":
      return TensorDtype.TENSOR_DTYPE_FLOAT32;
    case 8:
    case "TENSOR_DTYPE_FLOAT16":
      return TensorDtype.TENSOR_DTYPE_FLOAT16;
    case 2:
    case "TENSOR_DTYPE_INT32":
      return TensorDtype.TENSOR_DTYPE_INT32;
    case 3:
    case "TENSOR_DTYPE_INT64":
      return TensorDtype.TENSOR_DTYPE_INT64;
    case 4:
    case "TENSOR_DTYPE_INT8":
      return TensorDtype.TENSOR_DTYPE_INT8;
    case 5:
    case "TENSOR_DTYPE_UINT8":
      return TensorDtype.TENSOR_DTYPE_UINT8;
    case 6:
    case "TENSOR_DTYPE_INT16":
      return TensorDtype.TENSOR_DTYPE_INT16;
    case 7:
    case "TENSOR_DTYPE_UINT16":
      return TensorDtype.TENSOR_DTYPE_UINT16;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TensorDtype.UNRECOGNIZED;
  }
}

export function tensorDtypeToJSON(object: TensorDtype): string {
  switch (object) {
    case TensorDtype.TENSOR_DTYPE_UNSPECIFIED:
      return "TENSOR_DTYPE_UNSPECIFIED";
    case TensorDtype.TENSOR_DTYPE_FLOAT32:
      return "TENSOR_DTYPE_FLOAT32";
    case TensorDtype.TENSOR_DTYPE_FLOAT16:
      return "TENSOR_DTYPE_FLOAT16";
    case TensorDtype.TENSOR_DTYPE_INT32:
      return "TENSOR_DTYPE_INT32";
    case TensorDtype.TENSOR_DTYPE_INT64:
      return "TENSOR_DTYPE_INT64";
    case TensorDtype.TENSOR_DTYPE_INT8:
      return "TENSOR_DTYPE_INT8";
    case TensorDtype.TENSOR_DTYPE_UINT8:
      return "TENSOR_DTYPE_UINT8";
    case TensorDtype.TENSOR_DTYPE_INT16:
      return "TENSOR_DTYPE_INT16";
    case TensorDtype.TENSOR_DTYPE_UINT16:
      return "TENSOR_DTYPE_UINT16";
    case TensorDtype.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum QuantizeDtype {
  QUANTIZE_DTYPE_UNSPECIFIED = 0,
  QUANTIZE_DTYPE_INT8 = 1,
  /** QUANTIZE_DTYPE_UINT8 - do not use */
  QUANTIZE_DTYPE_UINT8 = 2,
  QUANTIZE_DTYPE_INT16 = 3,
  /** QUANTIZE_DTYPE_UINT16 - do not use */
  QUANTIZE_DTYPE_UINT16 = 4,
  QUANTIZE_DTYPE_INT4 = 5,
  /** QUANTIZE_DTYPE_UINT4 - do not use */
  QUANTIZE_DTYPE_UINT4 = 6,
  UNRECOGNIZED = -1,
}

export function quantizeDtypeFromJSON(object: any): QuantizeDtype {
  switch (object) {
    case 0:
    case "QUANTIZE_DTYPE_UNSPECIFIED":
      return QuantizeDtype.QUANTIZE_DTYPE_UNSPECIFIED;
    case 1:
    case "QUANTIZE_DTYPE_INT8":
      return QuantizeDtype.QUANTIZE_DTYPE_INT8;
    case 2:
    case "QUANTIZE_DTYPE_UINT8":
      return QuantizeDtype.QUANTIZE_DTYPE_UINT8;
    case 3:
    case "QUANTIZE_DTYPE_INT16":
      return QuantizeDtype.QUANTIZE_DTYPE_INT16;
    case 4:
    case "QUANTIZE_DTYPE_UINT16":
      return QuantizeDtype.QUANTIZE_DTYPE_UINT16;
    case 5:
    case "QUANTIZE_DTYPE_INT4":
      return QuantizeDtype.QUANTIZE_DTYPE_INT4;
    case 6:
    case "QUANTIZE_DTYPE_UINT4":
      return QuantizeDtype.QUANTIZE_DTYPE_UINT4;
    case -1:
    case "UNRECOGNIZED":
    default:
      return QuantizeDtype.UNRECOGNIZED;
  }
}

export function quantizeDtypeToJSON(object: QuantizeDtype): string {
  switch (object) {
    case QuantizeDtype.QUANTIZE_DTYPE_UNSPECIFIED:
      return "QUANTIZE_DTYPE_UNSPECIFIED";
    case QuantizeDtype.QUANTIZE_DTYPE_INT8:
      return "QUANTIZE_DTYPE_INT8";
    case QuantizeDtype.QUANTIZE_DTYPE_UINT8:
      return "QUANTIZE_DTYPE_UINT8";
    case QuantizeDtype.QUANTIZE_DTYPE_INT16:
      return "QUANTIZE_DTYPE_INT16";
    case QuantizeDtype.QUANTIZE_DTYPE_UINT16:
      return "QUANTIZE_DTYPE_UINT16";
    case QuantizeDtype.QUANTIZE_DTYPE_INT4:
      return "QUANTIZE_DTYPE_INT4";
    case QuantizeDtype.QUANTIZE_DTYPE_UINT4:
      return "QUANTIZE_DTYPE_UINT4";
    case QuantizeDtype.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ModelType {
  /** MODEL_TYPE_UNSPECIFIED - Note: enum namespace is global */
  MODEL_TYPE_UNSPECIFIED = 0,
  MODEL_TYPE_TORCHSCRIPT = 1,
  MODEL_TYPE_MLMODEL = 2,
  /** MODEL_TYPE_DEPRECATED_UNTRACED_TORCHSCRIPT - unused */
  MODEL_TYPE_DEPRECATED_UNTRACED_TORCHSCRIPT = 3,
  MODEL_TYPE_TFLITE = 4,
  MODEL_TYPE_MLMODELC = 5,
  MODEL_TYPE_ONNX = 6,
  MODEL_TYPE_ORT = 7,
  MODEL_TYPE_MLPACKAGE = 8,
  MODEL_TYPE_TETRART = 9,
  MODEL_TYPE_QNN_LIB_AARCH64_ANDROID = 10,
  MODEL_TYPE_QNN_CONTEXT_BINARY = 11,
  MODEL_TYPE_AIMET_ONNX = 12,
  MODEL_TYPE_AIMET_PT = 13,
  MODEL_TYPE_QNN_LIB_X86_64_LINUX = 14,
  UNRECOGNIZED = -1,
}

export function modelTypeFromJSON(object: any): ModelType {
  switch (object) {
    case 0:
    case "MODEL_TYPE_UNSPECIFIED":
      return ModelType.MODEL_TYPE_UNSPECIFIED;
    case 1:
    case "MODEL_TYPE_TORCHSCRIPT":
      return ModelType.MODEL_TYPE_TORCHSCRIPT;
    case 2:
    case "MODEL_TYPE_MLMODEL":
      return ModelType.MODEL_TYPE_MLMODEL;
    case 3:
    case "MODEL_TYPE_DEPRECATED_UNTRACED_TORCHSCRIPT":
      return ModelType.MODEL_TYPE_DEPRECATED_UNTRACED_TORCHSCRIPT;
    case 4:
    case "MODEL_TYPE_TFLITE":
      return ModelType.MODEL_TYPE_TFLITE;
    case 5:
    case "MODEL_TYPE_MLMODELC":
      return ModelType.MODEL_TYPE_MLMODELC;
    case 6:
    case "MODEL_TYPE_ONNX":
      return ModelType.MODEL_TYPE_ONNX;
    case 7:
    case "MODEL_TYPE_ORT":
      return ModelType.MODEL_TYPE_ORT;
    case 8:
    case "MODEL_TYPE_MLPACKAGE":
      return ModelType.MODEL_TYPE_MLPACKAGE;
    case 9:
    case "MODEL_TYPE_TETRART":
      return ModelType.MODEL_TYPE_TETRART;
    case 10:
    case "MODEL_TYPE_QNN_LIB_AARCH64_ANDROID":
      return ModelType.MODEL_TYPE_QNN_LIB_AARCH64_ANDROID;
    case 11:
    case "MODEL_TYPE_QNN_CONTEXT_BINARY":
      return ModelType.MODEL_TYPE_QNN_CONTEXT_BINARY;
    case 12:
    case "MODEL_TYPE_AIMET_ONNX":
      return ModelType.MODEL_TYPE_AIMET_ONNX;
    case 13:
    case "MODEL_TYPE_AIMET_PT":
      return ModelType.MODEL_TYPE_AIMET_PT;
    case 14:
    case "MODEL_TYPE_QNN_LIB_X86_64_LINUX":
      return ModelType.MODEL_TYPE_QNN_LIB_X86_64_LINUX;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ModelType.UNRECOGNIZED;
  }
}

export function modelTypeToJSON(object: ModelType): string {
  switch (object) {
    case ModelType.MODEL_TYPE_UNSPECIFIED:
      return "MODEL_TYPE_UNSPECIFIED";
    case ModelType.MODEL_TYPE_TORCHSCRIPT:
      return "MODEL_TYPE_TORCHSCRIPT";
    case ModelType.MODEL_TYPE_MLMODEL:
      return "MODEL_TYPE_MLMODEL";
    case ModelType.MODEL_TYPE_DEPRECATED_UNTRACED_TORCHSCRIPT:
      return "MODEL_TYPE_DEPRECATED_UNTRACED_TORCHSCRIPT";
    case ModelType.MODEL_TYPE_TFLITE:
      return "MODEL_TYPE_TFLITE";
    case ModelType.MODEL_TYPE_MLMODELC:
      return "MODEL_TYPE_MLMODELC";
    case ModelType.MODEL_TYPE_ONNX:
      return "MODEL_TYPE_ONNX";
    case ModelType.MODEL_TYPE_ORT:
      return "MODEL_TYPE_ORT";
    case ModelType.MODEL_TYPE_MLPACKAGE:
      return "MODEL_TYPE_MLPACKAGE";
    case ModelType.MODEL_TYPE_TETRART:
      return "MODEL_TYPE_TETRART";
    case ModelType.MODEL_TYPE_QNN_LIB_AARCH64_ANDROID:
      return "MODEL_TYPE_QNN_LIB_AARCH64_ANDROID";
    case ModelType.MODEL_TYPE_QNN_CONTEXT_BINARY:
      return "MODEL_TYPE_QNN_CONTEXT_BINARY";
    case ModelType.MODEL_TYPE_AIMET_ONNX:
      return "MODEL_TYPE_AIMET_ONNX";
    case ModelType.MODEL_TYPE_AIMET_PT:
      return "MODEL_TYPE_AIMET_PT";
    case ModelType.MODEL_TYPE_QNN_LIB_X86_64_LINUX:
      return "MODEL_TYPE_QNN_LIB_X86_64_LINUX";
    case ModelType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ModelMetadataKey {
  MODEL_METADATA_KEY_UNSPECIFIED = 0,
  /** MODEL_METADATA_KEY_QNN_SDK_VERSION - QNN */
  MODEL_METADATA_KEY_QNN_SDK_VERSION = 1,
  /** MODEL_METADATA_KEY_QNN_SDK_VARIANT - e.g., 'Auto' */
  MODEL_METADATA_KEY_QNN_SDK_VARIANT = 2,
  /** MODEL_METADATA_KEY_QNN_CONTEXT_BIN_GRAPH_NAMES - QNN (context binaries only) */
  MODEL_METADATA_KEY_QNN_CONTEXT_BIN_GRAPH_NAMES = 30,
  /** MODEL_METADATA_KEY_QNN_CONTEXT_BIN_HEXAGON_VERSION - e.g. 'v75' */
  MODEL_METADATA_KEY_QNN_CONTEXT_BIN_HEXAGON_VERSION = 31,
  /** MODEL_METADATA_KEY_QNN_CONTEXT_BIN_SOC_MODEL - e.g., '69' */
  MODEL_METADATA_KEY_QNN_CONTEXT_BIN_SOC_MODEL = 32,
  /** MODEL_METADATA_KEY_QNN_CONTEXT_BIN_BACKEND - 'HTP' (only option) */
  MODEL_METADATA_KEY_QNN_CONTEXT_BIN_BACKEND = 33,
  /** MODEL_METADATA_KEY_QNN_CONTEXT_BIN_VTCM - e.g., '2' */
  MODEL_METADATA_KEY_QNN_CONTEXT_BIN_VTCM = 34,
  /** MODEL_METADATA_KEY_QNN_CONTEXT_BIN_OPTIMIZATION_LEVEL - e.g., '3' */
  MODEL_METADATA_KEY_QNN_CONTEXT_BIN_OPTIMIZATION_LEVEL = 35,
  UNRECOGNIZED = -1,
}

export function modelMetadataKeyFromJSON(object: any): ModelMetadataKey {
  switch (object) {
    case 0:
    case "MODEL_METADATA_KEY_UNSPECIFIED":
      return ModelMetadataKey.MODEL_METADATA_KEY_UNSPECIFIED;
    case 1:
    case "MODEL_METADATA_KEY_QNN_SDK_VERSION":
      return ModelMetadataKey.MODEL_METADATA_KEY_QNN_SDK_VERSION;
    case 2:
    case "MODEL_METADATA_KEY_QNN_SDK_VARIANT":
      return ModelMetadataKey.MODEL_METADATA_KEY_QNN_SDK_VARIANT;
    case 30:
    case "MODEL_METADATA_KEY_QNN_CONTEXT_BIN_GRAPH_NAMES":
      return ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_GRAPH_NAMES;
    case 31:
    case "MODEL_METADATA_KEY_QNN_CONTEXT_BIN_HEXAGON_VERSION":
      return ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_HEXAGON_VERSION;
    case 32:
    case "MODEL_METADATA_KEY_QNN_CONTEXT_BIN_SOC_MODEL":
      return ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_SOC_MODEL;
    case 33:
    case "MODEL_METADATA_KEY_QNN_CONTEXT_BIN_BACKEND":
      return ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_BACKEND;
    case 34:
    case "MODEL_METADATA_KEY_QNN_CONTEXT_BIN_VTCM":
      return ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_VTCM;
    case 35:
    case "MODEL_METADATA_KEY_QNN_CONTEXT_BIN_OPTIMIZATION_LEVEL":
      return ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_OPTIMIZATION_LEVEL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ModelMetadataKey.UNRECOGNIZED;
  }
}

export function modelMetadataKeyToJSON(object: ModelMetadataKey): string {
  switch (object) {
    case ModelMetadataKey.MODEL_METADATA_KEY_UNSPECIFIED:
      return "MODEL_METADATA_KEY_UNSPECIFIED";
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_SDK_VERSION:
      return "MODEL_METADATA_KEY_QNN_SDK_VERSION";
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_SDK_VARIANT:
      return "MODEL_METADATA_KEY_QNN_SDK_VARIANT";
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_GRAPH_NAMES:
      return "MODEL_METADATA_KEY_QNN_CONTEXT_BIN_GRAPH_NAMES";
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_HEXAGON_VERSION:
      return "MODEL_METADATA_KEY_QNN_CONTEXT_BIN_HEXAGON_VERSION";
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_SOC_MODEL:
      return "MODEL_METADATA_KEY_QNN_CONTEXT_BIN_SOC_MODEL";
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_BACKEND:
      return "MODEL_METADATA_KEY_QNN_CONTEXT_BIN_BACKEND";
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_VTCM:
      return "MODEL_METADATA_KEY_QNN_CONTEXT_BIN_VTCM";
    case ModelMetadataKey.MODEL_METADATA_KEY_QNN_CONTEXT_BIN_OPTIMIZATION_LEVEL:
      return "MODEL_METADATA_KEY_QNN_CONTEXT_BIN_OPTIMIZATION_LEVEL";
    case ModelMetadataKey.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Types of jobs the service supports.
 * Use in the DB, as well as when endpoint querying.
 */
export enum JobType {
  JOB_TYPE_UNSPECIFIED = 0,
  JOB_TYPE_PROFILE = 1,
  JOB_TYPE_INFERENCE = 2,
  JOB_TYPE_COMPILE = 3,
  JOB_TYPE_QUANTIZE = 4,
  JOB_TYPE_LINK = 5,
  UNRECOGNIZED = -1,
}

export function jobTypeFromJSON(object: any): JobType {
  switch (object) {
    case 0:
    case "JOB_TYPE_UNSPECIFIED":
      return JobType.JOB_TYPE_UNSPECIFIED;
    case 1:
    case "JOB_TYPE_PROFILE":
      return JobType.JOB_TYPE_PROFILE;
    case 2:
    case "JOB_TYPE_INFERENCE":
      return JobType.JOB_TYPE_INFERENCE;
    case 3:
    case "JOB_TYPE_COMPILE":
      return JobType.JOB_TYPE_COMPILE;
    case 4:
    case "JOB_TYPE_QUANTIZE":
      return JobType.JOB_TYPE_QUANTIZE;
    case 5:
    case "JOB_TYPE_LINK":
      return JobType.JOB_TYPE_LINK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return JobType.UNRECOGNIZED;
  }
}

export function jobTypeToJSON(object: JobType): string {
  switch (object) {
    case JobType.JOB_TYPE_UNSPECIFIED:
      return "JOB_TYPE_UNSPECIFIED";
    case JobType.JOB_TYPE_PROFILE:
      return "JOB_TYPE_PROFILE";
    case JobType.JOB_TYPE_INFERENCE:
      return "JOB_TYPE_INFERENCE";
    case JobType.JOB_TYPE_COMPILE:
      return "JOB_TYPE_COMPILE";
    case JobType.JOB_TYPE_QUANTIZE:
      return "JOB_TYPE_QUANTIZE";
    case JobType.JOB_TYPE_LINK:
      return "JOB_TYPE_LINK";
    case JobType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Categories of job failures. */
export enum JobFailureType {
  /** JOB_FAILURE_TYPE_UNSPECIFIED - Default value; job is old or not complete */
  JOB_FAILURE_TYPE_UNSPECIFIED = 0,
  /** JOB_FAILURE_TYPE_SUCCESS - Job succeeded */
  JOB_FAILURE_TYPE_SUCCESS = 1,
  /** JOB_FAILURE_TYPE_UNKNOWN - Failure defies logic to categorize */
  JOB_FAILURE_TYPE_UNKNOWN = 2,
  /** JOB_FAILURE_TYPE_UNSUPPORTED - Unsupported, but valid request */
  JOB_FAILURE_TYPE_UNSUPPORTED = 3,
  /** JOB_FAILURE_TYPE_INTERNAL_UNKNOWN - 1000-1999: Internal errors */
  JOB_FAILURE_TYPE_INTERNAL_UNKNOWN = 1000,
  /** JOB_FAILURE_TYPE_INTERNAL_LOGIC - Faulty logic in AI Hub code */
  JOB_FAILURE_TYPE_INTERNAL_LOGIC = 1001,
  /** JOB_FAILURE_TYPE_RUNTIME_UNKNOWN - 2000-2099: Runtime errors */
  JOB_FAILURE_TYPE_RUNTIME_UNKNOWN = 2000,
  /** JOB_FAILURE_TYPE_RUNTIME_CRASH - Profiler process crashed */
  JOB_FAILURE_TYPE_RUNTIME_CRASH = 2001,
  /** JOB_FAILURE_TYPE_RUNTIME_OUT_OF_DISK - Worker ran out of disk space */
  JOB_FAILURE_TYPE_RUNTIME_OUT_OF_DISK = 2002,
  /** JOB_FAILURE_TYPE_CLOUD_API_ERROR - 2100-2199: Cloud errors */
  JOB_FAILURE_TYPE_CLOUD_API_ERROR = 2100,
  /** JOB_FAILURE_TYPE_CLOUD_RESOURCE_MISSING - Some resource is unexpectedly not found */
  JOB_FAILURE_TYPE_CLOUD_RESOURCE_MISSING = 2101,
  /** JOB_FAILURE_TYPE_QDC_API_ERROR - 2200-2299: QDC-specific errors */
  JOB_FAILURE_TYPE_QDC_API_ERROR = 2200,
  /** JOB_FAILURE_TYPE_MODEL_UNKNOWN - Input errors */
  JOB_FAILURE_TYPE_MODEL_UNKNOWN = 3000,
  /** JOB_FAILURE_TYPE_QNN_TOOL_ERROR - Unexpected failure while invoking a QNN tool */
  JOB_FAILURE_TYPE_QNN_TOOL_ERROR = 3050,
  /** JOB_FAILURE_TYPE_RUNTIME_TIMEOUT - 12000-12099: Runtime errors */
  JOB_FAILURE_TYPE_RUNTIME_TIMEOUT = 12000,
  /** JOB_FAILURE_TYPE_RUNTIME_DEVICE_OOM - Device ran out of memory */
  JOB_FAILURE_TYPE_RUNTIME_DEVICE_OOM = 12001,
  /** JOB_FAILURE_TYPE_CLOUD_THROTTLED - 12100-12199: Cloud errors */
  JOB_FAILURE_TYPE_CLOUD_THROTTLED = 12102,
  /** JOB_FAILURE_TYPE_INVALID_INPUTS - 13000-13049: General input errors */
  JOB_FAILURE_TYPE_INVALID_INPUTS = 13000,
  /** JOB_FAILURE_TYPE_INVALID_ARGUMENT - Invalid API arguments */
  JOB_FAILURE_TYPE_INVALID_ARGUMENT = 13001,
  /** JOB_FAILURE_TYPE_MODEL_CORRUPT - The model is structurally bogus */
  JOB_FAILURE_TYPE_MODEL_CORRUPT = 13002,
  /** JOB_FAILURE_TYPE_MODEL_INVALID - The model cannot be used in this context */
  JOB_FAILURE_TYPE_MODEL_INVALID = 13003,
  /** JOB_FAILURE_TYPE_QNN_VERSION_MISMATCH - 13050-13099: Incorrect QNN usage */
  JOB_FAILURE_TYPE_QNN_VERSION_MISMATCH = 13050,
  /** JOB_FAILURE_TYPE_DSP_VERSION_MISMATCH - An artifact was built for an incompatible DSP version. */
  JOB_FAILURE_TYPE_DSP_VERSION_MISMATCH = 13051,
  /** JOB_FAILURE_TYPE_RUNTIME_INTERNAL_SERVICE_BUSY - Internal errors */
  JOB_FAILURE_TYPE_RUNTIME_INTERNAL_SERVICE_BUSY = 21000,
  UNRECOGNIZED = -1,
}

export function jobFailureTypeFromJSON(object: any): JobFailureType {
  switch (object) {
    case 0:
    case "JOB_FAILURE_TYPE_UNSPECIFIED":
      return JobFailureType.JOB_FAILURE_TYPE_UNSPECIFIED;
    case 1:
    case "JOB_FAILURE_TYPE_SUCCESS":
      return JobFailureType.JOB_FAILURE_TYPE_SUCCESS;
    case 2:
    case "JOB_FAILURE_TYPE_UNKNOWN":
      return JobFailureType.JOB_FAILURE_TYPE_UNKNOWN;
    case 3:
    case "JOB_FAILURE_TYPE_UNSUPPORTED":
      return JobFailureType.JOB_FAILURE_TYPE_UNSUPPORTED;
    case 1000:
    case "JOB_FAILURE_TYPE_INTERNAL_UNKNOWN":
      return JobFailureType.JOB_FAILURE_TYPE_INTERNAL_UNKNOWN;
    case 1001:
    case "JOB_FAILURE_TYPE_INTERNAL_LOGIC":
      return JobFailureType.JOB_FAILURE_TYPE_INTERNAL_LOGIC;
    case 2000:
    case "JOB_FAILURE_TYPE_RUNTIME_UNKNOWN":
      return JobFailureType.JOB_FAILURE_TYPE_RUNTIME_UNKNOWN;
    case 2001:
    case "JOB_FAILURE_TYPE_RUNTIME_CRASH":
      return JobFailureType.JOB_FAILURE_TYPE_RUNTIME_CRASH;
    case 2002:
    case "JOB_FAILURE_TYPE_RUNTIME_OUT_OF_DISK":
      return JobFailureType.JOB_FAILURE_TYPE_RUNTIME_OUT_OF_DISK;
    case 2100:
    case "JOB_FAILURE_TYPE_CLOUD_API_ERROR":
      return JobFailureType.JOB_FAILURE_TYPE_CLOUD_API_ERROR;
    case 2101:
    case "JOB_FAILURE_TYPE_CLOUD_RESOURCE_MISSING":
      return JobFailureType.JOB_FAILURE_TYPE_CLOUD_RESOURCE_MISSING;
    case 2200:
    case "JOB_FAILURE_TYPE_QDC_API_ERROR":
      return JobFailureType.JOB_FAILURE_TYPE_QDC_API_ERROR;
    case 3000:
    case "JOB_FAILURE_TYPE_MODEL_UNKNOWN":
      return JobFailureType.JOB_FAILURE_TYPE_MODEL_UNKNOWN;
    case 3050:
    case "JOB_FAILURE_TYPE_QNN_TOOL_ERROR":
      return JobFailureType.JOB_FAILURE_TYPE_QNN_TOOL_ERROR;
    case 12000:
    case "JOB_FAILURE_TYPE_RUNTIME_TIMEOUT":
      return JobFailureType.JOB_FAILURE_TYPE_RUNTIME_TIMEOUT;
    case 12001:
    case "JOB_FAILURE_TYPE_RUNTIME_DEVICE_OOM":
      return JobFailureType.JOB_FAILURE_TYPE_RUNTIME_DEVICE_OOM;
    case 12102:
    case "JOB_FAILURE_TYPE_CLOUD_THROTTLED":
      return JobFailureType.JOB_FAILURE_TYPE_CLOUD_THROTTLED;
    case 13000:
    case "JOB_FAILURE_TYPE_INVALID_INPUTS":
      return JobFailureType.JOB_FAILURE_TYPE_INVALID_INPUTS;
    case 13001:
    case "JOB_FAILURE_TYPE_INVALID_ARGUMENT":
      return JobFailureType.JOB_FAILURE_TYPE_INVALID_ARGUMENT;
    case 13002:
    case "JOB_FAILURE_TYPE_MODEL_CORRUPT":
      return JobFailureType.JOB_FAILURE_TYPE_MODEL_CORRUPT;
    case 13003:
    case "JOB_FAILURE_TYPE_MODEL_INVALID":
      return JobFailureType.JOB_FAILURE_TYPE_MODEL_INVALID;
    case 13050:
    case "JOB_FAILURE_TYPE_QNN_VERSION_MISMATCH":
      return JobFailureType.JOB_FAILURE_TYPE_QNN_VERSION_MISMATCH;
    case 13051:
    case "JOB_FAILURE_TYPE_DSP_VERSION_MISMATCH":
      return JobFailureType.JOB_FAILURE_TYPE_DSP_VERSION_MISMATCH;
    case 21000:
    case "JOB_FAILURE_TYPE_RUNTIME_INTERNAL_SERVICE_BUSY":
      return JobFailureType.JOB_FAILURE_TYPE_RUNTIME_INTERNAL_SERVICE_BUSY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return JobFailureType.UNRECOGNIZED;
  }
}

export function jobFailureTypeToJSON(object: JobFailureType): string {
  switch (object) {
    case JobFailureType.JOB_FAILURE_TYPE_UNSPECIFIED:
      return "JOB_FAILURE_TYPE_UNSPECIFIED";
    case JobFailureType.JOB_FAILURE_TYPE_SUCCESS:
      return "JOB_FAILURE_TYPE_SUCCESS";
    case JobFailureType.JOB_FAILURE_TYPE_UNKNOWN:
      return "JOB_FAILURE_TYPE_UNKNOWN";
    case JobFailureType.JOB_FAILURE_TYPE_UNSUPPORTED:
      return "JOB_FAILURE_TYPE_UNSUPPORTED";
    case JobFailureType.JOB_FAILURE_TYPE_INTERNAL_UNKNOWN:
      return "JOB_FAILURE_TYPE_INTERNAL_UNKNOWN";
    case JobFailureType.JOB_FAILURE_TYPE_INTERNAL_LOGIC:
      return "JOB_FAILURE_TYPE_INTERNAL_LOGIC";
    case JobFailureType.JOB_FAILURE_TYPE_RUNTIME_UNKNOWN:
      return "JOB_FAILURE_TYPE_RUNTIME_UNKNOWN";
    case JobFailureType.JOB_FAILURE_TYPE_RUNTIME_CRASH:
      return "JOB_FAILURE_TYPE_RUNTIME_CRASH";
    case JobFailureType.JOB_FAILURE_TYPE_RUNTIME_OUT_OF_DISK:
      return "JOB_FAILURE_TYPE_RUNTIME_OUT_OF_DISK";
    case JobFailureType.JOB_FAILURE_TYPE_CLOUD_API_ERROR:
      return "JOB_FAILURE_TYPE_CLOUD_API_ERROR";
    case JobFailureType.JOB_FAILURE_TYPE_CLOUD_RESOURCE_MISSING:
      return "JOB_FAILURE_TYPE_CLOUD_RESOURCE_MISSING";
    case JobFailureType.JOB_FAILURE_TYPE_QDC_API_ERROR:
      return "JOB_FAILURE_TYPE_QDC_API_ERROR";
    case JobFailureType.JOB_FAILURE_TYPE_MODEL_UNKNOWN:
      return "JOB_FAILURE_TYPE_MODEL_UNKNOWN";
    case JobFailureType.JOB_FAILURE_TYPE_QNN_TOOL_ERROR:
      return "JOB_FAILURE_TYPE_QNN_TOOL_ERROR";
    case JobFailureType.JOB_FAILURE_TYPE_RUNTIME_TIMEOUT:
      return "JOB_FAILURE_TYPE_RUNTIME_TIMEOUT";
    case JobFailureType.JOB_FAILURE_TYPE_RUNTIME_DEVICE_OOM:
      return "JOB_FAILURE_TYPE_RUNTIME_DEVICE_OOM";
    case JobFailureType.JOB_FAILURE_TYPE_CLOUD_THROTTLED:
      return "JOB_FAILURE_TYPE_CLOUD_THROTTLED";
    case JobFailureType.JOB_FAILURE_TYPE_INVALID_INPUTS:
      return "JOB_FAILURE_TYPE_INVALID_INPUTS";
    case JobFailureType.JOB_FAILURE_TYPE_INVALID_ARGUMENT:
      return "JOB_FAILURE_TYPE_INVALID_ARGUMENT";
    case JobFailureType.JOB_FAILURE_TYPE_MODEL_CORRUPT:
      return "JOB_FAILURE_TYPE_MODEL_CORRUPT";
    case JobFailureType.JOB_FAILURE_TYPE_MODEL_INVALID:
      return "JOB_FAILURE_TYPE_MODEL_INVALID";
    case JobFailureType.JOB_FAILURE_TYPE_QNN_VERSION_MISMATCH:
      return "JOB_FAILURE_TYPE_QNN_VERSION_MISMATCH";
    case JobFailureType.JOB_FAILURE_TYPE_DSP_VERSION_MISMATCH:
      return "JOB_FAILURE_TYPE_DSP_VERSION_MISMATCH";
    case JobFailureType.JOB_FAILURE_TYPE_RUNTIME_INTERNAL_SERVICE_BUSY:
      return "JOB_FAILURE_TYPE_RUNTIME_INTERNAL_SERVICE_BUSY";
    case JobFailureType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Type of graph. Even though VizGraph is a generic graph representation,
 * knowing the graph type gives the frontend the option to do customize
 * a few things that are not stored the graph, such as:
 *  - Node coloring
 *  - Node captions (e.g., label may be “RSQRT” instead of type “unary”)
 *  - Importance order of node attributes (which to always show, which to hide)
 *  - Custom formatting (some attributes could get special treatment)
 *  - Fused ops (fused activations could be visualized.
 * These are just examples and it is up to the frontend to decide.
 */
export enum VizGraphType {
  VIZ_GRAPH_TYPE_UNSPECIFIED = 0,
  /** VIZ_GRAPH_TYPE_MLMODEL - Apple */
  VIZ_GRAPH_TYPE_MLMODEL = 1,
  /** VIZ_GRAPH_TYPE_MLPROGRAM - Core ML (MIL representation) */
  VIZ_GRAPH_TYPE_MLPROGRAM = 2,
  /** VIZ_GRAPH_TYPE_ESPRESSO - Espresso */
  VIZ_GRAPH_TYPE_ESPRESSO = 3,
  /** VIZ_GRAPH_TYPE_TFLITE - Tensorflow */
  VIZ_GRAPH_TYPE_TFLITE = 10,
  /** VIZ_GRAPH_TYPE_ONNX - ONNX */
  VIZ_GRAPH_TYPE_ONNX = 11,
  VIZ_GRAPH_TYPE_ORT = 12,
  /** VIZ_GRAPH_TYPE_QNN - QNN */
  VIZ_GRAPH_TYPE_QNN = 20,
  UNRECOGNIZED = -1,
}

export function vizGraphTypeFromJSON(object: any): VizGraphType {
  switch (object) {
    case 0:
    case "VIZ_GRAPH_TYPE_UNSPECIFIED":
      return VizGraphType.VIZ_GRAPH_TYPE_UNSPECIFIED;
    case 1:
    case "VIZ_GRAPH_TYPE_MLMODEL":
      return VizGraphType.VIZ_GRAPH_TYPE_MLMODEL;
    case 2:
    case "VIZ_GRAPH_TYPE_MLPROGRAM":
      return VizGraphType.VIZ_GRAPH_TYPE_MLPROGRAM;
    case 3:
    case "VIZ_GRAPH_TYPE_ESPRESSO":
      return VizGraphType.VIZ_GRAPH_TYPE_ESPRESSO;
    case 10:
    case "VIZ_GRAPH_TYPE_TFLITE":
      return VizGraphType.VIZ_GRAPH_TYPE_TFLITE;
    case 11:
    case "VIZ_GRAPH_TYPE_ONNX":
      return VizGraphType.VIZ_GRAPH_TYPE_ONNX;
    case 12:
    case "VIZ_GRAPH_TYPE_ORT":
      return VizGraphType.VIZ_GRAPH_TYPE_ORT;
    case 20:
    case "VIZ_GRAPH_TYPE_QNN":
      return VizGraphType.VIZ_GRAPH_TYPE_QNN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VizGraphType.UNRECOGNIZED;
  }
}

export function vizGraphTypeToJSON(object: VizGraphType): string {
  switch (object) {
    case VizGraphType.VIZ_GRAPH_TYPE_UNSPECIFIED:
      return "VIZ_GRAPH_TYPE_UNSPECIFIED";
    case VizGraphType.VIZ_GRAPH_TYPE_MLMODEL:
      return "VIZ_GRAPH_TYPE_MLMODEL";
    case VizGraphType.VIZ_GRAPH_TYPE_MLPROGRAM:
      return "VIZ_GRAPH_TYPE_MLPROGRAM";
    case VizGraphType.VIZ_GRAPH_TYPE_ESPRESSO:
      return "VIZ_GRAPH_TYPE_ESPRESSO";
    case VizGraphType.VIZ_GRAPH_TYPE_TFLITE:
      return "VIZ_GRAPH_TYPE_TFLITE";
    case VizGraphType.VIZ_GRAPH_TYPE_ONNX:
      return "VIZ_GRAPH_TYPE_ONNX";
    case VizGraphType.VIZ_GRAPH_TYPE_ORT:
      return "VIZ_GRAPH_TYPE_ORT";
    case VizGraphType.VIZ_GRAPH_TYPE_QNN:
      return "VIZ_GRAPH_TYPE_QNN";
    case VizGraphType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Dtypes for visualization. This needs to accommodate all backends, so expect
 * this list to grow.
 */
export enum VizDtype {
  VIZ_DTYPE_UNSPECIFIED = 0,
  /** VIZ_DTYPE_FLOAT16 - Common types */
  VIZ_DTYPE_FLOAT16 = 1,
  VIZ_DTYPE_FLOAT32 = 2,
  VIZ_DTYPE_FLOAT64 = 3,
  VIZ_DTYPE_UINT8 = 4,
  VIZ_DTYPE_INT8 = 5,
  VIZ_DTYPE_UINT16 = 6,
  VIZ_DTYPE_INT16 = 7,
  VIZ_DTYPE_UINT32 = 8,
  VIZ_DTYPE_INT32 = 9,
  VIZ_DTYPE_UINT64 = 10,
  VIZ_DTYPE_INT64 = 11,
  VIZ_DTYPE_COMPLEX64 = 12,
  VIZ_DTYPE_COMPLEX128 = 13,
  VIZ_DTYPE_STRING = 14,
  VIZ_DTYPE_BOOL = 15,
  VIZ_DTYPE_BFLOAT16 = 16,
  /** VIZ_DTYPE_UINT1 - Low-precision types */
  VIZ_DTYPE_UINT1 = 50,
  VIZ_DTYPE_UINT2 = 51,
  VIZ_DTYPE_UINT3 = 52,
  VIZ_DTYPE_UINT4 = 53,
  VIZ_DTYPE_UINT5 = 54,
  VIZ_DTYPE_UINT6 = 55,
  VIZ_DTYPE_UINT7 = 56,
  VIZ_DTYPE_INT4 = 63,
  /** VIZ_DTYPE_QINT4 - Quantization types */
  VIZ_DTYPE_QINT4 = 70,
  VIZ_DTYPE_QINT8 = 71,
  VIZ_DTYPE_QINT16 = 72,
  VIZ_DTYPE_QINT32 = 73,
  /** VIZ_DTYPE_QUINT4 - Unsigned Fixed Point types */
  VIZ_DTYPE_QUINT4 = 80,
  VIZ_DTYPE_QUINT8 = 81,
  VIZ_DTYPE_QUINT16 = 82,
  VIZ_DTYPE_QUINT32 = 83,
  /** VIZ_DTYPE_DICT_INT64_TO_FLOAT64 - Core ML special types */
  VIZ_DTYPE_DICT_INT64_TO_FLOAT64 = 100,
  VIZ_DTYPE_DICT_STRING_TO_FLOAT64 = 101,
  /** VIZ_DTYPE_TFLITE_RESOURCE - TFLite experimental types */
  VIZ_DTYPE_TFLITE_RESOURCE = 110,
  VIZ_DTYPE_TFLITE_VARIANT = 111,
  UNRECOGNIZED = -1,
}

export function vizDtypeFromJSON(object: any): VizDtype {
  switch (object) {
    case 0:
    case "VIZ_DTYPE_UNSPECIFIED":
      return VizDtype.VIZ_DTYPE_UNSPECIFIED;
    case 1:
    case "VIZ_DTYPE_FLOAT16":
      return VizDtype.VIZ_DTYPE_FLOAT16;
    case 2:
    case "VIZ_DTYPE_FLOAT32":
      return VizDtype.VIZ_DTYPE_FLOAT32;
    case 3:
    case "VIZ_DTYPE_FLOAT64":
      return VizDtype.VIZ_DTYPE_FLOAT64;
    case 4:
    case "VIZ_DTYPE_UINT8":
      return VizDtype.VIZ_DTYPE_UINT8;
    case 5:
    case "VIZ_DTYPE_INT8":
      return VizDtype.VIZ_DTYPE_INT8;
    case 6:
    case "VIZ_DTYPE_UINT16":
      return VizDtype.VIZ_DTYPE_UINT16;
    case 7:
    case "VIZ_DTYPE_INT16":
      return VizDtype.VIZ_DTYPE_INT16;
    case 8:
    case "VIZ_DTYPE_UINT32":
      return VizDtype.VIZ_DTYPE_UINT32;
    case 9:
    case "VIZ_DTYPE_INT32":
      return VizDtype.VIZ_DTYPE_INT32;
    case 10:
    case "VIZ_DTYPE_UINT64":
      return VizDtype.VIZ_DTYPE_UINT64;
    case 11:
    case "VIZ_DTYPE_INT64":
      return VizDtype.VIZ_DTYPE_INT64;
    case 12:
    case "VIZ_DTYPE_COMPLEX64":
      return VizDtype.VIZ_DTYPE_COMPLEX64;
    case 13:
    case "VIZ_DTYPE_COMPLEX128":
      return VizDtype.VIZ_DTYPE_COMPLEX128;
    case 14:
    case "VIZ_DTYPE_STRING":
      return VizDtype.VIZ_DTYPE_STRING;
    case 15:
    case "VIZ_DTYPE_BOOL":
      return VizDtype.VIZ_DTYPE_BOOL;
    case 16:
    case "VIZ_DTYPE_BFLOAT16":
      return VizDtype.VIZ_DTYPE_BFLOAT16;
    case 50:
    case "VIZ_DTYPE_UINT1":
      return VizDtype.VIZ_DTYPE_UINT1;
    case 51:
    case "VIZ_DTYPE_UINT2":
      return VizDtype.VIZ_DTYPE_UINT2;
    case 52:
    case "VIZ_DTYPE_UINT3":
      return VizDtype.VIZ_DTYPE_UINT3;
    case 53:
    case "VIZ_DTYPE_UINT4":
      return VizDtype.VIZ_DTYPE_UINT4;
    case 54:
    case "VIZ_DTYPE_UINT5":
      return VizDtype.VIZ_DTYPE_UINT5;
    case 55:
    case "VIZ_DTYPE_UINT6":
      return VizDtype.VIZ_DTYPE_UINT6;
    case 56:
    case "VIZ_DTYPE_UINT7":
      return VizDtype.VIZ_DTYPE_UINT7;
    case 63:
    case "VIZ_DTYPE_INT4":
      return VizDtype.VIZ_DTYPE_INT4;
    case 70:
    case "VIZ_DTYPE_QINT4":
      return VizDtype.VIZ_DTYPE_QINT4;
    case 71:
    case "VIZ_DTYPE_QINT8":
      return VizDtype.VIZ_DTYPE_QINT8;
    case 72:
    case "VIZ_DTYPE_QINT16":
      return VizDtype.VIZ_DTYPE_QINT16;
    case 73:
    case "VIZ_DTYPE_QINT32":
      return VizDtype.VIZ_DTYPE_QINT32;
    case 80:
    case "VIZ_DTYPE_QUINT4":
      return VizDtype.VIZ_DTYPE_QUINT4;
    case 81:
    case "VIZ_DTYPE_QUINT8":
      return VizDtype.VIZ_DTYPE_QUINT8;
    case 82:
    case "VIZ_DTYPE_QUINT16":
      return VizDtype.VIZ_DTYPE_QUINT16;
    case 83:
    case "VIZ_DTYPE_QUINT32":
      return VizDtype.VIZ_DTYPE_QUINT32;
    case 100:
    case "VIZ_DTYPE_DICT_INT64_TO_FLOAT64":
      return VizDtype.VIZ_DTYPE_DICT_INT64_TO_FLOAT64;
    case 101:
    case "VIZ_DTYPE_DICT_STRING_TO_FLOAT64":
      return VizDtype.VIZ_DTYPE_DICT_STRING_TO_FLOAT64;
    case 110:
    case "VIZ_DTYPE_TFLITE_RESOURCE":
      return VizDtype.VIZ_DTYPE_TFLITE_RESOURCE;
    case 111:
    case "VIZ_DTYPE_TFLITE_VARIANT":
      return VizDtype.VIZ_DTYPE_TFLITE_VARIANT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VizDtype.UNRECOGNIZED;
  }
}

export function vizDtypeToJSON(object: VizDtype): string {
  switch (object) {
    case VizDtype.VIZ_DTYPE_UNSPECIFIED:
      return "VIZ_DTYPE_UNSPECIFIED";
    case VizDtype.VIZ_DTYPE_FLOAT16:
      return "VIZ_DTYPE_FLOAT16";
    case VizDtype.VIZ_DTYPE_FLOAT32:
      return "VIZ_DTYPE_FLOAT32";
    case VizDtype.VIZ_DTYPE_FLOAT64:
      return "VIZ_DTYPE_FLOAT64";
    case VizDtype.VIZ_DTYPE_UINT8:
      return "VIZ_DTYPE_UINT8";
    case VizDtype.VIZ_DTYPE_INT8:
      return "VIZ_DTYPE_INT8";
    case VizDtype.VIZ_DTYPE_UINT16:
      return "VIZ_DTYPE_UINT16";
    case VizDtype.VIZ_DTYPE_INT16:
      return "VIZ_DTYPE_INT16";
    case VizDtype.VIZ_DTYPE_UINT32:
      return "VIZ_DTYPE_UINT32";
    case VizDtype.VIZ_DTYPE_INT32:
      return "VIZ_DTYPE_INT32";
    case VizDtype.VIZ_DTYPE_UINT64:
      return "VIZ_DTYPE_UINT64";
    case VizDtype.VIZ_DTYPE_INT64:
      return "VIZ_DTYPE_INT64";
    case VizDtype.VIZ_DTYPE_COMPLEX64:
      return "VIZ_DTYPE_COMPLEX64";
    case VizDtype.VIZ_DTYPE_COMPLEX128:
      return "VIZ_DTYPE_COMPLEX128";
    case VizDtype.VIZ_DTYPE_STRING:
      return "VIZ_DTYPE_STRING";
    case VizDtype.VIZ_DTYPE_BOOL:
      return "VIZ_DTYPE_BOOL";
    case VizDtype.VIZ_DTYPE_BFLOAT16:
      return "VIZ_DTYPE_BFLOAT16";
    case VizDtype.VIZ_DTYPE_UINT1:
      return "VIZ_DTYPE_UINT1";
    case VizDtype.VIZ_DTYPE_UINT2:
      return "VIZ_DTYPE_UINT2";
    case VizDtype.VIZ_DTYPE_UINT3:
      return "VIZ_DTYPE_UINT3";
    case VizDtype.VIZ_DTYPE_UINT4:
      return "VIZ_DTYPE_UINT4";
    case VizDtype.VIZ_DTYPE_UINT5:
      return "VIZ_DTYPE_UINT5";
    case VizDtype.VIZ_DTYPE_UINT6:
      return "VIZ_DTYPE_UINT6";
    case VizDtype.VIZ_DTYPE_UINT7:
      return "VIZ_DTYPE_UINT7";
    case VizDtype.VIZ_DTYPE_INT4:
      return "VIZ_DTYPE_INT4";
    case VizDtype.VIZ_DTYPE_QINT4:
      return "VIZ_DTYPE_QINT4";
    case VizDtype.VIZ_DTYPE_QINT8:
      return "VIZ_DTYPE_QINT8";
    case VizDtype.VIZ_DTYPE_QINT16:
      return "VIZ_DTYPE_QINT16";
    case VizDtype.VIZ_DTYPE_QINT32:
      return "VIZ_DTYPE_QINT32";
    case VizDtype.VIZ_DTYPE_QUINT4:
      return "VIZ_DTYPE_QUINT4";
    case VizDtype.VIZ_DTYPE_QUINT8:
      return "VIZ_DTYPE_QUINT8";
    case VizDtype.VIZ_DTYPE_QUINT16:
      return "VIZ_DTYPE_QUINT16";
    case VizDtype.VIZ_DTYPE_QUINT32:
      return "VIZ_DTYPE_QUINT32";
    case VizDtype.VIZ_DTYPE_DICT_INT64_TO_FLOAT64:
      return "VIZ_DTYPE_DICT_INT64_TO_FLOAT64";
    case VizDtype.VIZ_DTYPE_DICT_STRING_TO_FLOAT64:
      return "VIZ_DTYPE_DICT_STRING_TO_FLOAT64";
    case VizDtype.VIZ_DTYPE_TFLITE_RESOURCE:
      return "VIZ_DTYPE_TFLITE_RESOURCE";
    case VizDtype.VIZ_DTYPE_TFLITE_VARIANT:
      return "VIZ_DTYPE_TFLITE_VARIANT";
    case VizDtype.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Multiple models can be associated with a single job (to reflect intermediate
 * compilation steps or JIT-compiled runtime graphs). This enum describes the
 * relationship between a job and a model.
 *
 * Currently we only fetch one producer (and not its producer). If a model
 * with a compile job producer is profiled, this will be the relations:
 *
 *      Compile Uploaded model          PRODUCER_SOURCE
 *      Compile Intermediate            PRODUCER_INTERMEDIATE
 *      Compile Target / Profile Input  TARGET
 *
 * In this case, we could call the final model SOURCE as well, but the language
 * we use for input models to profile jobs is "target" model.
 */
export enum ModelRelation {
  MODEL_RELATION_UNSPECIFIED = 0,
  MODEL_RELATION_PRODUCER_SOURCE = 1,
  MODEL_RELATION_PRODUCER_INTERMEDIATE = 2,
  MODEL_RELATION_SOURCE = 3,
  MODEL_RELATION_INTERMEDIATE = 4,
  MODEL_RELATION_TARGET = 5,
  UNRECOGNIZED = -1,
}

export function modelRelationFromJSON(object: any): ModelRelation {
  switch (object) {
    case 0:
    case "MODEL_RELATION_UNSPECIFIED":
      return ModelRelation.MODEL_RELATION_UNSPECIFIED;
    case 1:
    case "MODEL_RELATION_PRODUCER_SOURCE":
      return ModelRelation.MODEL_RELATION_PRODUCER_SOURCE;
    case 2:
    case "MODEL_RELATION_PRODUCER_INTERMEDIATE":
      return ModelRelation.MODEL_RELATION_PRODUCER_INTERMEDIATE;
    case 3:
    case "MODEL_RELATION_SOURCE":
      return ModelRelation.MODEL_RELATION_SOURCE;
    case 4:
    case "MODEL_RELATION_INTERMEDIATE":
      return ModelRelation.MODEL_RELATION_INTERMEDIATE;
    case 5:
    case "MODEL_RELATION_TARGET":
      return ModelRelation.MODEL_RELATION_TARGET;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ModelRelation.UNRECOGNIZED;
  }
}

export function modelRelationToJSON(object: ModelRelation): string {
  switch (object) {
    case ModelRelation.MODEL_RELATION_UNSPECIFIED:
      return "MODEL_RELATION_UNSPECIFIED";
    case ModelRelation.MODEL_RELATION_PRODUCER_SOURCE:
      return "MODEL_RELATION_PRODUCER_SOURCE";
    case ModelRelation.MODEL_RELATION_PRODUCER_INTERMEDIATE:
      return "MODEL_RELATION_PRODUCER_INTERMEDIATE";
    case ModelRelation.MODEL_RELATION_SOURCE:
      return "MODEL_RELATION_SOURCE";
    case ModelRelation.MODEL_RELATION_INTERMEDIATE:
      return "MODEL_RELATION_INTERMEDIATE";
    case ModelRelation.MODEL_RELATION_TARGET:
      return "MODEL_RELATION_TARGET";
    case ModelRelation.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Warning: this enum should match the ComputeUnit enum in ProfilerResults.proto. */
export enum ComputeUnit {
  COMPUTE_UNIT_UNSPECIFIED = 0,
  COMPUTE_UNIT_CPU = 1,
  COMPUTE_UNIT_GPU = 2,
  COMPUTE_UNIT_NPU = 3,
  UNRECOGNIZED = -1,
}

export function computeUnitFromJSON(object: any): ComputeUnit {
  switch (object) {
    case 0:
    case "COMPUTE_UNIT_UNSPECIFIED":
      return ComputeUnit.COMPUTE_UNIT_UNSPECIFIED;
    case 1:
    case "COMPUTE_UNIT_CPU":
      return ComputeUnit.COMPUTE_UNIT_CPU;
    case 2:
    case "COMPUTE_UNIT_GPU":
      return ComputeUnit.COMPUTE_UNIT_GPU;
    case 3:
    case "COMPUTE_UNIT_NPU":
      return ComputeUnit.COMPUTE_UNIT_NPU;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ComputeUnit.UNRECOGNIZED;
  }
}

export function computeUnitToJSON(object: ComputeUnit): string {
  switch (object) {
    case ComputeUnit.COMPUTE_UNIT_UNSPECIFIED:
      return "COMPUTE_UNIT_UNSPECIFIED";
    case ComputeUnit.COMPUTE_UNIT_CPU:
      return "COMPUTE_UNIT_CPU";
    case ComputeUnit.COMPUTE_UNIT_GPU:
      return "COMPUTE_UNIT_GPU";
    case ComputeUnit.COMPUTE_UNIT_NPU:
      return "COMPUTE_UNIT_NPU";
    case ComputeUnit.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Universal Response Message (URM) for PUT / PATCH / POST */
export interface CreateUpdateResponse {
  id: string;
  status: string;
  creationTime: Date | undefined;
  expirationTime: Date | undefined;
}

/**
 * GET /jobs/<id>/download_compiled_model/
 * GET /models/<id>/download/
 */
export interface FileDownloadURL {
  url: string;
  filename: string;
}

/**
 * GET /jobs/<id>/shared_access
 * GET /models/<id>/shared_access
 * GET /datasets/<id>/shared_access
 */
export interface SharedAccess {
  email: string[];
}

/** PATCH /jobs/<id>/shared_access */
export interface SharedAccessChange {
  /** Email address(es) to grant access to. */
  addEmail: string[];
  /** Email address(es) to revoke access from. */
  removeEmail: string[];
}

/**
 * GET /models/<id>/upload_url/
 * GET /datasets/<id>/upload_url/
 */
export interface FileUploadURL {
  url: string;
  fileFieldName: string;
  fields: { [key: string]: string };
}

export interface FileUploadURL_FieldsEntry {
  key: string;
  value: string;
}

/** GET /models/<id>/multipart_upload */
export interface FileMultipartUploadURL {
  urls: string[];
  uploadId: string;
  maxPartSize: Long;
}

/** POST /models/<id>/multipart_upload */
export interface FileMultipartUploadComplete {
  uploadId: string;
  etags: string[];
}

/**
 * Update a job
 * PATCH /jobs/<id>
 */
export interface JobPublicUpdate {
  name?: string | undefined;
}

/**
 * Query service status
 * GET /status/
 */
export interface ServiceStatus {
  /**
   * Message if available will be displayed as banner on Tetra Hub.
   * No message means service is functioning normally.
   */
  message?: string | undefined;
}

/**
 * Get basic profile information about a User
 * GET users/<id>
 *
 * Request: No Data
 * Response: message User
 */
export interface User {
  /** globally unique */
  id: Long;
  firstName: string;
  lastName: string;
  /** globally unique login credentials */
  email: string;
  /** authentication token */
  token?:
    | string
    | undefined;
  /** Organization id */
  orgId: Long;
}

/**
 * Get list of users
 * GET users
 *
 * Request: No Data
 * Response: message UserList
 */
export interface UserList {
  users: User[];
  totalQueryCount: Long;
}

/**
 * Change password of token-authenticated user.
 * PUT users/auth/change_password/
 *
 * Request: message UserChangePassword
 * Response:
 *   Failed   -> 400 with message CreateUpdateResponse (id not used)
 *   Success  -> 200 without message
 */
export interface UserChangePassword {
  oldPassword: string;
  newPassword: string;
}

/**
 * Get information about an Organization
 * GET organizations/<id>
 *
 * Request: No Data
 * Response: message Organization
 */
export interface Organization {
  /** globally unique */
  orgId: Long;
  name: string;
  members: UserList | undefined;
}

export interface Device {
  name: string;
  os: string;
  attributes: string[];
  socDescription: string;
}

/**
 * Get list of active devices
 * GET devices
 *
 * Request: No Data
 * Response: message DeviceList
 */
export interface DeviceList {
  devices: Device[];
  totalQueryCount: Long;
}

/**
 * Get a dataset.
 * GET /datasets/<id>
 *
 * REQUEST: No Message
 * RESPONSE: message Dataset
 *
 * -----------------------
 *
 * Create a dataset.
 * POST /datasets/
 *
 * REQUEST: message Dataset
 * RESPONSE: URM
 *
 * -----------------------
 *
 * Update a dataset.
 * PATCH /datasets/<id>
 *
 * REQUEST: message Dataset
 * RESPONSE: URM
 */
export interface Dataset {
  /** Unique identifier of the dataset */
  datasetId?:
    | string
    | undefined;
  /** Owner of the dataset */
  owner?:
    | User
    | undefined;
  /** Name of the dataset */
  name?:
    | string
    | undefined;
  /** Timestamp at which the dataset was uploaded to Hub */
  creationTime?:
    | Date
    | undefined;
  /** Timestamp at which the dataset would expire and no longer be available */
  expirationTime?: Date | undefined;
  fileUploadComplete?:
    | boolean
    | undefined;
  /**
   * true iff the requesting user has permissions to modify the job.
   * This is used solely for UX purposes, the backend does an independent
   * permission check on all API calls.
   */
  readOnly?:
    | boolean
    | undefined;
  /** If set to true during a POST request, the uploaded dataset becomes permanent (no expiry window). */
  permanent?: boolean | undefined;
}

/**
 * List all jobs you own.
 * GET /datasets
 *
 * REQUEST: No Data
 * RESPONSE: message DatasetList
 */
export interface DatasetList {
  datasets: Dataset[];
  totalQueryCount: Long;
}

export interface TensorType {
  shape: Long[];
  dtype: TensorDtype;
}

/** Name and tensor type */
export interface NamedTensorType {
  name: string;
  tensorType: TensorType | undefined;
}

export interface NamedTensorTypeList {
  types: NamedTensorType[];
}

export interface ModelMetadata {
  /** Key is a ModelMetadataKey (explicit enum key is disallowed) */
  entries: { [key: number]: string };
}

export interface ModelMetadata_EntriesEntry {
  key: number;
  value: string;
}

/**
 * Get a model.
 * GET /models/<id>
 *
 * REQUEST: No Message
 * RESPONSE: message Model
 *
 * -----------------------
 *
 * Create a model.
 * POST /models/
 *
 * REQUEST: message Model
 * RESPONSE: URM
 *
 * -----------------------
 *
 * Update a model.
 * PATCH /models/<id>
 *
 * REQUEST: message Model
 * RESPONSE: URM
 */
export interface Model {
  modelId?:
    | string
    | undefined;
  /** "user" (not "owner") in DB terms (#11312) */
  owner?: User | undefined;
  name?: string | undefined;
  creationTime?: Date | undefined;
  modelType?: ModelType | undefined;
  fileUploadComplete?: boolean | undefined;
  producerId?:
    | string
    | undefined;
  /**
   * true iff the requesting user has permissions to modify the job.
   * This is used solely for UX purposes, the backend does an independent
   * permission check on all API calls.
   */
  readOnly?: boolean | undefined;
  isDirectory?: boolean | undefined;
  hidden?:
    | boolean
    | undefined;
  /** applicable if it has producer_id */
  producerAttemptNumber?: number | undefined;
  metadata?: ModelMetadata | undefined;
  hasVizgraph?: boolean | undefined;
}

/**
 * List models user has access to.
 * GET /models
 *
 * REQUEST: No Message
 * RESPONSE: message Model
 */
export interface ModelList {
  models: Model[];
  totalQueryCount: Long;
}

export interface Notice {
  context: Notice_Context;
  severity: Notice_Severity;
  message: string;
}

export enum Notice_Severity {
  DEBUG = 0,
  INFO = 1,
  WARNING = 2,
  ERROR = 3,
  UNRECOGNIZED = -1,
}

export function notice_SeverityFromJSON(object: any): Notice_Severity {
  switch (object) {
    case 0:
    case "DEBUG":
      return Notice_Severity.DEBUG;
    case 1:
    case "INFO":
      return Notice_Severity.INFO;
    case 2:
    case "WARNING":
      return Notice_Severity.WARNING;
    case 3:
    case "ERROR":
      return Notice_Severity.ERROR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Notice_Severity.UNRECOGNIZED;
  }
}

export function notice_SeverityToJSON(object: Notice_Severity): string {
  switch (object) {
    case Notice_Severity.DEBUG:
      return "DEBUG";
    case Notice_Severity.INFO:
      return "INFO";
    case Notice_Severity.WARNING:
      return "WARNING";
    case Notice_Severity.ERROR:
      return "ERROR";
    case Notice_Severity.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum Notice_Context {
  DEVICE = 0,
  MODEL = 1,
  UNRECOGNIZED = -1,
}

export function notice_ContextFromJSON(object: any): Notice_Context {
  switch (object) {
    case 0:
    case "DEVICE":
      return Notice_Context.DEVICE;
    case 1:
    case "MODEL":
      return Notice_Context.MODEL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Notice_Context.UNRECOGNIZED;
  }
}

export function notice_ContextToJSON(object: Notice_Context): string {
  switch (object) {
    case Notice_Context.DEVICE:
      return "DEVICE";
    case Notice_Context.MODEL:
      return "MODEL";
    case Notice_Context.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Get a compile job.
 * GET /jobs/<id>
 *
 * REQUEST: No Data
 * RESPONSE: message CompileJob
 */
export interface CompileJob {
  compileJobId: string;
  user: User | undefined;
  userId: Long;
  /** input model */
  model: Model | undefined;
  jobState: JobState;
  /** device target. */
  device:
    | Device
    | undefined;
  /** compatible device targets */
  devices: DeviceList | undefined;
  tensorTypeList: NamedTensorTypeList | undefined;
  name: string;
  /** See tetrai/utils/flags.py for all valid compiler flags. */
  options: string;
  failureReason?: string | undefined;
  targetModel?:
    | Model
    | undefined;
  /** True if the user should expect a non-404 response from /jobs/<id>/vizgraph */
  hasVizgraph?:
    | boolean
    | undefined;
  /** Job first entered the database */
  creationTime:
    | Date
    | undefined;
  /** Job is no longer queued */
  startTime:
    | Date
    | undefined;
  /** Job has completed */
  completionTime:
    | Date
    | undefined;
  /** The timestamp at which the job's state last changed */
  lastUpdatedTime:
    | Date
    | undefined;
  /** The admin page URL for the job. Only populated if queried by a superuser. */
  adminUrl?:
    | string
    | undefined;
  /**
   * input shapes for compiled model. can differ from tensor_type_list in type (when quantizing model IO)
   * and/or in shape, when specifying model IO layouts
   */
  targetTensorTypeList:
    | NamedTensorTypeList
    | undefined;
  /**
   * Dataset to use for quantization calibration.
   * Used only if the model is quantized.
   */
  calibrationDataset?:
    | Dataset
    | undefined;
  /** Job was queued for processing */
  queuedTime: Date | undefined;
}

/**
 * Get a link job.
 * GET /jobs/<id>
 *
 * REQUEST: No Data
 * RESPONSE: message LinkJob
 */
export interface LinkJob {
  linkJobId: string;
  user: User | undefined;
  userId: Long;
  /** input models */
  models: ModelList | undefined;
  jobState: JobState;
  name: string;
  /** See tetrai/utils/flags.py for all valid link flags. */
  options: string;
  failureReason?: string | undefined;
  targetModel?:
    | Model
    | undefined;
  /** Job first entered the database */
  creationTime:
    | Date
    | undefined;
  /** Job is no longer queued */
  startTime:
    | Date
    | undefined;
  /** Job has completed */
  completionTime:
    | Date
    | undefined;
  /** The timestamp at which the job's state last changed */
  lastUpdatedTime:
    | Date
    | undefined;
  /** The admin page URL for the job. Only populated if queried by a superuser. */
  adminUrl?:
    | string
    | undefined;
  /** Job was queued for processing */
  queuedTime: Date | undefined;
}

/**
 * Get a quantize job.
 * GET /jobs/<id>
 *
 * REQUEST: No Data
 * RESPONSE: message QuantizeJob
 */
export interface QuantizeJob {
  quantizeJobId: string;
  user: User | undefined;
  userId: Long;
  model: Model | undefined;
  calibrationDataset: Dataset | undefined;
  weightsDtype: QuantizeDtype;
  activationsDtype: QuantizeDtype;
  name: string;
  jobState: JobState;
  options: string;
  tensorTypeList:
    | NamedTensorTypeList
    | undefined;
  /** Job first entered the database */
  creationTime:
    | Date
    | undefined;
  /** Job is no longer queued */
  startTime:
    | Date
    | undefined;
  /** Job has completed */
  completionTime:
    | Date
    | undefined;
  /** The timestamp at which the job's state last changed */
  lastUpdatedTime: Date | undefined;
  failureReason?: string | undefined;
  targetModel?:
    | Model
    | undefined;
  /** The admin page URL for the job. Only populated if queried by a superuser. */
  adminUrl?:
    | string
    | undefined;
  /** True if the user should expect a non-404 response from /jobs/<id>/vizgraph */
  hasVizgraph?:
    | boolean
    | undefined;
  /** Job was queued for processing */
  queuedTime: Date | undefined;
}

/**
 * Get a profile job.
 * GET /jobs/<id>
 *
 * REQUEST: No Data
 * RESPONSE: message ProfileJob
 */
export interface ProfileJob {
  profileJobId: string;
  user: User | undefined;
  userId: Long;
  model: Model | undefined;
  jobState: JobState;
  /** device target. */
  device: Device | undefined;
  tensorTypeList:
    | NamedTensorTypeList
    | undefined;
  /** Job first entered the database */
  creationTime:
    | Date
    | undefined;
  /** Job is no longer queued */
  startTime:
    | Date
    | undefined;
  /** Job has completed */
  completionTime:
    | Date
    | undefined;
  /** Some results are surfaced here (if ready), to allow table views */
  executionTime?: Long | undefined;
  failureReason?:
    | string
    | undefined;
  /** DEPRECATED */
  peakMemoryUsage?: Long | undefined;
  name: string;
  /** See tetrai/utils/flags.py for all valid profile flags. */
  options: string;
  dataset:
    | Dataset
    | undefined;
  /** used for legacy compile+profile jobs */
  targetModel?: Model | undefined;
  executionPeakMemory?:
    | Range
    | undefined;
  /** True if the user should expect a non-404 response from /jobs/<id>/vizgraph */
  hasVizgraph?:
    | boolean
    | undefined;
  /** The timestamp at which the job's state last changed */
  lastUpdatedTime:
    | Date
    | undefined;
  /** The admin page URL for the job. Only populated if queried by a superuser. */
  adminUrl?:
    | string
    | undefined;
  /** True iff this is an old profile job */
  includesCompile: boolean;
  /** Additional error context beyond failure_reason */
  errorDetails?:
    | string
    | undefined;
  /** Job was queued for processing */
  queuedTime: Date | undefined;
}

/**
 * Get an inference job.
 * GET /jobs/<id>
 *
 * REQUEST: No Data
 * RESPONSE: message InferenceJob
 */
export interface InferenceJob {
  /** job identification */
  inferenceJobId: string;
  /** unique user proto */
  user:
    | User
    | undefined;
  /** model used */
  model:
    | Model
    | undefined;
  /** device target. */
  device:
    | Device
    | undefined;
  /** state of the job */
  jobState: JobState;
  /** name provided */
  name: string;
  /** input data */
  dataset:
    | Dataset
    | undefined;
  /** Job first entered the database */
  creationTime:
    | Date
    | undefined;
  /** Job is no longer queued */
  startTime:
    | Date
    | undefined;
  /** Job has completed */
  completionTime:
    | Date
    | undefined;
  /** failure reason */
  failureReason?: string | undefined;
  options: string;
  /** The timestamp at which the job's state last changed */
  lastUpdatedTime:
    | Date
    | undefined;
  /** The admin page URL for the job. Only populated if queried by a superuser. */
  adminUrl?:
    | string
    | undefined;
  /** Additional error context beyond failure_reason */
  errorDetails?:
    | string
    | undefined;
  /** Job was queued for processing */
  queuedTime: Date | undefined;
}

/**
 * Get a job.
 * GET /jobs/<id>
 *
 * REQUEST: No Data
 * RESPONSE: message Job
 */
export interface Job {
  profileJob?: ProfileJob | undefined;
  inferenceJob?: InferenceJob | undefined;
  compileJob?: CompileJob | undefined;
  quantizeJob?: QuantizeJob | undefined;
  linkJob?: LinkJob | undefined;
  hubVersion?:
    | string
    | undefined;
  /**
   * true iff the requesting user has permissions to modify the job.
   * This is used solely for UX purposes, the backend does an independent
   * permission check on all API calls.
   */
  readOnly?:
    | boolean
    | undefined;
  /** Anything else users should know. */
  notices: Notice[];
  failureType: JobFailureType;
}

/**
 * List all jobs you own.
 * GET /jobs
 *
 * QUERY STRINGS:
 *   offset (int)       Offset the query
 *   limit (int)        Limit returned elements
 *   type (int/str)     Type of job (e.g. ?type=profile)
 *   state (int/str)    State of job (e.g. ?state=done)
 *
 * REQUEST: No Data
 * RESPONSE: message JobList
 */
export interface JobList {
  /** job proto */
  jobs: Job[];
  /** total count */
  totalQueryCount: Long;
}

/** A summary of the most important details of a job. */
export interface JobSummary {
  profileJobSummary?: ProfileJobSummary | undefined;
  inferenceJobSummary?: InferenceJobSummary | undefined;
  compileJobSummary?: CompileJobSummary | undefined;
  quantizeJobSummary?: QuantizeJobSummary | undefined;
  linkJobSummary?: LinkJobSummary | undefined;
}

export interface CompileJobSummary {
  jobId: string;
  user: User | undefined;
  name: string;
  jobState: JobState;
  /** device target. */
  deviceName: string;
  deviceOsVersion: string;
  /** Job first entered the database */
  creationTime:
    | Date
    | undefined;
  /** Job is no longer queued */
  startTime:
    | Date
    | undefined;
  /** Job has completed */
  completionTime:
    | Date
    | undefined;
  /** The timestamp at which the job's state last changed */
  lastUpdatedTime:
    | Date
    | undefined;
  /** Job was queued for processing */
  queuedTime: Date | undefined;
}

export interface LinkJobSummary {
  jobId: string;
  user: User | undefined;
  name: string;
  jobState: JobState;
  /** Job first entered the database */
  creationTime:
    | Date
    | undefined;
  /** Job is no longer queued */
  startTime:
    | Date
    | undefined;
  /** Job has completed */
  completionTime:
    | Date
    | undefined;
  /** The timestamp at which the job's state last changed */
  lastUpdatedTime:
    | Date
    | undefined;
  /** Job was queued for processing */
  queuedTime: Date | undefined;
}

export interface QuantizeJobSummary {
  jobId: string;
  user: User | undefined;
  name: string;
  jobState: JobState;
  /** Job first entered the database */
  creationTime:
    | Date
    | undefined;
  /** Job is no longer queued */
  startTime:
    | Date
    | undefined;
  /** Job has completed */
  completionTime:
    | Date
    | undefined;
  /** The timestamp at which the job's state last changed */
  lastUpdatedTime:
    | Date
    | undefined;
  /** Job was queued for processing */
  queuedTime: Date | undefined;
}

export interface ProfileJobSummary {
  jobId: string;
  user: User | undefined;
  name: string;
  jobState: JobState;
  /** device target. */
  deviceName: string;
  deviceOsVersion: string;
  /** Job first entered the database */
  creationTime:
    | Date
    | undefined;
  /** Job is no longer queued */
  startTime:
    | Date
    | undefined;
  /** Job has completed */
  completionTime:
    | Date
    | undefined;
  /** The timestamp at which the job's state last changed */
  lastUpdatedTime:
    | Date
    | undefined;
  /** Some results are surfaced here (if ready), to allow table views */
  executionTime?: Long | undefined;
  executionPeakMemory?:
    | Range
    | undefined;
  /** Job was queued for processing */
  queuedTime: Date | undefined;
}

export interface InferenceJobSummary {
  jobId: string;
  user: User | undefined;
  name: string;
  jobState: JobState;
  /** device target. */
  deviceName: string;
  deviceOsVersion: string;
  /** Job first entered the database */
  creationTime:
    | Date
    | undefined;
  /** Job is no longer queued */
  startTime:
    | Date
    | undefined;
  /** Job has completed */
  completionTime:
    | Date
    | undefined;
  /** The timestamp at which the job's state last changed */
  lastUpdatedTime:
    | Date
    | undefined;
  /** Job was queued for processing */
  queuedTime: Date | undefined;
}

/**
 * List all jobs you own.
 * GET /job_summaries
 *
 * QUERY STRINGS:
 *   offset (int)       Offset the query
 *   limit (int)        Limit returned elements
 *   type (int/str)     Type of job (e.g. ?type=profile)
 *   state (int/str)    State of job (e.g. ?state=done)
 *
 * REQUEST: No Data
 * RESPONSE: message JobSummaryList
 */
export interface JobSummaryList {
  jobSummaries: JobSummary[];
  /** total count */
  totalQueryCount: Long;
}

/**
 * Get a compile job's result.
 * GET /jobs/<id>/result
 *
 * REQUEST: No Data
 * RESPONSE: message CompileJobResult
 *   Failed   -> 404 job result is not available (query CompileJob for reason)
 *   Success  -> 200 with CompileJobResult
 */
export interface CompileJobResult {
  /** same as ID in URL */
  compileJobId: string;
  compileDetail?: CompileDetail | undefined;
}

/**
 * Get a link job's result.
 * GET /jobs/<id>/result
 *
 * REQUEST: No Data
 * RESPONSE: message LinkJobResult
 *   Failed   -> 404 job result is not available (query LinkJob for reason)
 *   Success  -> 200 with LinkJobResult
 */
export interface LinkJobResult {
  /** same as ID in URL */
  linkJobId: string;
  linkDetail?: LinkDetail | undefined;
}

/**
 * Get a quantize job's result.
 * GET /jobs/<id>/result
 *
 * REQUEST: No Data
 * RESPONSE: message QuantizeJobResult
 *   Failed   -> 404 job result is not available (query QuantizeJob for reason)
 *   Success  -> 200 with QuantizeJobResult
 */
export interface QuantizeJobResult {
  /** same as ID in URL */
  quantizeJobId: string;
  quantizeDetail?: QuantizeDetail | undefined;
}

/**
 * Get a profiling job's result.
 * GET /jobs/<id>/result
 *
 * REQUEST: No Data
 * RESPONSE: message ProfileJobResult
 *   Failed   -> 404 job result is not available (query ProfileJob for reason)
 *   Success  -> 200 with ProfileJobResult
 */
export interface ProfileJobResult {
  /** same as ID in URL */
  profileJobId: string;
  profile?: ProfileDetail | undefined;
}

/**
 * Get an inference job's result.
 * GET /jobs/<id>/result
 *
 * REQUEST: No Data
 * RESPONSE: message InferenceJobResult
 *   Failed   -> 404 job result is not available (query InferenceJob for reason)
 *   Successs -> 200 with InferenceJobResult
 */
export interface InferenceJobResult {
  /** same as ID in URL */
  inferenceJobId: string;
  /** result of inference */
  outputDatasetId: string;
  detail?: ProfileDetail | undefined;
}

export interface JobResult {
  /** ProfileJob result */
  profileJobResult?:
    | ProfileJobResult
    | undefined;
  /** InferenceJob result */
  inferenceJobResult?:
    | InferenceJobResult
    | undefined;
  /** CompileJob result */
  compileJobResult?:
    | CompileJobResult
    | undefined;
  /** QuantizeJob result */
  quantizeJobResult?:
    | QuantizeJobResult
    | undefined;
  /** LinkJob result */
  linkJobResult?: LinkJobResult | undefined;
}

/**
 * Get a job's visualization graph.
 * GET /jobs/<id>/vizgraph
 *
 * REQUEST: No Data
 * RESPONSE: message VizGraph
 *   Failed   -> 404 visualization graph is not available
 *   Success  -> 200 with VizGraph
 *
 * Represents a directed graph with node information.
 */
export interface VizGraph {
  /**
   * Graph type. This may dictate how things are rendered on the frontend.
   * The default GRAPH_UNKNOWN is also used to communicate a failed VizGraph
   * and should not be saved to the database.
   */
  graphType: VizGraphType;
  /**
   * Subgraphs. Many networks have only a single subgraph. See VizSubgraph
   * fore more information.
   */
  subgraphs: VizSubgraph[];
  /** Model-level parameters (e.g., Core ML/Android runtime version) */
  parameters: { [key: string]: VizValue };
  /**
   * Optional tensor information of the graph
   * The name refers to edge names (inputs/outputs in VizNode)
   * VizTensor contains name again, but map for convenient fast access
   */
  tensors: { [key: string]: VizTensor };
}

export interface VizGraph_ParametersEntry {
  key: string;
  value: VizValue | undefined;
}

export interface VizGraph_TensorsEntry {
  key: string;
  value: VizTensor | undefined;
}

/**
 * A subgraph. Many neural networks are entirely contained within a single
 * subgraph. However, multiple subgraph may be necessary if there are branching
 * or looping ops (e.g. if-branch/else-branch execute separate subgraphs).
 */
export interface VizSubgraph {
  /**
   * The main subgraph (index 0 in VizGraph) typically has an empty name, so
   * this is primarily to describe what additonal subgraphs do.
   */
  name: string;
  /** Nodes */
  nodes: VizNode[];
}

/**
 * Message representing a shape. The reason to make this a message as opposed
 * to place repeated directly in VizTensor was to allow a differentiation
 * between scalar (shape []) and undefined shape (previously stored as [], but
 * now using the optional sematics).
 */
export interface VizShape {
  /**
   * Empty shape is a scalar (although some backends may use shape [1] arrays
   * as scalars).
   * Negative values (e.g. -1) may be used for flexible length shapes,
   * although the exact semantics can be graph type specific.
   */
  dim: Long[];
}

/** Represents a tensor, either an intermediate representation or a weight */
export interface VizTensor {
  /** Tensor data type */
  dtype: VizDtype;
  /**
   * Shape of the tensor
   * Optional since we don't always know the shape of a tensor.
   */
  shape:
    | VizShape
    | undefined;
  /** Parameters can add additional information to a tensor (e.g. quantization) */
  parameters: { [key: string]: VizValue };
  /**
   * Optional value of tensors (used only for small tensors that are highly
   * informative to the user, such as padding amounts, permutation, etc.).
   * This is particularly useful for instance in TFLite. Other backends can
   * choose never to use it, if there is no clear benefit to the user. For 2D
   * or higher, stored in row-major flattening.
   */
  data: VizValue | undefined;
}

export interface VizTensor_ParametersEntry {
  key: string;
  value: VizValue | undefined;
}

/** Represents a single node in a directed graph. */
export interface VizNode {
  /** Unique layer name (should correspond to profiler analysis list) */
  name: string;
  /**
   * Layer type, e.g., "convolution"
   * The exact set of types depends on the graph type.
   * Two special types always exist: "input", "output".
   */
  opType: string;
  /**
   * Inputs and outputs: refers to other inputs/outputs and not necessarily the
   * node name. A node with multiple inputs and outputs may use different edge
   * names.
   */
  inputs: string[];
  outputs: string[];
  /**
   * Optionally provide input/output keys. Should either be empty or same
   * length as inputs/outputs.
   * The keys may also give hints how the inputs were flatten in cases where the
   * original representation had a hierarchical input. For instance MIL's
   * concat(values=[a, b, c]) can be flattened to three inputs with names
   * ["values:0", "values:1", "values:2"].
   */
  inputKeys: string[];
  outputKeys: string[];
  /**
   * Attributes are any parameters stored inside the node. VizAttributes can
   * be either values (VizValue), used for primitives and simple flast lists
   * of primitives, or tensors (VizTensor), which contains shape and metadata
   * of N-dimesnional tensors.
   */
  attributes: { [key: string]: VizAttribute };
  subgraph: Long;
}

export interface VizNode_AttributesEntry {
  key: string;
  value: VizAttribute | undefined;
}

/** Represents a parameter value. */
export interface VizValue {
  literalValue?: string | undefined;
  stringValue?: string | undefined;
  integerValue?: Long | undefined;
  floatValue?: number | undefined;
  boolValue?: boolean | undefined;
  stringList?: VizValue_StringList | undefined;
  integerList?: VizValue_IntegerList | undefined;
  floatList?: VizValue_FloatList | undefined;
  boolList?: VizValue_BoolList | undefined;
}

export interface VizValue_StringList {
  list: string[];
}

export interface VizValue_IntegerList {
  list: Long[];
}

export interface VizValue_FloatList {
  list: number[];
}

export interface VizValue_BoolList {
  list: boolean[];
}

/**
 * An attribute is either a value (primitive or flat list of primitives), or a
 * tensor (info and metadata of N-dimensional tensor without the weights)
 */
export interface VizAttribute {
  value?: VizValue | undefined;
  tensor?: VizTensor | undefined;
}

export interface JobAssociatedModel {
  modelId: string;
  jobId: string;
  modelType: ModelType;
  modelRelation: ModelRelation;
  hasVizgraph: boolean;
}

/**
 * GET /jobs/<id>/associated_models/
 *
 * REQUEST: No Data
 * RESPONSE: message JobAssociatedModels
 *   Failed   -> 404 job does not exist
 *   Success  -> 200 with no message
 *
 * This list is used for job visualization. Unlike model visualization, a
 * drop-down is presented with all models associated with jobs. If the model
 * has a vizgraph, the item can be selected and the visualization viewed.
 */
export interface JobAssociatedModels {
  associatedModels: JobAssociatedModel[];
}

export interface LayerDetail {
  /** Display name */
  name: string;
  computeUnit: ComputeUnit;
  layerTypeName: string;
  /** Similar to name, but guarantees to uniquely identify the layer (and may be opaque) */
  id: string;
  /** Name of delegate / execution provider (e.g. "MPS" or "NNAPI") */
  delegateName: string;
  /** Extra information about the delegate, such as underlying device or driver (e.g. "OpenCL" or "qti-gpu") */
  delegateExtraInfo: string;
  /** If available, the minimum execution time for this layer. */
  executionTime?:
    | Long
    | undefined;
  /** If available, the identifier of the segment to which this layer belongs. */
  segmentId?:
    | string
    | undefined;
  /**
   * Optional strings that represent the underlying operations reported by the runtime.
   * * Because of optimizations, there may be more than one reported here.
   * * There is no standard format for these; they are self-reported by delegates.
   */
  delegateReportedOps: string[];
  /** If available, the minimum execution cycles for this layer. */
  executionCycles?: Long | undefined;
}

export interface SegmentDetail {
  /** Uniquely identifies the segment */
  id: string;
  computeUnit: ComputeUnit;
  /** Name of delegate / execution provider (e.g. "MPS" or "NNAPI") */
  delegateName: string;
  /** Extra information about the delegate, such as underlying device or driver (e.g. "OpenCL" or "qti-gpu") */
  delegateExtraInfo: string;
  /** If available, the minimum execution time for this segment. */
  executionTime?: Long | undefined;
}

export interface Range {
  lower: Long;
  upper: Long;
}

export interface RuntimeConfigProperty {
  key: string;
  value: string;
}

/**
 * This message is used to describe a component's configuration that was used during a profile or inference
 * job. The properties map to the underlying component's (i.e., backend or delegate) representation for
 * specifying options. Only options that override defaults will be specified here.
 */
export interface RuntimeConfig {
  /** runtime component name */
  name: string;
  /** component configuration properties */
  properties: RuntimeConfigProperty[];
}

export interface ToolVersion {
  /** A tool and version used in profiling/inference */
  name: string;
  version: string;
}

export interface ProfileDetail {
  /** Version 0.0 */
  executionTime: Long;
  /** DEPRECATED */
  peakMemoryUsage: Long;
  /** DEPRECATED */
  loadTime?: Long | undefined;
  layerDetails: LayerDetail[];
  /** Added in version 1.0 */
  majorVersion: Long;
  minorVersion: Long;
  /** DEPRECATED */
  afterColdLoadPeakMemory: Long;
  /** DEPRECATED */
  afterCompilePeakMemory: Long;
  /** DEPRECATED */
  afterExecutionPeakMemory: Long;
  /** DEPRECATED */
  afterWarmLoadPeakMemory: Long;
  /** DEPRECATED */
  beforeColdLoadCurrentMemory: Long;
  /** DEPRECATED */
  beforeColdLoadPeakMemory: Long;
  /** DEPRECATED */
  beforeCompileCurrentMemory: Long;
  /** DEPRECATED */
  beforeCompilePeakMemory: Long;
  /** DEPRECATED */
  beforeExecutionCurrentMemory: Long;
  /** DEPRECATED */
  beforeExecutionPeakMemory: Long;
  /** DEPRECATED */
  beforeWarmLoadCurrentMemory: Long;
  /** DEPRECATED */
  beforeWarmLoadPeakMemory: Long;
  coldLoadTime: Long;
  compileTime: Long;
  warmLoadTime: Long;
  /** DEPRECATED */
  afterColdLoadCurrentMemory: Long;
  /** DEPRECATED */
  afterWarmLoadCurrentMemory: Long;
  /** DEPRECATED */
  afterCompileCurrentMemory: Long;
  /** DEPRECATED */
  afterExecutionCurrentMemory: Long;
  /** Added in version 1.1 */
  compileMemory?: ProfileDetail_MemoryUsage | undefined;
  coldLoadMemory?: ProfileDetail_MemoryUsage | undefined;
  warmLoadMemory?: ProfileDetail_MemoryUsage | undefined;
  executionMemory?:
    | ProfileDetail_MemoryUsage
    | undefined;
  /** Added in version 1.2 */
  allCompileTimes: Long[];
  allColdLoadTimes: Long[];
  allWarmLoadTimes: Long[];
  allExecutionTimes: Long[];
  /** Added in version 1.3 */
  segmentDetails: SegmentDetail[];
  /** Added in version 1.6 */
  runtimeConfig: RuntimeConfig[];
  /** Added in version 1.7 */
  toolVersions: ToolVersion[];
}

export interface ProfileDetail_MemoryUsage {
  increase: Range | undefined;
  peak: Range | undefined;
}

export interface CompileDetail {
  toolVersions: ToolVersion[];
}

export interface LinkDetail {
  toolVersions: ToolVersion[];
}

export interface QuantizeDetail {
  toolVersions: ToolVersion[];
  /** Map from output name to psnr value */
  psnr: { [key: string]: number };
}

export interface QuantizeDetail_PsnrEntry {
  key: string;
  value: number;
}

function createBaseCreateUpdateResponse(): CreateUpdateResponse {
  return { id: "", status: "", creationTime: undefined, expirationTime: undefined };
}

export const CreateUpdateResponse: MessageFns<CreateUpdateResponse> = {
  encode(message: CreateUpdateResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.status !== "") {
      writer.uint32(18).string(message.status);
    }
    if (message.creationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.creationTime), writer.uint32(26).fork()).join();
    }
    if (message.expirationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.expirationTime), writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateUpdateResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUpdateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.status = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.creationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.expirationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateUpdateResponse {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      creationTime: isSet(object.creationTime) ? fromJsonTimestamp(object.creationTime) : undefined,
      expirationTime: isSet(object.expirationTime) ? fromJsonTimestamp(object.expirationTime) : undefined,
    };
  },

  toJSON(message: CreateUpdateResponse): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.creationTime !== undefined) {
      obj.creationTime = message.creationTime.toISOString();
    }
    if (message.expirationTime !== undefined) {
      obj.expirationTime = message.expirationTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateUpdateResponse>, I>>(base?: I): CreateUpdateResponse {
    return CreateUpdateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateUpdateResponse>, I>>(object: I): CreateUpdateResponse {
    const message = createBaseCreateUpdateResponse();
    message.id = object.id ?? "";
    message.status = object.status ?? "";
    message.creationTime = object.creationTime ?? undefined;
    message.expirationTime = object.expirationTime ?? undefined;
    return message;
  },
};

function createBaseFileDownloadURL(): FileDownloadURL {
  return { url: "", filename: "" };
}

export const FileDownloadURL: MessageFns<FileDownloadURL> = {
  encode(message: FileDownloadURL, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.filename !== "") {
      writer.uint32(18).string(message.filename);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FileDownloadURL {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileDownloadURL();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filename = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FileDownloadURL {
    return {
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      filename: isSet(object.filename) ? globalThis.String(object.filename) : "",
    };
  },

  toJSON(message: FileDownloadURL): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.filename !== "") {
      obj.filename = message.filename;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FileDownloadURL>, I>>(base?: I): FileDownloadURL {
    return FileDownloadURL.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileDownloadURL>, I>>(object: I): FileDownloadURL {
    const message = createBaseFileDownloadURL();
    message.url = object.url ?? "";
    message.filename = object.filename ?? "";
    return message;
  },
};

function createBaseSharedAccess(): SharedAccess {
  return { email: [] };
}

export const SharedAccess: MessageFns<SharedAccess> = {
  encode(message: SharedAccess, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.email) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SharedAccess {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSharedAccess();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.email.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SharedAccess {
    return { email: globalThis.Array.isArray(object?.email) ? object.email.map((e: any) => globalThis.String(e)) : [] };
  },

  toJSON(message: SharedAccess): unknown {
    const obj: any = {};
    if (message.email?.length) {
      obj.email = message.email;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SharedAccess>, I>>(base?: I): SharedAccess {
    return SharedAccess.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SharedAccess>, I>>(object: I): SharedAccess {
    const message = createBaseSharedAccess();
    message.email = object.email?.map((e) => e) || [];
    return message;
  },
};

function createBaseSharedAccessChange(): SharedAccessChange {
  return { addEmail: [], removeEmail: [] };
}

export const SharedAccessChange: MessageFns<SharedAccessChange> = {
  encode(message: SharedAccessChange, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.addEmail) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.removeEmail) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SharedAccessChange {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSharedAccessChange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.addEmail.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.removeEmail.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SharedAccessChange {
    return {
      addEmail: globalThis.Array.isArray(object?.addEmail) ? object.addEmail.map((e: any) => globalThis.String(e)) : [],
      removeEmail: globalThis.Array.isArray(object?.removeEmail)
        ? object.removeEmail.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: SharedAccessChange): unknown {
    const obj: any = {};
    if (message.addEmail?.length) {
      obj.addEmail = message.addEmail;
    }
    if (message.removeEmail?.length) {
      obj.removeEmail = message.removeEmail;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SharedAccessChange>, I>>(base?: I): SharedAccessChange {
    return SharedAccessChange.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SharedAccessChange>, I>>(object: I): SharedAccessChange {
    const message = createBaseSharedAccessChange();
    message.addEmail = object.addEmail?.map((e) => e) || [];
    message.removeEmail = object.removeEmail?.map((e) => e) || [];
    return message;
  },
};

function createBaseFileUploadURL(): FileUploadURL {
  return { url: "", fileFieldName: "", fields: {} };
}

export const FileUploadURL: MessageFns<FileUploadURL> = {
  encode(message: FileUploadURL, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.fileFieldName !== "") {
      writer.uint32(18).string(message.fileFieldName);
    }
    Object.entries(message.fields).forEach(([key, value]) => {
      FileUploadURL_FieldsEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FileUploadURL {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileUploadURL();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fileFieldName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = FileUploadURL_FieldsEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.fields[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FileUploadURL {
    return {
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      fileFieldName: isSet(object.fileFieldName) ? globalThis.String(object.fileFieldName) : "",
      fields: isObject(object.fields)
        ? Object.entries(object.fields).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: FileUploadURL): unknown {
    const obj: any = {};
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.fileFieldName !== "") {
      obj.fileFieldName = message.fileFieldName;
    }
    if (message.fields) {
      const entries = Object.entries(message.fields);
      if (entries.length > 0) {
        obj.fields = {};
        entries.forEach(([k, v]) => {
          obj.fields[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FileUploadURL>, I>>(base?: I): FileUploadURL {
    return FileUploadURL.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileUploadURL>, I>>(object: I): FileUploadURL {
    const message = createBaseFileUploadURL();
    message.url = object.url ?? "";
    message.fileFieldName = object.fileFieldName ?? "";
    message.fields = Object.entries(object.fields ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseFileUploadURL_FieldsEntry(): FileUploadURL_FieldsEntry {
  return { key: "", value: "" };
}

export const FileUploadURL_FieldsEntry: MessageFns<FileUploadURL_FieldsEntry> = {
  encode(message: FileUploadURL_FieldsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FileUploadURL_FieldsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileUploadURL_FieldsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FileUploadURL_FieldsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: FileUploadURL_FieldsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FileUploadURL_FieldsEntry>, I>>(base?: I): FileUploadURL_FieldsEntry {
    return FileUploadURL_FieldsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileUploadURL_FieldsEntry>, I>>(object: I): FileUploadURL_FieldsEntry {
    const message = createBaseFileUploadURL_FieldsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseFileMultipartUploadURL(): FileMultipartUploadURL {
  return { urls: [], uploadId: "", maxPartSize: Long.UZERO };
}

export const FileMultipartUploadURL: MessageFns<FileMultipartUploadURL> = {
  encode(message: FileMultipartUploadURL, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.urls) {
      writer.uint32(10).string(v!);
    }
    if (message.uploadId !== "") {
      writer.uint32(18).string(message.uploadId);
    }
    if (!message.maxPartSize.equals(Long.UZERO)) {
      writer.uint32(24).uint64(message.maxPartSize.toString());
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FileMultipartUploadURL {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileMultipartUploadURL();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.urls.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.uploadId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.maxPartSize = Long.fromString(reader.uint64().toString(), true);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FileMultipartUploadURL {
    return {
      urls: globalThis.Array.isArray(object?.urls) ? object.urls.map((e: any) => globalThis.String(e)) : [],
      uploadId: isSet(object.uploadId) ? globalThis.String(object.uploadId) : "",
      maxPartSize: isSet(object.maxPartSize) ? Long.fromValue(object.maxPartSize) : Long.UZERO,
    };
  },

  toJSON(message: FileMultipartUploadURL): unknown {
    const obj: any = {};
    if (message.urls?.length) {
      obj.urls = message.urls;
    }
    if (message.uploadId !== "") {
      obj.uploadId = message.uploadId;
    }
    if (!message.maxPartSize.equals(Long.UZERO)) {
      obj.maxPartSize = (message.maxPartSize || Long.UZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FileMultipartUploadURL>, I>>(base?: I): FileMultipartUploadURL {
    return FileMultipartUploadURL.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileMultipartUploadURL>, I>>(object: I): FileMultipartUploadURL {
    const message = createBaseFileMultipartUploadURL();
    message.urls = object.urls?.map((e) => e) || [];
    message.uploadId = object.uploadId ?? "";
    message.maxPartSize = (object.maxPartSize !== undefined && object.maxPartSize !== null)
      ? Long.fromValue(object.maxPartSize)
      : Long.UZERO;
    return message;
  },
};

function createBaseFileMultipartUploadComplete(): FileMultipartUploadComplete {
  return { uploadId: "", etags: [] };
}

export const FileMultipartUploadComplete: MessageFns<FileMultipartUploadComplete> = {
  encode(message: FileMultipartUploadComplete, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.uploadId !== "") {
      writer.uint32(10).string(message.uploadId);
    }
    for (const v of message.etags) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FileMultipartUploadComplete {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileMultipartUploadComplete();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uploadId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.etags.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FileMultipartUploadComplete {
    return {
      uploadId: isSet(object.uploadId) ? globalThis.String(object.uploadId) : "",
      etags: globalThis.Array.isArray(object?.etags) ? object.etags.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: FileMultipartUploadComplete): unknown {
    const obj: any = {};
    if (message.uploadId !== "") {
      obj.uploadId = message.uploadId;
    }
    if (message.etags?.length) {
      obj.etags = message.etags;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FileMultipartUploadComplete>, I>>(base?: I): FileMultipartUploadComplete {
    return FileMultipartUploadComplete.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileMultipartUploadComplete>, I>>(object: I): FileMultipartUploadComplete {
    const message = createBaseFileMultipartUploadComplete();
    message.uploadId = object.uploadId ?? "";
    message.etags = object.etags?.map((e) => e) || [];
    return message;
  },
};

function createBaseJobPublicUpdate(): JobPublicUpdate {
  return { name: undefined };
}

export const JobPublicUpdate: MessageFns<JobPublicUpdate> = {
  encode(message: JobPublicUpdate, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== undefined) {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): JobPublicUpdate {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobPublicUpdate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobPublicUpdate {
    return { name: isSet(object.name) ? globalThis.String(object.name) : undefined };
  },

  toJSON(message: JobPublicUpdate): unknown {
    const obj: any = {};
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobPublicUpdate>, I>>(base?: I): JobPublicUpdate {
    return JobPublicUpdate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JobPublicUpdate>, I>>(object: I): JobPublicUpdate {
    const message = createBaseJobPublicUpdate();
    message.name = object.name ?? undefined;
    return message;
  },
};

function createBaseServiceStatus(): ServiceStatus {
  return { message: undefined };
}

export const ServiceStatus: MessageFns<ServiceStatus> = {
  encode(message: ServiceStatus, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.message !== undefined) {
      writer.uint32(10).string(message.message);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ServiceStatus {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServiceStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServiceStatus {
    return { message: isSet(object.message) ? globalThis.String(object.message) : undefined };
  },

  toJSON(message: ServiceStatus): unknown {
    const obj: any = {};
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServiceStatus>, I>>(base?: I): ServiceStatus {
    return ServiceStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServiceStatus>, I>>(object: I): ServiceStatus {
    const message = createBaseServiceStatus();
    message.message = object.message ?? undefined;
    return message;
  },
};

function createBaseUser(): User {
  return { id: Long.UZERO, firstName: "", lastName: "", email: "", token: undefined, orgId: Long.UZERO };
}

export const User: MessageFns<User> = {
  encode(message: User, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (!message.id.equals(Long.UZERO)) {
      writer.uint32(8).uint64(message.id.toString());
    }
    if (message.firstName !== "") {
      writer.uint32(18).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(26).string(message.lastName);
    }
    if (message.email !== "") {
      writer.uint32(34).string(message.email);
    }
    if (message.token !== undefined) {
      writer.uint32(42).string(message.token);
    }
    if (!message.orgId.equals(Long.UZERO)) {
      writer.uint32(48).uint64(message.orgId.toString());
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): User {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.email = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.token = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.orgId = Long.fromString(reader.uint64().toString(), true);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      id: isSet(object.id) ? Long.fromValue(object.id) : Long.UZERO,
      firstName: isSet(object.firstName) ? globalThis.String(object.firstName) : "",
      lastName: isSet(object.lastName) ? globalThis.String(object.lastName) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      token: isSet(object.token) ? globalThis.String(object.token) : undefined,
      orgId: isSet(object.orgId) ? Long.fromValue(object.orgId) : Long.UZERO,
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    if (!message.id.equals(Long.UZERO)) {
      obj.id = (message.id || Long.UZERO).toString();
    }
    if (message.firstName !== "") {
      obj.firstName = message.firstName;
    }
    if (message.lastName !== "") {
      obj.lastName = message.lastName;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.token !== undefined) {
      obj.token = message.token;
    }
    if (!message.orgId.equals(Long.UZERO)) {
      obj.orgId = (message.orgId || Long.UZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.id = (object.id !== undefined && object.id !== null) ? Long.fromValue(object.id) : Long.UZERO;
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.email = object.email ?? "";
    message.token = object.token ?? undefined;
    message.orgId = (object.orgId !== undefined && object.orgId !== null) ? Long.fromValue(object.orgId) : Long.UZERO;
    return message;
  },
};

function createBaseUserList(): UserList {
  return { users: [], totalQueryCount: Long.UZERO };
}

export const UserList: MessageFns<UserList> = {
  encode(message: UserList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.users) {
      User.encode(v!, writer.uint32(10).fork()).join();
    }
    if (!message.totalQueryCount.equals(Long.UZERO)) {
      writer.uint32(16).uint64(message.totalQueryCount.toString());
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UserList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(User.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalQueryCount = Long.fromString(reader.uint64().toString(), true);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserList {
    return {
      users: globalThis.Array.isArray(object?.users) ? object.users.map((e: any) => User.fromJSON(e)) : [],
      totalQueryCount: isSet(object.totalQueryCount) ? Long.fromValue(object.totalQueryCount) : Long.UZERO,
    };
  },

  toJSON(message: UserList): unknown {
    const obj: any = {};
    if (message.users?.length) {
      obj.users = message.users.map((e) => User.toJSON(e));
    }
    if (!message.totalQueryCount.equals(Long.UZERO)) {
      obj.totalQueryCount = (message.totalQueryCount || Long.UZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserList>, I>>(base?: I): UserList {
    return UserList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserList>, I>>(object: I): UserList {
    const message = createBaseUserList();
    message.users = object.users?.map((e) => User.fromPartial(e)) || [];
    message.totalQueryCount = (object.totalQueryCount !== undefined && object.totalQueryCount !== null)
      ? Long.fromValue(object.totalQueryCount)
      : Long.UZERO;
    return message;
  },
};

function createBaseUserChangePassword(): UserChangePassword {
  return { oldPassword: "", newPassword: "" };
}

export const UserChangePassword: MessageFns<UserChangePassword> = {
  encode(message: UserChangePassword, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.oldPassword !== "") {
      writer.uint32(10).string(message.oldPassword);
    }
    if (message.newPassword !== "") {
      writer.uint32(18).string(message.newPassword);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UserChangePassword {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserChangePassword();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.oldPassword = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.newPassword = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserChangePassword {
    return {
      oldPassword: isSet(object.oldPassword) ? globalThis.String(object.oldPassword) : "",
      newPassword: isSet(object.newPassword) ? globalThis.String(object.newPassword) : "",
    };
  },

  toJSON(message: UserChangePassword): unknown {
    const obj: any = {};
    if (message.oldPassword !== "") {
      obj.oldPassword = message.oldPassword;
    }
    if (message.newPassword !== "") {
      obj.newPassword = message.newPassword;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserChangePassword>, I>>(base?: I): UserChangePassword {
    return UserChangePassword.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserChangePassword>, I>>(object: I): UserChangePassword {
    const message = createBaseUserChangePassword();
    message.oldPassword = object.oldPassword ?? "";
    message.newPassword = object.newPassword ?? "";
    return message;
  },
};

function createBaseOrganization(): Organization {
  return { orgId: Long.UZERO, name: "", members: undefined };
}

export const Organization: MessageFns<Organization> = {
  encode(message: Organization, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (!message.orgId.equals(Long.UZERO)) {
      writer.uint32(8).uint64(message.orgId.toString());
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.members !== undefined) {
      UserList.encode(message.members, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Organization {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrganization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.orgId = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.members = UserList.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Organization {
    return {
      orgId: isSet(object.orgId) ? Long.fromValue(object.orgId) : Long.UZERO,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      members: isSet(object.members) ? UserList.fromJSON(object.members) : undefined,
    };
  },

  toJSON(message: Organization): unknown {
    const obj: any = {};
    if (!message.orgId.equals(Long.UZERO)) {
      obj.orgId = (message.orgId || Long.UZERO).toString();
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.members !== undefined) {
      obj.members = UserList.toJSON(message.members);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Organization>, I>>(base?: I): Organization {
    return Organization.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Organization>, I>>(object: I): Organization {
    const message = createBaseOrganization();
    message.orgId = (object.orgId !== undefined && object.orgId !== null) ? Long.fromValue(object.orgId) : Long.UZERO;
    message.name = object.name ?? "";
    message.members = (object.members !== undefined && object.members !== null)
      ? UserList.fromPartial(object.members)
      : undefined;
    return message;
  },
};

function createBaseDevice(): Device {
  return { name: "", os: "", attributes: [], socDescription: "" };
}

export const Device: MessageFns<Device> = {
  encode(message: Device, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.os !== "") {
      writer.uint32(18).string(message.os);
    }
    for (const v of message.attributes) {
      writer.uint32(26).string(v!);
    }
    if (message.socDescription !== "") {
      writer.uint32(34).string(message.socDescription);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Device {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDevice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.os = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.attributes.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.socDescription = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Device {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      os: isSet(object.os) ? globalThis.String(object.os) : "",
      attributes: globalThis.Array.isArray(object?.attributes)
        ? object.attributes.map((e: any) => globalThis.String(e))
        : [],
      socDescription: isSet(object.socDescription) ? globalThis.String(object.socDescription) : "",
    };
  },

  toJSON(message: Device): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.os !== "") {
      obj.os = message.os;
    }
    if (message.attributes?.length) {
      obj.attributes = message.attributes;
    }
    if (message.socDescription !== "") {
      obj.socDescription = message.socDescription;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Device>, I>>(base?: I): Device {
    return Device.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Device>, I>>(object: I): Device {
    const message = createBaseDevice();
    message.name = object.name ?? "";
    message.os = object.os ?? "";
    message.attributes = object.attributes?.map((e) => e) || [];
    message.socDescription = object.socDescription ?? "";
    return message;
  },
};

function createBaseDeviceList(): DeviceList {
  return { devices: [], totalQueryCount: Long.UZERO };
}

export const DeviceList: MessageFns<DeviceList> = {
  encode(message: DeviceList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.devices) {
      Device.encode(v!, writer.uint32(10).fork()).join();
    }
    if (!message.totalQueryCount.equals(Long.UZERO)) {
      writer.uint32(16).uint64(message.totalQueryCount.toString());
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeviceList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.devices.push(Device.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalQueryCount = Long.fromString(reader.uint64().toString(), true);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceList {
    return {
      devices: globalThis.Array.isArray(object?.devices) ? object.devices.map((e: any) => Device.fromJSON(e)) : [],
      totalQueryCount: isSet(object.totalQueryCount) ? Long.fromValue(object.totalQueryCount) : Long.UZERO,
    };
  },

  toJSON(message: DeviceList): unknown {
    const obj: any = {};
    if (message.devices?.length) {
      obj.devices = message.devices.map((e) => Device.toJSON(e));
    }
    if (!message.totalQueryCount.equals(Long.UZERO)) {
      obj.totalQueryCount = (message.totalQueryCount || Long.UZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeviceList>, I>>(base?: I): DeviceList {
    return DeviceList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeviceList>, I>>(object: I): DeviceList {
    const message = createBaseDeviceList();
    message.devices = object.devices?.map((e) => Device.fromPartial(e)) || [];
    message.totalQueryCount = (object.totalQueryCount !== undefined && object.totalQueryCount !== null)
      ? Long.fromValue(object.totalQueryCount)
      : Long.UZERO;
    return message;
  },
};

function createBaseDataset(): Dataset {
  return {
    datasetId: undefined,
    owner: undefined,
    name: undefined,
    creationTime: undefined,
    expirationTime: undefined,
    fileUploadComplete: undefined,
    readOnly: undefined,
    permanent: undefined,
  };
}

export const Dataset: MessageFns<Dataset> = {
  encode(message: Dataset, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.datasetId !== undefined) {
      writer.uint32(10).string(message.datasetId);
    }
    if (message.owner !== undefined) {
      User.encode(message.owner, writer.uint32(18).fork()).join();
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.creationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.creationTime), writer.uint32(34).fork()).join();
    }
    if (message.expirationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.expirationTime), writer.uint32(42).fork()).join();
    }
    if (message.fileUploadComplete !== undefined) {
      writer.uint32(48).bool(message.fileUploadComplete);
    }
    if (message.readOnly !== undefined) {
      writer.uint32(56).bool(message.readOnly);
    }
    if (message.permanent !== undefined) {
      writer.uint32(64).bool(message.permanent);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Dataset {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.datasetId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.owner = User.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.creationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.expirationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.fileUploadComplete = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.readOnly = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.permanent = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Dataset {
    return {
      datasetId: isSet(object.datasetId) ? globalThis.String(object.datasetId) : undefined,
      owner: isSet(object.owner) ? User.fromJSON(object.owner) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : undefined,
      creationTime: isSet(object.creationTime) ? fromJsonTimestamp(object.creationTime) : undefined,
      expirationTime: isSet(object.expirationTime) ? fromJsonTimestamp(object.expirationTime) : undefined,
      fileUploadComplete: isSet(object.fileUploadComplete) ? globalThis.Boolean(object.fileUploadComplete) : undefined,
      readOnly: isSet(object.readOnly) ? globalThis.Boolean(object.readOnly) : undefined,
      permanent: isSet(object.permanent) ? globalThis.Boolean(object.permanent) : undefined,
    };
  },

  toJSON(message: Dataset): unknown {
    const obj: any = {};
    if (message.datasetId !== undefined) {
      obj.datasetId = message.datasetId;
    }
    if (message.owner !== undefined) {
      obj.owner = User.toJSON(message.owner);
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.creationTime !== undefined) {
      obj.creationTime = message.creationTime.toISOString();
    }
    if (message.expirationTime !== undefined) {
      obj.expirationTime = message.expirationTime.toISOString();
    }
    if (message.fileUploadComplete !== undefined) {
      obj.fileUploadComplete = message.fileUploadComplete;
    }
    if (message.readOnly !== undefined) {
      obj.readOnly = message.readOnly;
    }
    if (message.permanent !== undefined) {
      obj.permanent = message.permanent;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Dataset>, I>>(base?: I): Dataset {
    return Dataset.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Dataset>, I>>(object: I): Dataset {
    const message = createBaseDataset();
    message.datasetId = object.datasetId ?? undefined;
    message.owner = (object.owner !== undefined && object.owner !== null) ? User.fromPartial(object.owner) : undefined;
    message.name = object.name ?? undefined;
    message.creationTime = object.creationTime ?? undefined;
    message.expirationTime = object.expirationTime ?? undefined;
    message.fileUploadComplete = object.fileUploadComplete ?? undefined;
    message.readOnly = object.readOnly ?? undefined;
    message.permanent = object.permanent ?? undefined;
    return message;
  },
};

function createBaseDatasetList(): DatasetList {
  return { datasets: [], totalQueryCount: Long.UZERO };
}

export const DatasetList: MessageFns<DatasetList> = {
  encode(message: DatasetList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.datasets) {
      Dataset.encode(v!, writer.uint32(10).fork()).join();
    }
    if (!message.totalQueryCount.equals(Long.UZERO)) {
      writer.uint32(16).uint64(message.totalQueryCount.toString());
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DatasetList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDatasetList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.datasets.push(Dataset.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalQueryCount = Long.fromString(reader.uint64().toString(), true);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DatasetList {
    return {
      datasets: globalThis.Array.isArray(object?.datasets) ? object.datasets.map((e: any) => Dataset.fromJSON(e)) : [],
      totalQueryCount: isSet(object.totalQueryCount) ? Long.fromValue(object.totalQueryCount) : Long.UZERO,
    };
  },

  toJSON(message: DatasetList): unknown {
    const obj: any = {};
    if (message.datasets?.length) {
      obj.datasets = message.datasets.map((e) => Dataset.toJSON(e));
    }
    if (!message.totalQueryCount.equals(Long.UZERO)) {
      obj.totalQueryCount = (message.totalQueryCount || Long.UZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DatasetList>, I>>(base?: I): DatasetList {
    return DatasetList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DatasetList>, I>>(object: I): DatasetList {
    const message = createBaseDatasetList();
    message.datasets = object.datasets?.map((e) => Dataset.fromPartial(e)) || [];
    message.totalQueryCount = (object.totalQueryCount !== undefined && object.totalQueryCount !== null)
      ? Long.fromValue(object.totalQueryCount)
      : Long.UZERO;
    return message;
  },
};

function createBaseTensorType(): TensorType {
  return { shape: [], dtype: 0 };
}

export const TensorType: MessageFns<TensorType> = {
  encode(message: TensorType, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    writer.uint32(10).fork();
    for (const v of message.shape) {
      writer.uint64(v.toString());
    }
    writer.join();
    if (message.dtype !== 0) {
      writer.uint32(16).int32(message.dtype);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TensorType {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTensorType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.shape.push(Long.fromString(reader.uint64().toString(), true));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.shape.push(Long.fromString(reader.uint64().toString(), true));
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.dtype = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TensorType {
    return {
      shape: globalThis.Array.isArray(object?.shape) ? object.shape.map((e: any) => Long.fromValue(e)) : [],
      dtype: isSet(object.dtype) ? tensorDtypeFromJSON(object.dtype) : 0,
    };
  },

  toJSON(message: TensorType): unknown {
    const obj: any = {};
    if (message.shape?.length) {
      obj.shape = message.shape.map((e) => (e || Long.UZERO).toString());
    }
    if (message.dtype !== 0) {
      obj.dtype = tensorDtypeToJSON(message.dtype);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TensorType>, I>>(base?: I): TensorType {
    return TensorType.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TensorType>, I>>(object: I): TensorType {
    const message = createBaseTensorType();
    message.shape = object.shape?.map((e) => Long.fromValue(e)) || [];
    message.dtype = object.dtype ?? 0;
    return message;
  },
};

function createBaseNamedTensorType(): NamedTensorType {
  return { name: "", tensorType: undefined };
}

export const NamedTensorType: MessageFns<NamedTensorType> = {
  encode(message: NamedTensorType, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.tensorType !== undefined) {
      TensorType.encode(message.tensorType, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NamedTensorType {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNamedTensorType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tensorType = TensorType.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NamedTensorType {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      tensorType: isSet(object.tensorType) ? TensorType.fromJSON(object.tensorType) : undefined,
    };
  },

  toJSON(message: NamedTensorType): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.tensorType !== undefined) {
      obj.tensorType = TensorType.toJSON(message.tensorType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NamedTensorType>, I>>(base?: I): NamedTensorType {
    return NamedTensorType.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NamedTensorType>, I>>(object: I): NamedTensorType {
    const message = createBaseNamedTensorType();
    message.name = object.name ?? "";
    message.tensorType = (object.tensorType !== undefined && object.tensorType !== null)
      ? TensorType.fromPartial(object.tensorType)
      : undefined;
    return message;
  },
};

function createBaseNamedTensorTypeList(): NamedTensorTypeList {
  return { types: [] };
}

export const NamedTensorTypeList: MessageFns<NamedTensorTypeList> = {
  encode(message: NamedTensorTypeList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.types) {
      NamedTensorType.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): NamedTensorTypeList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNamedTensorTypeList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.types.push(NamedTensorType.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NamedTensorTypeList {
    return {
      types: globalThis.Array.isArray(object?.types) ? object.types.map((e: any) => NamedTensorType.fromJSON(e)) : [],
    };
  },

  toJSON(message: NamedTensorTypeList): unknown {
    const obj: any = {};
    if (message.types?.length) {
      obj.types = message.types.map((e) => NamedTensorType.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NamedTensorTypeList>, I>>(base?: I): NamedTensorTypeList {
    return NamedTensorTypeList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NamedTensorTypeList>, I>>(object: I): NamedTensorTypeList {
    const message = createBaseNamedTensorTypeList();
    message.types = object.types?.map((e) => NamedTensorType.fromPartial(e)) || [];
    return message;
  },
};

function createBaseModelMetadata(): ModelMetadata {
  return { entries: {} };
}

export const ModelMetadata: MessageFns<ModelMetadata> = {
  encode(message: ModelMetadata, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.entries).forEach(([key, value]) => {
      ModelMetadata_EntriesEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ModelMetadata {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModelMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = ModelMetadata_EntriesEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.entries[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModelMetadata {
    return {
      entries: isObject(object.entries)
        ? Object.entries(object.entries).reduce<{ [key: number]: string }>((acc, [key, value]) => {
          acc[globalThis.Number(key)] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ModelMetadata): unknown {
    const obj: any = {};
    if (message.entries) {
      const entries = Object.entries(message.entries);
      if (entries.length > 0) {
        obj.entries = {};
        entries.forEach(([k, v]) => {
          obj.entries[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ModelMetadata>, I>>(base?: I): ModelMetadata {
    return ModelMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ModelMetadata>, I>>(object: I): ModelMetadata {
    const message = createBaseModelMetadata();
    message.entries = Object.entries(object.entries ?? {}).reduce<{ [key: number]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[globalThis.Number(key)] = globalThis.String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseModelMetadata_EntriesEntry(): ModelMetadata_EntriesEntry {
  return { key: 0, value: "" };
}

export const ModelMetadata_EntriesEntry: MessageFns<ModelMetadata_EntriesEntry> = {
  encode(message: ModelMetadata_EntriesEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== 0) {
      writer.uint32(8).uint32(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ModelMetadata_EntriesEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModelMetadata_EntriesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.key = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModelMetadata_EntriesEntry {
    return {
      key: isSet(object.key) ? globalThis.Number(object.key) : 0,
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ModelMetadata_EntriesEntry): unknown {
    const obj: any = {};
    if (message.key !== 0) {
      obj.key = Math.round(message.key);
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ModelMetadata_EntriesEntry>, I>>(base?: I): ModelMetadata_EntriesEntry {
    return ModelMetadata_EntriesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ModelMetadata_EntriesEntry>, I>>(object: I): ModelMetadata_EntriesEntry {
    const message = createBaseModelMetadata_EntriesEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseModel(): Model {
  return {
    modelId: undefined,
    owner: undefined,
    name: undefined,
    creationTime: undefined,
    modelType: undefined,
    fileUploadComplete: undefined,
    producerId: undefined,
    readOnly: undefined,
    isDirectory: undefined,
    hidden: undefined,
    producerAttemptNumber: undefined,
    metadata: undefined,
    hasVizgraph: undefined,
  };
}

export const Model: MessageFns<Model> = {
  encode(message: Model, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.modelId !== undefined) {
      writer.uint32(10).string(message.modelId);
    }
    if (message.owner !== undefined) {
      User.encode(message.owner, writer.uint32(18).fork()).join();
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.creationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.creationTime), writer.uint32(34).fork()).join();
    }
    if (message.modelType !== undefined) {
      writer.uint32(40).int32(message.modelType);
    }
    if (message.fileUploadComplete !== undefined) {
      writer.uint32(48).bool(message.fileUploadComplete);
    }
    if (message.producerId !== undefined) {
      writer.uint32(58).string(message.producerId);
    }
    if (message.readOnly !== undefined) {
      writer.uint32(64).bool(message.readOnly);
    }
    if (message.isDirectory !== undefined) {
      writer.uint32(72).bool(message.isDirectory);
    }
    if (message.hidden !== undefined) {
      writer.uint32(80).bool(message.hidden);
    }
    if (message.producerAttemptNumber !== undefined) {
      writer.uint32(88).uint32(message.producerAttemptNumber);
    }
    if (message.metadata !== undefined) {
      ModelMetadata.encode(message.metadata, writer.uint32(98).fork()).join();
    }
    if (message.hasVizgraph !== undefined) {
      writer.uint32(104).bool(message.hasVizgraph);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Model {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modelId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.owner = User.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.creationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.modelType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.fileUploadComplete = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.producerId = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.readOnly = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.isDirectory = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.hidden = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.producerAttemptNumber = reader.uint32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.metadata = ModelMetadata.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.hasVizgraph = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Model {
    return {
      modelId: isSet(object.modelId) ? globalThis.String(object.modelId) : undefined,
      owner: isSet(object.owner) ? User.fromJSON(object.owner) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : undefined,
      creationTime: isSet(object.creationTime) ? fromJsonTimestamp(object.creationTime) : undefined,
      modelType: isSet(object.modelType) ? modelTypeFromJSON(object.modelType) : undefined,
      fileUploadComplete: isSet(object.fileUploadComplete) ? globalThis.Boolean(object.fileUploadComplete) : undefined,
      producerId: isSet(object.producerId) ? globalThis.String(object.producerId) : undefined,
      readOnly: isSet(object.readOnly) ? globalThis.Boolean(object.readOnly) : undefined,
      isDirectory: isSet(object.isDirectory) ? globalThis.Boolean(object.isDirectory) : undefined,
      hidden: isSet(object.hidden) ? globalThis.Boolean(object.hidden) : undefined,
      producerAttemptNumber: isSet(object.producerAttemptNumber)
        ? globalThis.Number(object.producerAttemptNumber)
        : undefined,
      metadata: isSet(object.metadata) ? ModelMetadata.fromJSON(object.metadata) : undefined,
      hasVizgraph: isSet(object.hasVizgraph) ? globalThis.Boolean(object.hasVizgraph) : undefined,
    };
  },

  toJSON(message: Model): unknown {
    const obj: any = {};
    if (message.modelId !== undefined) {
      obj.modelId = message.modelId;
    }
    if (message.owner !== undefined) {
      obj.owner = User.toJSON(message.owner);
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.creationTime !== undefined) {
      obj.creationTime = message.creationTime.toISOString();
    }
    if (message.modelType !== undefined) {
      obj.modelType = modelTypeToJSON(message.modelType);
    }
    if (message.fileUploadComplete !== undefined) {
      obj.fileUploadComplete = message.fileUploadComplete;
    }
    if (message.producerId !== undefined) {
      obj.producerId = message.producerId;
    }
    if (message.readOnly !== undefined) {
      obj.readOnly = message.readOnly;
    }
    if (message.isDirectory !== undefined) {
      obj.isDirectory = message.isDirectory;
    }
    if (message.hidden !== undefined) {
      obj.hidden = message.hidden;
    }
    if (message.producerAttemptNumber !== undefined) {
      obj.producerAttemptNumber = Math.round(message.producerAttemptNumber);
    }
    if (message.metadata !== undefined) {
      obj.metadata = ModelMetadata.toJSON(message.metadata);
    }
    if (message.hasVizgraph !== undefined) {
      obj.hasVizgraph = message.hasVizgraph;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Model>, I>>(base?: I): Model {
    return Model.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Model>, I>>(object: I): Model {
    const message = createBaseModel();
    message.modelId = object.modelId ?? undefined;
    message.owner = (object.owner !== undefined && object.owner !== null) ? User.fromPartial(object.owner) : undefined;
    message.name = object.name ?? undefined;
    message.creationTime = object.creationTime ?? undefined;
    message.modelType = object.modelType ?? undefined;
    message.fileUploadComplete = object.fileUploadComplete ?? undefined;
    message.producerId = object.producerId ?? undefined;
    message.readOnly = object.readOnly ?? undefined;
    message.isDirectory = object.isDirectory ?? undefined;
    message.hidden = object.hidden ?? undefined;
    message.producerAttemptNumber = object.producerAttemptNumber ?? undefined;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? ModelMetadata.fromPartial(object.metadata)
      : undefined;
    message.hasVizgraph = object.hasVizgraph ?? undefined;
    return message;
  },
};

function createBaseModelList(): ModelList {
  return { models: [], totalQueryCount: Long.UZERO };
}

export const ModelList: MessageFns<ModelList> = {
  encode(message: ModelList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.models) {
      Model.encode(v!, writer.uint32(10).fork()).join();
    }
    if (!message.totalQueryCount.equals(Long.UZERO)) {
      writer.uint32(16).uint64(message.totalQueryCount.toString());
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ModelList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModelList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.models.push(Model.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalQueryCount = Long.fromString(reader.uint64().toString(), true);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModelList {
    return {
      models: globalThis.Array.isArray(object?.models) ? object.models.map((e: any) => Model.fromJSON(e)) : [],
      totalQueryCount: isSet(object.totalQueryCount) ? Long.fromValue(object.totalQueryCount) : Long.UZERO,
    };
  },

  toJSON(message: ModelList): unknown {
    const obj: any = {};
    if (message.models?.length) {
      obj.models = message.models.map((e) => Model.toJSON(e));
    }
    if (!message.totalQueryCount.equals(Long.UZERO)) {
      obj.totalQueryCount = (message.totalQueryCount || Long.UZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ModelList>, I>>(base?: I): ModelList {
    return ModelList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ModelList>, I>>(object: I): ModelList {
    const message = createBaseModelList();
    message.models = object.models?.map((e) => Model.fromPartial(e)) || [];
    message.totalQueryCount = (object.totalQueryCount !== undefined && object.totalQueryCount !== null)
      ? Long.fromValue(object.totalQueryCount)
      : Long.UZERO;
    return message;
  },
};

function createBaseNotice(): Notice {
  return { context: 0, severity: 0, message: "" };
}

export const Notice: MessageFns<Notice> = {
  encode(message: Notice, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.context !== 0) {
      writer.uint32(8).int32(message.context);
    }
    if (message.severity !== 0) {
      writer.uint32(16).int32(message.severity);
    }
    if (message.message !== "") {
      writer.uint32(26).string(message.message);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Notice {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.context = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.severity = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Notice {
    return {
      context: isSet(object.context) ? notice_ContextFromJSON(object.context) : 0,
      severity: isSet(object.severity) ? notice_SeverityFromJSON(object.severity) : 0,
      message: isSet(object.message) ? globalThis.String(object.message) : "",
    };
  },

  toJSON(message: Notice): unknown {
    const obj: any = {};
    if (message.context !== 0) {
      obj.context = notice_ContextToJSON(message.context);
    }
    if (message.severity !== 0) {
      obj.severity = notice_SeverityToJSON(message.severity);
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Notice>, I>>(base?: I): Notice {
    return Notice.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Notice>, I>>(object: I): Notice {
    const message = createBaseNotice();
    message.context = object.context ?? 0;
    message.severity = object.severity ?? 0;
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseCompileJob(): CompileJob {
  return {
    compileJobId: "",
    user: undefined,
    userId: Long.UZERO,
    model: undefined,
    jobState: 0,
    device: undefined,
    devices: undefined,
    tensorTypeList: undefined,
    name: "",
    options: "",
    failureReason: undefined,
    targetModel: undefined,
    hasVizgraph: undefined,
    creationTime: undefined,
    startTime: undefined,
    completionTime: undefined,
    lastUpdatedTime: undefined,
    adminUrl: undefined,
    targetTensorTypeList: undefined,
    calibrationDataset: undefined,
    queuedTime: undefined,
  };
}

export const CompileJob: MessageFns<CompileJob> = {
  encode(message: CompileJob, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.compileJobId !== "") {
      writer.uint32(10).string(message.compileJobId);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).join();
    }
    if (!message.userId.equals(Long.UZERO)) {
      writer.uint32(24).uint64(message.userId.toString());
    }
    if (message.model !== undefined) {
      Model.encode(message.model, writer.uint32(34).fork()).join();
    }
    if (message.jobState !== 0) {
      writer.uint32(40).int32(message.jobState);
    }
    if (message.device !== undefined) {
      Device.encode(message.device, writer.uint32(50).fork()).join();
    }
    if (message.devices !== undefined) {
      DeviceList.encode(message.devices, writer.uint32(58).fork()).join();
    }
    if (message.tensorTypeList !== undefined) {
      NamedTensorTypeList.encode(message.tensorTypeList, writer.uint32(66).fork()).join();
    }
    if (message.name !== "") {
      writer.uint32(74).string(message.name);
    }
    if (message.options !== "") {
      writer.uint32(82).string(message.options);
    }
    if (message.failureReason !== undefined) {
      writer.uint32(90).string(message.failureReason);
    }
    if (message.targetModel !== undefined) {
      Model.encode(message.targetModel, writer.uint32(98).fork()).join();
    }
    if (message.hasVizgraph !== undefined) {
      writer.uint32(104).bool(message.hasVizgraph);
    }
    if (message.creationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.creationTime), writer.uint32(114).fork()).join();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(122).fork()).join();
    }
    if (message.completionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.completionTime), writer.uint32(130).fork()).join();
    }
    if (message.lastUpdatedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdatedTime), writer.uint32(138).fork()).join();
    }
    if (message.adminUrl !== undefined) {
      writer.uint32(146).string(message.adminUrl);
    }
    if (message.targetTensorTypeList !== undefined) {
      NamedTensorTypeList.encode(message.targetTensorTypeList, writer.uint32(154).fork()).join();
    }
    if (message.calibrationDataset !== undefined) {
      Dataset.encode(message.calibrationDataset, writer.uint32(162).fork()).join();
    }
    if (message.queuedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.queuedTime), writer.uint32(170).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CompileJob {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompileJob();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.compileJobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.userId = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.model = Model.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.jobState = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.device = Device.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.devices = DeviceList.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.tensorTypeList = NamedTensorTypeList.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.name = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.options = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.failureReason = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.targetModel = Model.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.hasVizgraph = reader.bool();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.creationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.completionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.lastUpdatedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.adminUrl = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.targetTensorTypeList = NamedTensorTypeList.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.calibrationDataset = Dataset.decode(reader, reader.uint32());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.queuedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompileJob {
    return {
      compileJobId: isSet(object.compileJobId) ? globalThis.String(object.compileJobId) : "",
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      userId: isSet(object.userId) ? Long.fromValue(object.userId) : Long.UZERO,
      model: isSet(object.model) ? Model.fromJSON(object.model) : undefined,
      jobState: isSet(object.jobState) ? jobStateFromJSON(object.jobState) : 0,
      device: isSet(object.device) ? Device.fromJSON(object.device) : undefined,
      devices: isSet(object.devices) ? DeviceList.fromJSON(object.devices) : undefined,
      tensorTypeList: isSet(object.tensorTypeList) ? NamedTensorTypeList.fromJSON(object.tensorTypeList) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      options: isSet(object.options) ? globalThis.String(object.options) : "",
      failureReason: isSet(object.failureReason) ? globalThis.String(object.failureReason) : undefined,
      targetModel: isSet(object.targetModel) ? Model.fromJSON(object.targetModel) : undefined,
      hasVizgraph: isSet(object.hasVizgraph) ? globalThis.Boolean(object.hasVizgraph) : undefined,
      creationTime: isSet(object.creationTime) ? fromJsonTimestamp(object.creationTime) : undefined,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      completionTime: isSet(object.completionTime) ? fromJsonTimestamp(object.completionTime) : undefined,
      lastUpdatedTime: isSet(object.lastUpdatedTime) ? fromJsonTimestamp(object.lastUpdatedTime) : undefined,
      adminUrl: isSet(object.adminUrl) ? globalThis.String(object.adminUrl) : undefined,
      targetTensorTypeList: isSet(object.targetTensorTypeList)
        ? NamedTensorTypeList.fromJSON(object.targetTensorTypeList)
        : undefined,
      calibrationDataset: isSet(object.calibrationDataset) ? Dataset.fromJSON(object.calibrationDataset) : undefined,
      queuedTime: isSet(object.queuedTime) ? fromJsonTimestamp(object.queuedTime) : undefined,
    };
  },

  toJSON(message: CompileJob): unknown {
    const obj: any = {};
    if (message.compileJobId !== "") {
      obj.compileJobId = message.compileJobId;
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (!message.userId.equals(Long.UZERO)) {
      obj.userId = (message.userId || Long.UZERO).toString();
    }
    if (message.model !== undefined) {
      obj.model = Model.toJSON(message.model);
    }
    if (message.jobState !== 0) {
      obj.jobState = jobStateToJSON(message.jobState);
    }
    if (message.device !== undefined) {
      obj.device = Device.toJSON(message.device);
    }
    if (message.devices !== undefined) {
      obj.devices = DeviceList.toJSON(message.devices);
    }
    if (message.tensorTypeList !== undefined) {
      obj.tensorTypeList = NamedTensorTypeList.toJSON(message.tensorTypeList);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.options !== "") {
      obj.options = message.options;
    }
    if (message.failureReason !== undefined) {
      obj.failureReason = message.failureReason;
    }
    if (message.targetModel !== undefined) {
      obj.targetModel = Model.toJSON(message.targetModel);
    }
    if (message.hasVizgraph !== undefined) {
      obj.hasVizgraph = message.hasVizgraph;
    }
    if (message.creationTime !== undefined) {
      obj.creationTime = message.creationTime.toISOString();
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.completionTime !== undefined) {
      obj.completionTime = message.completionTime.toISOString();
    }
    if (message.lastUpdatedTime !== undefined) {
      obj.lastUpdatedTime = message.lastUpdatedTime.toISOString();
    }
    if (message.adminUrl !== undefined) {
      obj.adminUrl = message.adminUrl;
    }
    if (message.targetTensorTypeList !== undefined) {
      obj.targetTensorTypeList = NamedTensorTypeList.toJSON(message.targetTensorTypeList);
    }
    if (message.calibrationDataset !== undefined) {
      obj.calibrationDataset = Dataset.toJSON(message.calibrationDataset);
    }
    if (message.queuedTime !== undefined) {
      obj.queuedTime = message.queuedTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompileJob>, I>>(base?: I): CompileJob {
    return CompileJob.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompileJob>, I>>(object: I): CompileJob {
    const message = createBaseCompileJob();
    message.compileJobId = object.compileJobId ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.userId = (object.userId !== undefined && object.userId !== null)
      ? Long.fromValue(object.userId)
      : Long.UZERO;
    message.model = (object.model !== undefined && object.model !== null) ? Model.fromPartial(object.model) : undefined;
    message.jobState = object.jobState ?? 0;
    message.device = (object.device !== undefined && object.device !== null)
      ? Device.fromPartial(object.device)
      : undefined;
    message.devices = (object.devices !== undefined && object.devices !== null)
      ? DeviceList.fromPartial(object.devices)
      : undefined;
    message.tensorTypeList = (object.tensorTypeList !== undefined && object.tensorTypeList !== null)
      ? NamedTensorTypeList.fromPartial(object.tensorTypeList)
      : undefined;
    message.name = object.name ?? "";
    message.options = object.options ?? "";
    message.failureReason = object.failureReason ?? undefined;
    message.targetModel = (object.targetModel !== undefined && object.targetModel !== null)
      ? Model.fromPartial(object.targetModel)
      : undefined;
    message.hasVizgraph = object.hasVizgraph ?? undefined;
    message.creationTime = object.creationTime ?? undefined;
    message.startTime = object.startTime ?? undefined;
    message.completionTime = object.completionTime ?? undefined;
    message.lastUpdatedTime = object.lastUpdatedTime ?? undefined;
    message.adminUrl = object.adminUrl ?? undefined;
    message.targetTensorTypeList = (object.targetTensorTypeList !== undefined && object.targetTensorTypeList !== null)
      ? NamedTensorTypeList.fromPartial(object.targetTensorTypeList)
      : undefined;
    message.calibrationDataset = (object.calibrationDataset !== undefined && object.calibrationDataset !== null)
      ? Dataset.fromPartial(object.calibrationDataset)
      : undefined;
    message.queuedTime = object.queuedTime ?? undefined;
    return message;
  },
};

function createBaseLinkJob(): LinkJob {
  return {
    linkJobId: "",
    user: undefined,
    userId: Long.UZERO,
    models: undefined,
    jobState: 0,
    name: "",
    options: "",
    failureReason: undefined,
    targetModel: undefined,
    creationTime: undefined,
    startTime: undefined,
    completionTime: undefined,
    lastUpdatedTime: undefined,
    adminUrl: undefined,
    queuedTime: undefined,
  };
}

export const LinkJob: MessageFns<LinkJob> = {
  encode(message: LinkJob, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.linkJobId !== "") {
      writer.uint32(10).string(message.linkJobId);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).join();
    }
    if (!message.userId.equals(Long.UZERO)) {
      writer.uint32(24).uint64(message.userId.toString());
    }
    if (message.models !== undefined) {
      ModelList.encode(message.models, writer.uint32(34).fork()).join();
    }
    if (message.jobState !== 0) {
      writer.uint32(40).int32(message.jobState);
    }
    if (message.name !== "") {
      writer.uint32(50).string(message.name);
    }
    if (message.options !== "") {
      writer.uint32(58).string(message.options);
    }
    if (message.failureReason !== undefined) {
      writer.uint32(66).string(message.failureReason);
    }
    if (message.targetModel !== undefined) {
      Model.encode(message.targetModel, writer.uint32(74).fork()).join();
    }
    if (message.creationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.creationTime), writer.uint32(82).fork()).join();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(90).fork()).join();
    }
    if (message.completionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.completionTime), writer.uint32(98).fork()).join();
    }
    if (message.lastUpdatedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdatedTime), writer.uint32(106).fork()).join();
    }
    if (message.adminUrl !== undefined) {
      writer.uint32(114).string(message.adminUrl);
    }
    if (message.queuedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.queuedTime), writer.uint32(122).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LinkJob {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLinkJob();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkJobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.userId = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.models = ModelList.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.jobState = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.name = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.options = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.failureReason = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.targetModel = Model.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.creationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.completionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.lastUpdatedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.adminUrl = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.queuedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LinkJob {
    return {
      linkJobId: isSet(object.linkJobId) ? globalThis.String(object.linkJobId) : "",
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      userId: isSet(object.userId) ? Long.fromValue(object.userId) : Long.UZERO,
      models: isSet(object.models) ? ModelList.fromJSON(object.models) : undefined,
      jobState: isSet(object.jobState) ? jobStateFromJSON(object.jobState) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      options: isSet(object.options) ? globalThis.String(object.options) : "",
      failureReason: isSet(object.failureReason) ? globalThis.String(object.failureReason) : undefined,
      targetModel: isSet(object.targetModel) ? Model.fromJSON(object.targetModel) : undefined,
      creationTime: isSet(object.creationTime) ? fromJsonTimestamp(object.creationTime) : undefined,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      completionTime: isSet(object.completionTime) ? fromJsonTimestamp(object.completionTime) : undefined,
      lastUpdatedTime: isSet(object.lastUpdatedTime) ? fromJsonTimestamp(object.lastUpdatedTime) : undefined,
      adminUrl: isSet(object.adminUrl) ? globalThis.String(object.adminUrl) : undefined,
      queuedTime: isSet(object.queuedTime) ? fromJsonTimestamp(object.queuedTime) : undefined,
    };
  },

  toJSON(message: LinkJob): unknown {
    const obj: any = {};
    if (message.linkJobId !== "") {
      obj.linkJobId = message.linkJobId;
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (!message.userId.equals(Long.UZERO)) {
      obj.userId = (message.userId || Long.UZERO).toString();
    }
    if (message.models !== undefined) {
      obj.models = ModelList.toJSON(message.models);
    }
    if (message.jobState !== 0) {
      obj.jobState = jobStateToJSON(message.jobState);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.options !== "") {
      obj.options = message.options;
    }
    if (message.failureReason !== undefined) {
      obj.failureReason = message.failureReason;
    }
    if (message.targetModel !== undefined) {
      obj.targetModel = Model.toJSON(message.targetModel);
    }
    if (message.creationTime !== undefined) {
      obj.creationTime = message.creationTime.toISOString();
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.completionTime !== undefined) {
      obj.completionTime = message.completionTime.toISOString();
    }
    if (message.lastUpdatedTime !== undefined) {
      obj.lastUpdatedTime = message.lastUpdatedTime.toISOString();
    }
    if (message.adminUrl !== undefined) {
      obj.adminUrl = message.adminUrl;
    }
    if (message.queuedTime !== undefined) {
      obj.queuedTime = message.queuedTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LinkJob>, I>>(base?: I): LinkJob {
    return LinkJob.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LinkJob>, I>>(object: I): LinkJob {
    const message = createBaseLinkJob();
    message.linkJobId = object.linkJobId ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.userId = (object.userId !== undefined && object.userId !== null)
      ? Long.fromValue(object.userId)
      : Long.UZERO;
    message.models = (object.models !== undefined && object.models !== null)
      ? ModelList.fromPartial(object.models)
      : undefined;
    message.jobState = object.jobState ?? 0;
    message.name = object.name ?? "";
    message.options = object.options ?? "";
    message.failureReason = object.failureReason ?? undefined;
    message.targetModel = (object.targetModel !== undefined && object.targetModel !== null)
      ? Model.fromPartial(object.targetModel)
      : undefined;
    message.creationTime = object.creationTime ?? undefined;
    message.startTime = object.startTime ?? undefined;
    message.completionTime = object.completionTime ?? undefined;
    message.lastUpdatedTime = object.lastUpdatedTime ?? undefined;
    message.adminUrl = object.adminUrl ?? undefined;
    message.queuedTime = object.queuedTime ?? undefined;
    return message;
  },
};

function createBaseQuantizeJob(): QuantizeJob {
  return {
    quantizeJobId: "",
    user: undefined,
    userId: Long.UZERO,
    model: undefined,
    calibrationDataset: undefined,
    weightsDtype: 0,
    activationsDtype: 0,
    name: "",
    jobState: 0,
    options: "",
    tensorTypeList: undefined,
    creationTime: undefined,
    startTime: undefined,
    completionTime: undefined,
    lastUpdatedTime: undefined,
    failureReason: undefined,
    targetModel: undefined,
    adminUrl: undefined,
    hasVizgraph: undefined,
    queuedTime: undefined,
  };
}

export const QuantizeJob: MessageFns<QuantizeJob> = {
  encode(message: QuantizeJob, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.quantizeJobId !== "") {
      writer.uint32(10).string(message.quantizeJobId);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).join();
    }
    if (!message.userId.equals(Long.UZERO)) {
      writer.uint32(24).uint64(message.userId.toString());
    }
    if (message.model !== undefined) {
      Model.encode(message.model, writer.uint32(34).fork()).join();
    }
    if (message.calibrationDataset !== undefined) {
      Dataset.encode(message.calibrationDataset, writer.uint32(42).fork()).join();
    }
    if (message.weightsDtype !== 0) {
      writer.uint32(48).int32(message.weightsDtype);
    }
    if (message.activationsDtype !== 0) {
      writer.uint32(56).int32(message.activationsDtype);
    }
    if (message.name !== "") {
      writer.uint32(66).string(message.name);
    }
    if (message.jobState !== 0) {
      writer.uint32(72).int32(message.jobState);
    }
    if (message.options !== "") {
      writer.uint32(82).string(message.options);
    }
    if (message.tensorTypeList !== undefined) {
      NamedTensorTypeList.encode(message.tensorTypeList, writer.uint32(90).fork()).join();
    }
    if (message.creationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.creationTime), writer.uint32(98).fork()).join();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(106).fork()).join();
    }
    if (message.completionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.completionTime), writer.uint32(114).fork()).join();
    }
    if (message.lastUpdatedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdatedTime), writer.uint32(122).fork()).join();
    }
    if (message.failureReason !== undefined) {
      writer.uint32(130).string(message.failureReason);
    }
    if (message.targetModel !== undefined) {
      Model.encode(message.targetModel, writer.uint32(138).fork()).join();
    }
    if (message.adminUrl !== undefined) {
      writer.uint32(146).string(message.adminUrl);
    }
    if (message.hasVizgraph !== undefined) {
      writer.uint32(152).bool(message.hasVizgraph);
    }
    if (message.queuedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.queuedTime), writer.uint32(162).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QuantizeJob {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantizeJob();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quantizeJobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.userId = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.model = Model.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.calibrationDataset = Dataset.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.weightsDtype = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.activationsDtype = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.name = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.jobState = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.options = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.tensorTypeList = NamedTensorTypeList.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.creationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.completionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.lastUpdatedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.failureReason = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.targetModel = Model.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.adminUrl = reader.string();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.hasVizgraph = reader.bool();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.queuedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantizeJob {
    return {
      quantizeJobId: isSet(object.quantizeJobId) ? globalThis.String(object.quantizeJobId) : "",
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      userId: isSet(object.userId) ? Long.fromValue(object.userId) : Long.UZERO,
      model: isSet(object.model) ? Model.fromJSON(object.model) : undefined,
      calibrationDataset: isSet(object.calibrationDataset) ? Dataset.fromJSON(object.calibrationDataset) : undefined,
      weightsDtype: isSet(object.weightsDtype) ? quantizeDtypeFromJSON(object.weightsDtype) : 0,
      activationsDtype: isSet(object.activationsDtype) ? quantizeDtypeFromJSON(object.activationsDtype) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      jobState: isSet(object.jobState) ? jobStateFromJSON(object.jobState) : 0,
      options: isSet(object.options) ? globalThis.String(object.options) : "",
      tensorTypeList: isSet(object.tensorTypeList) ? NamedTensorTypeList.fromJSON(object.tensorTypeList) : undefined,
      creationTime: isSet(object.creationTime) ? fromJsonTimestamp(object.creationTime) : undefined,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      completionTime: isSet(object.completionTime) ? fromJsonTimestamp(object.completionTime) : undefined,
      lastUpdatedTime: isSet(object.lastUpdatedTime) ? fromJsonTimestamp(object.lastUpdatedTime) : undefined,
      failureReason: isSet(object.failureReason) ? globalThis.String(object.failureReason) : undefined,
      targetModel: isSet(object.targetModel) ? Model.fromJSON(object.targetModel) : undefined,
      adminUrl: isSet(object.adminUrl) ? globalThis.String(object.adminUrl) : undefined,
      hasVizgraph: isSet(object.hasVizgraph) ? globalThis.Boolean(object.hasVizgraph) : undefined,
      queuedTime: isSet(object.queuedTime) ? fromJsonTimestamp(object.queuedTime) : undefined,
    };
  },

  toJSON(message: QuantizeJob): unknown {
    const obj: any = {};
    if (message.quantizeJobId !== "") {
      obj.quantizeJobId = message.quantizeJobId;
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (!message.userId.equals(Long.UZERO)) {
      obj.userId = (message.userId || Long.UZERO).toString();
    }
    if (message.model !== undefined) {
      obj.model = Model.toJSON(message.model);
    }
    if (message.calibrationDataset !== undefined) {
      obj.calibrationDataset = Dataset.toJSON(message.calibrationDataset);
    }
    if (message.weightsDtype !== 0) {
      obj.weightsDtype = quantizeDtypeToJSON(message.weightsDtype);
    }
    if (message.activationsDtype !== 0) {
      obj.activationsDtype = quantizeDtypeToJSON(message.activationsDtype);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.jobState !== 0) {
      obj.jobState = jobStateToJSON(message.jobState);
    }
    if (message.options !== "") {
      obj.options = message.options;
    }
    if (message.tensorTypeList !== undefined) {
      obj.tensorTypeList = NamedTensorTypeList.toJSON(message.tensorTypeList);
    }
    if (message.creationTime !== undefined) {
      obj.creationTime = message.creationTime.toISOString();
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.completionTime !== undefined) {
      obj.completionTime = message.completionTime.toISOString();
    }
    if (message.lastUpdatedTime !== undefined) {
      obj.lastUpdatedTime = message.lastUpdatedTime.toISOString();
    }
    if (message.failureReason !== undefined) {
      obj.failureReason = message.failureReason;
    }
    if (message.targetModel !== undefined) {
      obj.targetModel = Model.toJSON(message.targetModel);
    }
    if (message.adminUrl !== undefined) {
      obj.adminUrl = message.adminUrl;
    }
    if (message.hasVizgraph !== undefined) {
      obj.hasVizgraph = message.hasVizgraph;
    }
    if (message.queuedTime !== undefined) {
      obj.queuedTime = message.queuedTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantizeJob>, I>>(base?: I): QuantizeJob {
    return QuantizeJob.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QuantizeJob>, I>>(object: I): QuantizeJob {
    const message = createBaseQuantizeJob();
    message.quantizeJobId = object.quantizeJobId ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.userId = (object.userId !== undefined && object.userId !== null)
      ? Long.fromValue(object.userId)
      : Long.UZERO;
    message.model = (object.model !== undefined && object.model !== null) ? Model.fromPartial(object.model) : undefined;
    message.calibrationDataset = (object.calibrationDataset !== undefined && object.calibrationDataset !== null)
      ? Dataset.fromPartial(object.calibrationDataset)
      : undefined;
    message.weightsDtype = object.weightsDtype ?? 0;
    message.activationsDtype = object.activationsDtype ?? 0;
    message.name = object.name ?? "";
    message.jobState = object.jobState ?? 0;
    message.options = object.options ?? "";
    message.tensorTypeList = (object.tensorTypeList !== undefined && object.tensorTypeList !== null)
      ? NamedTensorTypeList.fromPartial(object.tensorTypeList)
      : undefined;
    message.creationTime = object.creationTime ?? undefined;
    message.startTime = object.startTime ?? undefined;
    message.completionTime = object.completionTime ?? undefined;
    message.lastUpdatedTime = object.lastUpdatedTime ?? undefined;
    message.failureReason = object.failureReason ?? undefined;
    message.targetModel = (object.targetModel !== undefined && object.targetModel !== null)
      ? Model.fromPartial(object.targetModel)
      : undefined;
    message.adminUrl = object.adminUrl ?? undefined;
    message.hasVizgraph = object.hasVizgraph ?? undefined;
    message.queuedTime = object.queuedTime ?? undefined;
    return message;
  },
};

function createBaseProfileJob(): ProfileJob {
  return {
    profileJobId: "",
    user: undefined,
    userId: Long.UZERO,
    model: undefined,
    jobState: 0,
    device: undefined,
    tensorTypeList: undefined,
    creationTime: undefined,
    startTime: undefined,
    completionTime: undefined,
    executionTime: undefined,
    failureReason: undefined,
    peakMemoryUsage: undefined,
    name: "",
    options: "",
    dataset: undefined,
    targetModel: undefined,
    executionPeakMemory: undefined,
    hasVizgraph: undefined,
    lastUpdatedTime: undefined,
    adminUrl: undefined,
    includesCompile: false,
    errorDetails: undefined,
    queuedTime: undefined,
  };
}

export const ProfileJob: MessageFns<ProfileJob> = {
  encode(message: ProfileJob, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.profileJobId !== "") {
      writer.uint32(10).string(message.profileJobId);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).join();
    }
    if (!message.userId.equals(Long.UZERO)) {
      writer.uint32(24).uint64(message.userId.toString());
    }
    if (message.model !== undefined) {
      Model.encode(message.model, writer.uint32(34).fork()).join();
    }
    if (message.jobState !== 0) {
      writer.uint32(40).int32(message.jobState);
    }
    if (message.device !== undefined) {
      Device.encode(message.device, writer.uint32(50).fork()).join();
    }
    if (message.tensorTypeList !== undefined) {
      NamedTensorTypeList.encode(message.tensorTypeList, writer.uint32(58).fork()).join();
    }
    if (message.creationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.creationTime), writer.uint32(66).fork()).join();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(74).fork()).join();
    }
    if (message.completionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.completionTime), writer.uint32(82).fork()).join();
    }
    if (message.executionTime !== undefined) {
      writer.uint32(88).uint64(message.executionTime.toString());
    }
    if (message.failureReason !== undefined) {
      writer.uint32(98).string(message.failureReason);
    }
    if (message.peakMemoryUsage !== undefined) {
      writer.uint32(104).uint64(message.peakMemoryUsage.toString());
    }
    if (message.name !== "") {
      writer.uint32(114).string(message.name);
    }
    if (message.options !== "") {
      writer.uint32(122).string(message.options);
    }
    if (message.dataset !== undefined) {
      Dataset.encode(message.dataset, writer.uint32(130).fork()).join();
    }
    if (message.targetModel !== undefined) {
      Model.encode(message.targetModel, writer.uint32(138).fork()).join();
    }
    if (message.executionPeakMemory !== undefined) {
      Range.encode(message.executionPeakMemory, writer.uint32(146).fork()).join();
    }
    if (message.hasVizgraph !== undefined) {
      writer.uint32(152).bool(message.hasVizgraph);
    }
    if (message.lastUpdatedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdatedTime), writer.uint32(162).fork()).join();
    }
    if (message.adminUrl !== undefined) {
      writer.uint32(170).string(message.adminUrl);
    }
    if (message.includesCompile !== false) {
      writer.uint32(176).bool(message.includesCompile);
    }
    if (message.errorDetails !== undefined) {
      writer.uint32(186).string(message.errorDetails);
    }
    if (message.queuedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.queuedTime), writer.uint32(194).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ProfileJob {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProfileJob();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.profileJobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.userId = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.model = Model.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.jobState = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.device = Device.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tensorTypeList = NamedTensorTypeList.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.creationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.completionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.executionTime = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.failureReason = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.peakMemoryUsage = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.name = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.options = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.dataset = Dataset.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.targetModel = Model.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.executionPeakMemory = Range.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.hasVizgraph = reader.bool();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.lastUpdatedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.adminUrl = reader.string();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.includesCompile = reader.bool();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.errorDetails = reader.string();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.queuedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProfileJob {
    return {
      profileJobId: isSet(object.profileJobId) ? globalThis.String(object.profileJobId) : "",
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      userId: isSet(object.userId) ? Long.fromValue(object.userId) : Long.UZERO,
      model: isSet(object.model) ? Model.fromJSON(object.model) : undefined,
      jobState: isSet(object.jobState) ? jobStateFromJSON(object.jobState) : 0,
      device: isSet(object.device) ? Device.fromJSON(object.device) : undefined,
      tensorTypeList: isSet(object.tensorTypeList) ? NamedTensorTypeList.fromJSON(object.tensorTypeList) : undefined,
      creationTime: isSet(object.creationTime) ? fromJsonTimestamp(object.creationTime) : undefined,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      completionTime: isSet(object.completionTime) ? fromJsonTimestamp(object.completionTime) : undefined,
      executionTime: isSet(object.executionTime) ? Long.fromValue(object.executionTime) : undefined,
      failureReason: isSet(object.failureReason) ? globalThis.String(object.failureReason) : undefined,
      peakMemoryUsage: isSet(object.peakMemoryUsage) ? Long.fromValue(object.peakMemoryUsage) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      options: isSet(object.options) ? globalThis.String(object.options) : "",
      dataset: isSet(object.dataset) ? Dataset.fromJSON(object.dataset) : undefined,
      targetModel: isSet(object.targetModel) ? Model.fromJSON(object.targetModel) : undefined,
      executionPeakMemory: isSet(object.executionPeakMemory) ? Range.fromJSON(object.executionPeakMemory) : undefined,
      hasVizgraph: isSet(object.hasVizgraph) ? globalThis.Boolean(object.hasVizgraph) : undefined,
      lastUpdatedTime: isSet(object.lastUpdatedTime) ? fromJsonTimestamp(object.lastUpdatedTime) : undefined,
      adminUrl: isSet(object.adminUrl) ? globalThis.String(object.adminUrl) : undefined,
      includesCompile: isSet(object.includesCompile) ? globalThis.Boolean(object.includesCompile) : false,
      errorDetails: isSet(object.errorDetails) ? globalThis.String(object.errorDetails) : undefined,
      queuedTime: isSet(object.queuedTime) ? fromJsonTimestamp(object.queuedTime) : undefined,
    };
  },

  toJSON(message: ProfileJob): unknown {
    const obj: any = {};
    if (message.profileJobId !== "") {
      obj.profileJobId = message.profileJobId;
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (!message.userId.equals(Long.UZERO)) {
      obj.userId = (message.userId || Long.UZERO).toString();
    }
    if (message.model !== undefined) {
      obj.model = Model.toJSON(message.model);
    }
    if (message.jobState !== 0) {
      obj.jobState = jobStateToJSON(message.jobState);
    }
    if (message.device !== undefined) {
      obj.device = Device.toJSON(message.device);
    }
    if (message.tensorTypeList !== undefined) {
      obj.tensorTypeList = NamedTensorTypeList.toJSON(message.tensorTypeList);
    }
    if (message.creationTime !== undefined) {
      obj.creationTime = message.creationTime.toISOString();
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.completionTime !== undefined) {
      obj.completionTime = message.completionTime.toISOString();
    }
    if (message.executionTime !== undefined) {
      obj.executionTime = (message.executionTime || Long.UZERO).toString();
    }
    if (message.failureReason !== undefined) {
      obj.failureReason = message.failureReason;
    }
    if (message.peakMemoryUsage !== undefined) {
      obj.peakMemoryUsage = (message.peakMemoryUsage || Long.UZERO).toString();
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.options !== "") {
      obj.options = message.options;
    }
    if (message.dataset !== undefined) {
      obj.dataset = Dataset.toJSON(message.dataset);
    }
    if (message.targetModel !== undefined) {
      obj.targetModel = Model.toJSON(message.targetModel);
    }
    if (message.executionPeakMemory !== undefined) {
      obj.executionPeakMemory = Range.toJSON(message.executionPeakMemory);
    }
    if (message.hasVizgraph !== undefined) {
      obj.hasVizgraph = message.hasVizgraph;
    }
    if (message.lastUpdatedTime !== undefined) {
      obj.lastUpdatedTime = message.lastUpdatedTime.toISOString();
    }
    if (message.adminUrl !== undefined) {
      obj.adminUrl = message.adminUrl;
    }
    if (message.includesCompile !== false) {
      obj.includesCompile = message.includesCompile;
    }
    if (message.errorDetails !== undefined) {
      obj.errorDetails = message.errorDetails;
    }
    if (message.queuedTime !== undefined) {
      obj.queuedTime = message.queuedTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProfileJob>, I>>(base?: I): ProfileJob {
    return ProfileJob.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProfileJob>, I>>(object: I): ProfileJob {
    const message = createBaseProfileJob();
    message.profileJobId = object.profileJobId ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.userId = (object.userId !== undefined && object.userId !== null)
      ? Long.fromValue(object.userId)
      : Long.UZERO;
    message.model = (object.model !== undefined && object.model !== null) ? Model.fromPartial(object.model) : undefined;
    message.jobState = object.jobState ?? 0;
    message.device = (object.device !== undefined && object.device !== null)
      ? Device.fromPartial(object.device)
      : undefined;
    message.tensorTypeList = (object.tensorTypeList !== undefined && object.tensorTypeList !== null)
      ? NamedTensorTypeList.fromPartial(object.tensorTypeList)
      : undefined;
    message.creationTime = object.creationTime ?? undefined;
    message.startTime = object.startTime ?? undefined;
    message.completionTime = object.completionTime ?? undefined;
    message.executionTime = (object.executionTime !== undefined && object.executionTime !== null)
      ? Long.fromValue(object.executionTime)
      : undefined;
    message.failureReason = object.failureReason ?? undefined;
    message.peakMemoryUsage = (object.peakMemoryUsage !== undefined && object.peakMemoryUsage !== null)
      ? Long.fromValue(object.peakMemoryUsage)
      : undefined;
    message.name = object.name ?? "";
    message.options = object.options ?? "";
    message.dataset = (object.dataset !== undefined && object.dataset !== null)
      ? Dataset.fromPartial(object.dataset)
      : undefined;
    message.targetModel = (object.targetModel !== undefined && object.targetModel !== null)
      ? Model.fromPartial(object.targetModel)
      : undefined;
    message.executionPeakMemory = (object.executionPeakMemory !== undefined && object.executionPeakMemory !== null)
      ? Range.fromPartial(object.executionPeakMemory)
      : undefined;
    message.hasVizgraph = object.hasVizgraph ?? undefined;
    message.lastUpdatedTime = object.lastUpdatedTime ?? undefined;
    message.adminUrl = object.adminUrl ?? undefined;
    message.includesCompile = object.includesCompile ?? false;
    message.errorDetails = object.errorDetails ?? undefined;
    message.queuedTime = object.queuedTime ?? undefined;
    return message;
  },
};

function createBaseInferenceJob(): InferenceJob {
  return {
    inferenceJobId: "",
    user: undefined,
    model: undefined,
    device: undefined,
    jobState: 0,
    name: "",
    dataset: undefined,
    creationTime: undefined,
    startTime: undefined,
    completionTime: undefined,
    failureReason: undefined,
    options: "",
    lastUpdatedTime: undefined,
    adminUrl: undefined,
    errorDetails: undefined,
    queuedTime: undefined,
  };
}

export const InferenceJob: MessageFns<InferenceJob> = {
  encode(message: InferenceJob, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.inferenceJobId !== "") {
      writer.uint32(10).string(message.inferenceJobId);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).join();
    }
    if (message.model !== undefined) {
      Model.encode(message.model, writer.uint32(26).fork()).join();
    }
    if (message.device !== undefined) {
      Device.encode(message.device, writer.uint32(34).fork()).join();
    }
    if (message.jobState !== 0) {
      writer.uint32(40).int32(message.jobState);
    }
    if (message.name !== "") {
      writer.uint32(50).string(message.name);
    }
    if (message.dataset !== undefined) {
      Dataset.encode(message.dataset, writer.uint32(58).fork()).join();
    }
    if (message.creationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.creationTime), writer.uint32(66).fork()).join();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(74).fork()).join();
    }
    if (message.completionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.completionTime), writer.uint32(82).fork()).join();
    }
    if (message.failureReason !== undefined) {
      writer.uint32(90).string(message.failureReason);
    }
    if (message.options !== "") {
      writer.uint32(98).string(message.options);
    }
    if (message.lastUpdatedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdatedTime), writer.uint32(106).fork()).join();
    }
    if (message.adminUrl !== undefined) {
      writer.uint32(114).string(message.adminUrl);
    }
    if (message.errorDetails !== undefined) {
      writer.uint32(122).string(message.errorDetails);
    }
    if (message.queuedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.queuedTime), writer.uint32(130).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): InferenceJob {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInferenceJob();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.inferenceJobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.model = Model.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.device = Device.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.jobState = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.name = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.dataset = Dataset.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.creationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.completionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.failureReason = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.options = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.lastUpdatedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.adminUrl = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.errorDetails = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.queuedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InferenceJob {
    return {
      inferenceJobId: isSet(object.inferenceJobId) ? globalThis.String(object.inferenceJobId) : "",
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      model: isSet(object.model) ? Model.fromJSON(object.model) : undefined,
      device: isSet(object.device) ? Device.fromJSON(object.device) : undefined,
      jobState: isSet(object.jobState) ? jobStateFromJSON(object.jobState) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      dataset: isSet(object.dataset) ? Dataset.fromJSON(object.dataset) : undefined,
      creationTime: isSet(object.creationTime) ? fromJsonTimestamp(object.creationTime) : undefined,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      completionTime: isSet(object.completionTime) ? fromJsonTimestamp(object.completionTime) : undefined,
      failureReason: isSet(object.failureReason) ? globalThis.String(object.failureReason) : undefined,
      options: isSet(object.options) ? globalThis.String(object.options) : "",
      lastUpdatedTime: isSet(object.lastUpdatedTime) ? fromJsonTimestamp(object.lastUpdatedTime) : undefined,
      adminUrl: isSet(object.adminUrl) ? globalThis.String(object.adminUrl) : undefined,
      errorDetails: isSet(object.errorDetails) ? globalThis.String(object.errorDetails) : undefined,
      queuedTime: isSet(object.queuedTime) ? fromJsonTimestamp(object.queuedTime) : undefined,
    };
  },

  toJSON(message: InferenceJob): unknown {
    const obj: any = {};
    if (message.inferenceJobId !== "") {
      obj.inferenceJobId = message.inferenceJobId;
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.model !== undefined) {
      obj.model = Model.toJSON(message.model);
    }
    if (message.device !== undefined) {
      obj.device = Device.toJSON(message.device);
    }
    if (message.jobState !== 0) {
      obj.jobState = jobStateToJSON(message.jobState);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.dataset !== undefined) {
      obj.dataset = Dataset.toJSON(message.dataset);
    }
    if (message.creationTime !== undefined) {
      obj.creationTime = message.creationTime.toISOString();
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.completionTime !== undefined) {
      obj.completionTime = message.completionTime.toISOString();
    }
    if (message.failureReason !== undefined) {
      obj.failureReason = message.failureReason;
    }
    if (message.options !== "") {
      obj.options = message.options;
    }
    if (message.lastUpdatedTime !== undefined) {
      obj.lastUpdatedTime = message.lastUpdatedTime.toISOString();
    }
    if (message.adminUrl !== undefined) {
      obj.adminUrl = message.adminUrl;
    }
    if (message.errorDetails !== undefined) {
      obj.errorDetails = message.errorDetails;
    }
    if (message.queuedTime !== undefined) {
      obj.queuedTime = message.queuedTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InferenceJob>, I>>(base?: I): InferenceJob {
    return InferenceJob.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InferenceJob>, I>>(object: I): InferenceJob {
    const message = createBaseInferenceJob();
    message.inferenceJobId = object.inferenceJobId ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.model = (object.model !== undefined && object.model !== null) ? Model.fromPartial(object.model) : undefined;
    message.device = (object.device !== undefined && object.device !== null)
      ? Device.fromPartial(object.device)
      : undefined;
    message.jobState = object.jobState ?? 0;
    message.name = object.name ?? "";
    message.dataset = (object.dataset !== undefined && object.dataset !== null)
      ? Dataset.fromPartial(object.dataset)
      : undefined;
    message.creationTime = object.creationTime ?? undefined;
    message.startTime = object.startTime ?? undefined;
    message.completionTime = object.completionTime ?? undefined;
    message.failureReason = object.failureReason ?? undefined;
    message.options = object.options ?? "";
    message.lastUpdatedTime = object.lastUpdatedTime ?? undefined;
    message.adminUrl = object.adminUrl ?? undefined;
    message.errorDetails = object.errorDetails ?? undefined;
    message.queuedTime = object.queuedTime ?? undefined;
    return message;
  },
};

function createBaseJob(): Job {
  return {
    profileJob: undefined,
    inferenceJob: undefined,
    compileJob: undefined,
    quantizeJob: undefined,
    linkJob: undefined,
    hubVersion: undefined,
    readOnly: undefined,
    notices: [],
    failureType: 0,
  };
}

export const Job: MessageFns<Job> = {
  encode(message: Job, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.profileJob !== undefined) {
      ProfileJob.encode(message.profileJob, writer.uint32(10).fork()).join();
    }
    if (message.inferenceJob !== undefined) {
      InferenceJob.encode(message.inferenceJob, writer.uint32(18).fork()).join();
    }
    if (message.compileJob !== undefined) {
      CompileJob.encode(message.compileJob, writer.uint32(34).fork()).join();
    }
    if (message.quantizeJob !== undefined) {
      QuantizeJob.encode(message.quantizeJob, writer.uint32(50).fork()).join();
    }
    if (message.linkJob !== undefined) {
      LinkJob.encode(message.linkJob, writer.uint32(58).fork()).join();
    }
    if (message.hubVersion !== undefined) {
      writer.uint32(26).string(message.hubVersion);
    }
    if (message.readOnly !== undefined) {
      writer.uint32(40).bool(message.readOnly);
    }
    for (const v of message.notices) {
      Notice.encode(v!, writer.uint32(66).fork()).join();
    }
    if (message.failureType !== 0) {
      writer.uint32(72).int32(message.failureType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Job {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJob();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.profileJob = ProfileJob.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.inferenceJob = InferenceJob.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.compileJob = CompileJob.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.quantizeJob = QuantizeJob.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.linkJob = LinkJob.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.hubVersion = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.readOnly = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.notices.push(Notice.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.failureType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Job {
    return {
      profileJob: isSet(object.profileJob) ? ProfileJob.fromJSON(object.profileJob) : undefined,
      inferenceJob: isSet(object.inferenceJob) ? InferenceJob.fromJSON(object.inferenceJob) : undefined,
      compileJob: isSet(object.compileJob) ? CompileJob.fromJSON(object.compileJob) : undefined,
      quantizeJob: isSet(object.quantizeJob) ? QuantizeJob.fromJSON(object.quantizeJob) : undefined,
      linkJob: isSet(object.linkJob) ? LinkJob.fromJSON(object.linkJob) : undefined,
      hubVersion: isSet(object.hubVersion) ? globalThis.String(object.hubVersion) : undefined,
      readOnly: isSet(object.readOnly) ? globalThis.Boolean(object.readOnly) : undefined,
      notices: globalThis.Array.isArray(object?.notices) ? object.notices.map((e: any) => Notice.fromJSON(e)) : [],
      failureType: isSet(object.failureType) ? jobFailureTypeFromJSON(object.failureType) : 0,
    };
  },

  toJSON(message: Job): unknown {
    const obj: any = {};
    if (message.profileJob !== undefined) {
      obj.profileJob = ProfileJob.toJSON(message.profileJob);
    }
    if (message.inferenceJob !== undefined) {
      obj.inferenceJob = InferenceJob.toJSON(message.inferenceJob);
    }
    if (message.compileJob !== undefined) {
      obj.compileJob = CompileJob.toJSON(message.compileJob);
    }
    if (message.quantizeJob !== undefined) {
      obj.quantizeJob = QuantizeJob.toJSON(message.quantizeJob);
    }
    if (message.linkJob !== undefined) {
      obj.linkJob = LinkJob.toJSON(message.linkJob);
    }
    if (message.hubVersion !== undefined) {
      obj.hubVersion = message.hubVersion;
    }
    if (message.readOnly !== undefined) {
      obj.readOnly = message.readOnly;
    }
    if (message.notices?.length) {
      obj.notices = message.notices.map((e) => Notice.toJSON(e));
    }
    if (message.failureType !== 0) {
      obj.failureType = jobFailureTypeToJSON(message.failureType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Job>, I>>(base?: I): Job {
    return Job.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Job>, I>>(object: I): Job {
    const message = createBaseJob();
    message.profileJob = (object.profileJob !== undefined && object.profileJob !== null)
      ? ProfileJob.fromPartial(object.profileJob)
      : undefined;
    message.inferenceJob = (object.inferenceJob !== undefined && object.inferenceJob !== null)
      ? InferenceJob.fromPartial(object.inferenceJob)
      : undefined;
    message.compileJob = (object.compileJob !== undefined && object.compileJob !== null)
      ? CompileJob.fromPartial(object.compileJob)
      : undefined;
    message.quantizeJob = (object.quantizeJob !== undefined && object.quantizeJob !== null)
      ? QuantizeJob.fromPartial(object.quantizeJob)
      : undefined;
    message.linkJob = (object.linkJob !== undefined && object.linkJob !== null)
      ? LinkJob.fromPartial(object.linkJob)
      : undefined;
    message.hubVersion = object.hubVersion ?? undefined;
    message.readOnly = object.readOnly ?? undefined;
    message.notices = object.notices?.map((e) => Notice.fromPartial(e)) || [];
    message.failureType = object.failureType ?? 0;
    return message;
  },
};

function createBaseJobList(): JobList {
  return { jobs: [], totalQueryCount: Long.UZERO };
}

export const JobList: MessageFns<JobList> = {
  encode(message: JobList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.jobs) {
      Job.encode(v!, writer.uint32(10).fork()).join();
    }
    if (!message.totalQueryCount.equals(Long.UZERO)) {
      writer.uint32(16).uint64(message.totalQueryCount.toString());
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): JobList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobs.push(Job.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalQueryCount = Long.fromString(reader.uint64().toString(), true);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobList {
    return {
      jobs: globalThis.Array.isArray(object?.jobs) ? object.jobs.map((e: any) => Job.fromJSON(e)) : [],
      totalQueryCount: isSet(object.totalQueryCount) ? Long.fromValue(object.totalQueryCount) : Long.UZERO,
    };
  },

  toJSON(message: JobList): unknown {
    const obj: any = {};
    if (message.jobs?.length) {
      obj.jobs = message.jobs.map((e) => Job.toJSON(e));
    }
    if (!message.totalQueryCount.equals(Long.UZERO)) {
      obj.totalQueryCount = (message.totalQueryCount || Long.UZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobList>, I>>(base?: I): JobList {
    return JobList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JobList>, I>>(object: I): JobList {
    const message = createBaseJobList();
    message.jobs = object.jobs?.map((e) => Job.fromPartial(e)) || [];
    message.totalQueryCount = (object.totalQueryCount !== undefined && object.totalQueryCount !== null)
      ? Long.fromValue(object.totalQueryCount)
      : Long.UZERO;
    return message;
  },
};

function createBaseJobSummary(): JobSummary {
  return {
    profileJobSummary: undefined,
    inferenceJobSummary: undefined,
    compileJobSummary: undefined,
    quantizeJobSummary: undefined,
    linkJobSummary: undefined,
  };
}

export const JobSummary: MessageFns<JobSummary> = {
  encode(message: JobSummary, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.profileJobSummary !== undefined) {
      ProfileJobSummary.encode(message.profileJobSummary, writer.uint32(10).fork()).join();
    }
    if (message.inferenceJobSummary !== undefined) {
      InferenceJobSummary.encode(message.inferenceJobSummary, writer.uint32(18).fork()).join();
    }
    if (message.compileJobSummary !== undefined) {
      CompileJobSummary.encode(message.compileJobSummary, writer.uint32(34).fork()).join();
    }
    if (message.quantizeJobSummary !== undefined) {
      QuantizeJobSummary.encode(message.quantizeJobSummary, writer.uint32(42).fork()).join();
    }
    if (message.linkJobSummary !== undefined) {
      LinkJobSummary.encode(message.linkJobSummary, writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): JobSummary {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.profileJobSummary = ProfileJobSummary.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.inferenceJobSummary = InferenceJobSummary.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.compileJobSummary = CompileJobSummary.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.quantizeJobSummary = QuantizeJobSummary.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.linkJobSummary = LinkJobSummary.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobSummary {
    return {
      profileJobSummary: isSet(object.profileJobSummary)
        ? ProfileJobSummary.fromJSON(object.profileJobSummary)
        : undefined,
      inferenceJobSummary: isSet(object.inferenceJobSummary)
        ? InferenceJobSummary.fromJSON(object.inferenceJobSummary)
        : undefined,
      compileJobSummary: isSet(object.compileJobSummary)
        ? CompileJobSummary.fromJSON(object.compileJobSummary)
        : undefined,
      quantizeJobSummary: isSet(object.quantizeJobSummary)
        ? QuantizeJobSummary.fromJSON(object.quantizeJobSummary)
        : undefined,
      linkJobSummary: isSet(object.linkJobSummary) ? LinkJobSummary.fromJSON(object.linkJobSummary) : undefined,
    };
  },

  toJSON(message: JobSummary): unknown {
    const obj: any = {};
    if (message.profileJobSummary !== undefined) {
      obj.profileJobSummary = ProfileJobSummary.toJSON(message.profileJobSummary);
    }
    if (message.inferenceJobSummary !== undefined) {
      obj.inferenceJobSummary = InferenceJobSummary.toJSON(message.inferenceJobSummary);
    }
    if (message.compileJobSummary !== undefined) {
      obj.compileJobSummary = CompileJobSummary.toJSON(message.compileJobSummary);
    }
    if (message.quantizeJobSummary !== undefined) {
      obj.quantizeJobSummary = QuantizeJobSummary.toJSON(message.quantizeJobSummary);
    }
    if (message.linkJobSummary !== undefined) {
      obj.linkJobSummary = LinkJobSummary.toJSON(message.linkJobSummary);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobSummary>, I>>(base?: I): JobSummary {
    return JobSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JobSummary>, I>>(object: I): JobSummary {
    const message = createBaseJobSummary();
    message.profileJobSummary = (object.profileJobSummary !== undefined && object.profileJobSummary !== null)
      ? ProfileJobSummary.fromPartial(object.profileJobSummary)
      : undefined;
    message.inferenceJobSummary = (object.inferenceJobSummary !== undefined && object.inferenceJobSummary !== null)
      ? InferenceJobSummary.fromPartial(object.inferenceJobSummary)
      : undefined;
    message.compileJobSummary = (object.compileJobSummary !== undefined && object.compileJobSummary !== null)
      ? CompileJobSummary.fromPartial(object.compileJobSummary)
      : undefined;
    message.quantizeJobSummary = (object.quantizeJobSummary !== undefined && object.quantizeJobSummary !== null)
      ? QuantizeJobSummary.fromPartial(object.quantizeJobSummary)
      : undefined;
    message.linkJobSummary = (object.linkJobSummary !== undefined && object.linkJobSummary !== null)
      ? LinkJobSummary.fromPartial(object.linkJobSummary)
      : undefined;
    return message;
  },
};

function createBaseCompileJobSummary(): CompileJobSummary {
  return {
    jobId: "",
    user: undefined,
    name: "",
    jobState: 0,
    deviceName: "",
    deviceOsVersion: "",
    creationTime: undefined,
    startTime: undefined,
    completionTime: undefined,
    lastUpdatedTime: undefined,
    queuedTime: undefined,
  };
}

export const CompileJobSummary: MessageFns<CompileJobSummary> = {
  encode(message: CompileJobSummary, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.jobId !== "") {
      writer.uint32(10).string(message.jobId);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).join();
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.jobState !== 0) {
      writer.uint32(32).int32(message.jobState);
    }
    if (message.deviceName !== "") {
      writer.uint32(42).string(message.deviceName);
    }
    if (message.deviceOsVersion !== "") {
      writer.uint32(50).string(message.deviceOsVersion);
    }
    if (message.creationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.creationTime), writer.uint32(58).fork()).join();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(66).fork()).join();
    }
    if (message.completionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.completionTime), writer.uint32(74).fork()).join();
    }
    if (message.lastUpdatedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdatedTime), writer.uint32(82).fork()).join();
    }
    if (message.queuedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.queuedTime), writer.uint32(98).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CompileJobSummary {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompileJobSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.jobState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deviceName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.deviceOsVersion = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.creationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.completionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.lastUpdatedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.queuedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompileJobSummary {
    return {
      jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "",
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      jobState: isSet(object.jobState) ? jobStateFromJSON(object.jobState) : 0,
      deviceName: isSet(object.deviceName) ? globalThis.String(object.deviceName) : "",
      deviceOsVersion: isSet(object.deviceOsVersion) ? globalThis.String(object.deviceOsVersion) : "",
      creationTime: isSet(object.creationTime) ? fromJsonTimestamp(object.creationTime) : undefined,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      completionTime: isSet(object.completionTime) ? fromJsonTimestamp(object.completionTime) : undefined,
      lastUpdatedTime: isSet(object.lastUpdatedTime) ? fromJsonTimestamp(object.lastUpdatedTime) : undefined,
      queuedTime: isSet(object.queuedTime) ? fromJsonTimestamp(object.queuedTime) : undefined,
    };
  },

  toJSON(message: CompileJobSummary): unknown {
    const obj: any = {};
    if (message.jobId !== "") {
      obj.jobId = message.jobId;
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.jobState !== 0) {
      obj.jobState = jobStateToJSON(message.jobState);
    }
    if (message.deviceName !== "") {
      obj.deviceName = message.deviceName;
    }
    if (message.deviceOsVersion !== "") {
      obj.deviceOsVersion = message.deviceOsVersion;
    }
    if (message.creationTime !== undefined) {
      obj.creationTime = message.creationTime.toISOString();
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.completionTime !== undefined) {
      obj.completionTime = message.completionTime.toISOString();
    }
    if (message.lastUpdatedTime !== undefined) {
      obj.lastUpdatedTime = message.lastUpdatedTime.toISOString();
    }
    if (message.queuedTime !== undefined) {
      obj.queuedTime = message.queuedTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompileJobSummary>, I>>(base?: I): CompileJobSummary {
    return CompileJobSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompileJobSummary>, I>>(object: I): CompileJobSummary {
    const message = createBaseCompileJobSummary();
    message.jobId = object.jobId ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.name = object.name ?? "";
    message.jobState = object.jobState ?? 0;
    message.deviceName = object.deviceName ?? "";
    message.deviceOsVersion = object.deviceOsVersion ?? "";
    message.creationTime = object.creationTime ?? undefined;
    message.startTime = object.startTime ?? undefined;
    message.completionTime = object.completionTime ?? undefined;
    message.lastUpdatedTime = object.lastUpdatedTime ?? undefined;
    message.queuedTime = object.queuedTime ?? undefined;
    return message;
  },
};

function createBaseLinkJobSummary(): LinkJobSummary {
  return {
    jobId: "",
    user: undefined,
    name: "",
    jobState: 0,
    creationTime: undefined,
    startTime: undefined,
    completionTime: undefined,
    lastUpdatedTime: undefined,
    queuedTime: undefined,
  };
}

export const LinkJobSummary: MessageFns<LinkJobSummary> = {
  encode(message: LinkJobSummary, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.jobId !== "") {
      writer.uint32(10).string(message.jobId);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).join();
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.jobState !== 0) {
      writer.uint32(32).int32(message.jobState);
    }
    if (message.creationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.creationTime), writer.uint32(42).fork()).join();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(50).fork()).join();
    }
    if (message.completionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.completionTime), writer.uint32(58).fork()).join();
    }
    if (message.lastUpdatedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdatedTime), writer.uint32(66).fork()).join();
    }
    if (message.queuedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.queuedTime), writer.uint32(82).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LinkJobSummary {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLinkJobSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.jobState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.creationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.completionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.lastUpdatedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.queuedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LinkJobSummary {
    return {
      jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "",
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      jobState: isSet(object.jobState) ? jobStateFromJSON(object.jobState) : 0,
      creationTime: isSet(object.creationTime) ? fromJsonTimestamp(object.creationTime) : undefined,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      completionTime: isSet(object.completionTime) ? fromJsonTimestamp(object.completionTime) : undefined,
      lastUpdatedTime: isSet(object.lastUpdatedTime) ? fromJsonTimestamp(object.lastUpdatedTime) : undefined,
      queuedTime: isSet(object.queuedTime) ? fromJsonTimestamp(object.queuedTime) : undefined,
    };
  },

  toJSON(message: LinkJobSummary): unknown {
    const obj: any = {};
    if (message.jobId !== "") {
      obj.jobId = message.jobId;
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.jobState !== 0) {
      obj.jobState = jobStateToJSON(message.jobState);
    }
    if (message.creationTime !== undefined) {
      obj.creationTime = message.creationTime.toISOString();
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.completionTime !== undefined) {
      obj.completionTime = message.completionTime.toISOString();
    }
    if (message.lastUpdatedTime !== undefined) {
      obj.lastUpdatedTime = message.lastUpdatedTime.toISOString();
    }
    if (message.queuedTime !== undefined) {
      obj.queuedTime = message.queuedTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LinkJobSummary>, I>>(base?: I): LinkJobSummary {
    return LinkJobSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LinkJobSummary>, I>>(object: I): LinkJobSummary {
    const message = createBaseLinkJobSummary();
    message.jobId = object.jobId ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.name = object.name ?? "";
    message.jobState = object.jobState ?? 0;
    message.creationTime = object.creationTime ?? undefined;
    message.startTime = object.startTime ?? undefined;
    message.completionTime = object.completionTime ?? undefined;
    message.lastUpdatedTime = object.lastUpdatedTime ?? undefined;
    message.queuedTime = object.queuedTime ?? undefined;
    return message;
  },
};

function createBaseQuantizeJobSummary(): QuantizeJobSummary {
  return {
    jobId: "",
    user: undefined,
    name: "",
    jobState: 0,
    creationTime: undefined,
    startTime: undefined,
    completionTime: undefined,
    lastUpdatedTime: undefined,
    queuedTime: undefined,
  };
}

export const QuantizeJobSummary: MessageFns<QuantizeJobSummary> = {
  encode(message: QuantizeJobSummary, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.jobId !== "") {
      writer.uint32(10).string(message.jobId);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).join();
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.jobState !== 0) {
      writer.uint32(32).int32(message.jobState);
    }
    if (message.creationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.creationTime), writer.uint32(42).fork()).join();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(50).fork()).join();
    }
    if (message.completionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.completionTime), writer.uint32(58).fork()).join();
    }
    if (message.lastUpdatedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdatedTime), writer.uint32(66).fork()).join();
    }
    if (message.queuedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.queuedTime), writer.uint32(82).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QuantizeJobSummary {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantizeJobSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.jobState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.creationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.completionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.lastUpdatedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.queuedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantizeJobSummary {
    return {
      jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "",
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      jobState: isSet(object.jobState) ? jobStateFromJSON(object.jobState) : 0,
      creationTime: isSet(object.creationTime) ? fromJsonTimestamp(object.creationTime) : undefined,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      completionTime: isSet(object.completionTime) ? fromJsonTimestamp(object.completionTime) : undefined,
      lastUpdatedTime: isSet(object.lastUpdatedTime) ? fromJsonTimestamp(object.lastUpdatedTime) : undefined,
      queuedTime: isSet(object.queuedTime) ? fromJsonTimestamp(object.queuedTime) : undefined,
    };
  },

  toJSON(message: QuantizeJobSummary): unknown {
    const obj: any = {};
    if (message.jobId !== "") {
      obj.jobId = message.jobId;
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.jobState !== 0) {
      obj.jobState = jobStateToJSON(message.jobState);
    }
    if (message.creationTime !== undefined) {
      obj.creationTime = message.creationTime.toISOString();
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.completionTime !== undefined) {
      obj.completionTime = message.completionTime.toISOString();
    }
    if (message.lastUpdatedTime !== undefined) {
      obj.lastUpdatedTime = message.lastUpdatedTime.toISOString();
    }
    if (message.queuedTime !== undefined) {
      obj.queuedTime = message.queuedTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantizeJobSummary>, I>>(base?: I): QuantizeJobSummary {
    return QuantizeJobSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QuantizeJobSummary>, I>>(object: I): QuantizeJobSummary {
    const message = createBaseQuantizeJobSummary();
    message.jobId = object.jobId ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.name = object.name ?? "";
    message.jobState = object.jobState ?? 0;
    message.creationTime = object.creationTime ?? undefined;
    message.startTime = object.startTime ?? undefined;
    message.completionTime = object.completionTime ?? undefined;
    message.lastUpdatedTime = object.lastUpdatedTime ?? undefined;
    message.queuedTime = object.queuedTime ?? undefined;
    return message;
  },
};

function createBaseProfileJobSummary(): ProfileJobSummary {
  return {
    jobId: "",
    user: undefined,
    name: "",
    jobState: 0,
    deviceName: "",
    deviceOsVersion: "",
    creationTime: undefined,
    startTime: undefined,
    completionTime: undefined,
    lastUpdatedTime: undefined,
    executionTime: undefined,
    executionPeakMemory: undefined,
    queuedTime: undefined,
  };
}

export const ProfileJobSummary: MessageFns<ProfileJobSummary> = {
  encode(message: ProfileJobSummary, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.jobId !== "") {
      writer.uint32(10).string(message.jobId);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).join();
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.jobState !== 0) {
      writer.uint32(32).int32(message.jobState);
    }
    if (message.deviceName !== "") {
      writer.uint32(42).string(message.deviceName);
    }
    if (message.deviceOsVersion !== "") {
      writer.uint32(50).string(message.deviceOsVersion);
    }
    if (message.creationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.creationTime), writer.uint32(58).fork()).join();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(66).fork()).join();
    }
    if (message.completionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.completionTime), writer.uint32(74).fork()).join();
    }
    if (message.lastUpdatedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdatedTime), writer.uint32(82).fork()).join();
    }
    if (message.executionTime !== undefined) {
      writer.uint32(96).uint64(message.executionTime.toString());
    }
    if (message.executionPeakMemory !== undefined) {
      Range.encode(message.executionPeakMemory, writer.uint32(106).fork()).join();
    }
    if (message.queuedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.queuedTime), writer.uint32(114).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ProfileJobSummary {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProfileJobSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.jobState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deviceName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.deviceOsVersion = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.creationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.completionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.lastUpdatedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.executionTime = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.executionPeakMemory = Range.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.queuedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProfileJobSummary {
    return {
      jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "",
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      jobState: isSet(object.jobState) ? jobStateFromJSON(object.jobState) : 0,
      deviceName: isSet(object.deviceName) ? globalThis.String(object.deviceName) : "",
      deviceOsVersion: isSet(object.deviceOsVersion) ? globalThis.String(object.deviceOsVersion) : "",
      creationTime: isSet(object.creationTime) ? fromJsonTimestamp(object.creationTime) : undefined,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      completionTime: isSet(object.completionTime) ? fromJsonTimestamp(object.completionTime) : undefined,
      lastUpdatedTime: isSet(object.lastUpdatedTime) ? fromJsonTimestamp(object.lastUpdatedTime) : undefined,
      executionTime: isSet(object.executionTime) ? Long.fromValue(object.executionTime) : undefined,
      executionPeakMemory: isSet(object.executionPeakMemory) ? Range.fromJSON(object.executionPeakMemory) : undefined,
      queuedTime: isSet(object.queuedTime) ? fromJsonTimestamp(object.queuedTime) : undefined,
    };
  },

  toJSON(message: ProfileJobSummary): unknown {
    const obj: any = {};
    if (message.jobId !== "") {
      obj.jobId = message.jobId;
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.jobState !== 0) {
      obj.jobState = jobStateToJSON(message.jobState);
    }
    if (message.deviceName !== "") {
      obj.deviceName = message.deviceName;
    }
    if (message.deviceOsVersion !== "") {
      obj.deviceOsVersion = message.deviceOsVersion;
    }
    if (message.creationTime !== undefined) {
      obj.creationTime = message.creationTime.toISOString();
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.completionTime !== undefined) {
      obj.completionTime = message.completionTime.toISOString();
    }
    if (message.lastUpdatedTime !== undefined) {
      obj.lastUpdatedTime = message.lastUpdatedTime.toISOString();
    }
    if (message.executionTime !== undefined) {
      obj.executionTime = (message.executionTime || Long.UZERO).toString();
    }
    if (message.executionPeakMemory !== undefined) {
      obj.executionPeakMemory = Range.toJSON(message.executionPeakMemory);
    }
    if (message.queuedTime !== undefined) {
      obj.queuedTime = message.queuedTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProfileJobSummary>, I>>(base?: I): ProfileJobSummary {
    return ProfileJobSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProfileJobSummary>, I>>(object: I): ProfileJobSummary {
    const message = createBaseProfileJobSummary();
    message.jobId = object.jobId ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.name = object.name ?? "";
    message.jobState = object.jobState ?? 0;
    message.deviceName = object.deviceName ?? "";
    message.deviceOsVersion = object.deviceOsVersion ?? "";
    message.creationTime = object.creationTime ?? undefined;
    message.startTime = object.startTime ?? undefined;
    message.completionTime = object.completionTime ?? undefined;
    message.lastUpdatedTime = object.lastUpdatedTime ?? undefined;
    message.executionTime = (object.executionTime !== undefined && object.executionTime !== null)
      ? Long.fromValue(object.executionTime)
      : undefined;
    message.executionPeakMemory = (object.executionPeakMemory !== undefined && object.executionPeakMemory !== null)
      ? Range.fromPartial(object.executionPeakMemory)
      : undefined;
    message.queuedTime = object.queuedTime ?? undefined;
    return message;
  },
};

function createBaseInferenceJobSummary(): InferenceJobSummary {
  return {
    jobId: "",
    user: undefined,
    name: "",
    jobState: 0,
    deviceName: "",
    deviceOsVersion: "",
    creationTime: undefined,
    startTime: undefined,
    completionTime: undefined,
    lastUpdatedTime: undefined,
    queuedTime: undefined,
  };
}

export const InferenceJobSummary: MessageFns<InferenceJobSummary> = {
  encode(message: InferenceJobSummary, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.jobId !== "") {
      writer.uint32(10).string(message.jobId);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(18).fork()).join();
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.jobState !== 0) {
      writer.uint32(32).int32(message.jobState);
    }
    if (message.deviceName !== "") {
      writer.uint32(42).string(message.deviceName);
    }
    if (message.deviceOsVersion !== "") {
      writer.uint32(50).string(message.deviceOsVersion);
    }
    if (message.creationTime !== undefined) {
      Timestamp.encode(toTimestamp(message.creationTime), writer.uint32(58).fork()).join();
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(66).fork()).join();
    }
    if (message.completionTime !== undefined) {
      Timestamp.encode(toTimestamp(message.completionTime), writer.uint32(74).fork()).join();
    }
    if (message.lastUpdatedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastUpdatedTime), writer.uint32(82).fork()).join();
    }
    if (message.queuedTime !== undefined) {
      Timestamp.encode(toTimestamp(message.queuedTime), writer.uint32(98).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): InferenceJobSummary {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInferenceJobSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.user = User.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.jobState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deviceName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.deviceOsVersion = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.creationTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.completionTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.lastUpdatedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.queuedTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InferenceJobSummary {
    return {
      jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "",
      user: isSet(object.user) ? User.fromJSON(object.user) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      jobState: isSet(object.jobState) ? jobStateFromJSON(object.jobState) : 0,
      deviceName: isSet(object.deviceName) ? globalThis.String(object.deviceName) : "",
      deviceOsVersion: isSet(object.deviceOsVersion) ? globalThis.String(object.deviceOsVersion) : "",
      creationTime: isSet(object.creationTime) ? fromJsonTimestamp(object.creationTime) : undefined,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      completionTime: isSet(object.completionTime) ? fromJsonTimestamp(object.completionTime) : undefined,
      lastUpdatedTime: isSet(object.lastUpdatedTime) ? fromJsonTimestamp(object.lastUpdatedTime) : undefined,
      queuedTime: isSet(object.queuedTime) ? fromJsonTimestamp(object.queuedTime) : undefined,
    };
  },

  toJSON(message: InferenceJobSummary): unknown {
    const obj: any = {};
    if (message.jobId !== "") {
      obj.jobId = message.jobId;
    }
    if (message.user !== undefined) {
      obj.user = User.toJSON(message.user);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.jobState !== 0) {
      obj.jobState = jobStateToJSON(message.jobState);
    }
    if (message.deviceName !== "") {
      obj.deviceName = message.deviceName;
    }
    if (message.deviceOsVersion !== "") {
      obj.deviceOsVersion = message.deviceOsVersion;
    }
    if (message.creationTime !== undefined) {
      obj.creationTime = message.creationTime.toISOString();
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.completionTime !== undefined) {
      obj.completionTime = message.completionTime.toISOString();
    }
    if (message.lastUpdatedTime !== undefined) {
      obj.lastUpdatedTime = message.lastUpdatedTime.toISOString();
    }
    if (message.queuedTime !== undefined) {
      obj.queuedTime = message.queuedTime.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InferenceJobSummary>, I>>(base?: I): InferenceJobSummary {
    return InferenceJobSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InferenceJobSummary>, I>>(object: I): InferenceJobSummary {
    const message = createBaseInferenceJobSummary();
    message.jobId = object.jobId ?? "";
    message.user = (object.user !== undefined && object.user !== null) ? User.fromPartial(object.user) : undefined;
    message.name = object.name ?? "";
    message.jobState = object.jobState ?? 0;
    message.deviceName = object.deviceName ?? "";
    message.deviceOsVersion = object.deviceOsVersion ?? "";
    message.creationTime = object.creationTime ?? undefined;
    message.startTime = object.startTime ?? undefined;
    message.completionTime = object.completionTime ?? undefined;
    message.lastUpdatedTime = object.lastUpdatedTime ?? undefined;
    message.queuedTime = object.queuedTime ?? undefined;
    return message;
  },
};

function createBaseJobSummaryList(): JobSummaryList {
  return { jobSummaries: [], totalQueryCount: Long.UZERO };
}

export const JobSummaryList: MessageFns<JobSummaryList> = {
  encode(message: JobSummaryList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.jobSummaries) {
      JobSummary.encode(v!, writer.uint32(10).fork()).join();
    }
    if (!message.totalQueryCount.equals(Long.UZERO)) {
      writer.uint32(16).uint64(message.totalQueryCount.toString());
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): JobSummaryList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobSummaryList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jobSummaries.push(JobSummary.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalQueryCount = Long.fromString(reader.uint64().toString(), true);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobSummaryList {
    return {
      jobSummaries: globalThis.Array.isArray(object?.jobSummaries)
        ? object.jobSummaries.map((e: any) => JobSummary.fromJSON(e))
        : [],
      totalQueryCount: isSet(object.totalQueryCount) ? Long.fromValue(object.totalQueryCount) : Long.UZERO,
    };
  },

  toJSON(message: JobSummaryList): unknown {
    const obj: any = {};
    if (message.jobSummaries?.length) {
      obj.jobSummaries = message.jobSummaries.map((e) => JobSummary.toJSON(e));
    }
    if (!message.totalQueryCount.equals(Long.UZERO)) {
      obj.totalQueryCount = (message.totalQueryCount || Long.UZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobSummaryList>, I>>(base?: I): JobSummaryList {
    return JobSummaryList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JobSummaryList>, I>>(object: I): JobSummaryList {
    const message = createBaseJobSummaryList();
    message.jobSummaries = object.jobSummaries?.map((e) => JobSummary.fromPartial(e)) || [];
    message.totalQueryCount = (object.totalQueryCount !== undefined && object.totalQueryCount !== null)
      ? Long.fromValue(object.totalQueryCount)
      : Long.UZERO;
    return message;
  },
};

function createBaseCompileJobResult(): CompileJobResult {
  return { compileJobId: "", compileDetail: undefined };
}

export const CompileJobResult: MessageFns<CompileJobResult> = {
  encode(message: CompileJobResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.compileJobId !== "") {
      writer.uint32(10).string(message.compileJobId);
    }
    if (message.compileDetail !== undefined) {
      CompileDetail.encode(message.compileDetail, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CompileJobResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompileJobResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.compileJobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.compileDetail = CompileDetail.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompileJobResult {
    return {
      compileJobId: isSet(object.compileJobId) ? globalThis.String(object.compileJobId) : "",
      compileDetail: isSet(object.compileDetail) ? CompileDetail.fromJSON(object.compileDetail) : undefined,
    };
  },

  toJSON(message: CompileJobResult): unknown {
    const obj: any = {};
    if (message.compileJobId !== "") {
      obj.compileJobId = message.compileJobId;
    }
    if (message.compileDetail !== undefined) {
      obj.compileDetail = CompileDetail.toJSON(message.compileDetail);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompileJobResult>, I>>(base?: I): CompileJobResult {
    return CompileJobResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompileJobResult>, I>>(object: I): CompileJobResult {
    const message = createBaseCompileJobResult();
    message.compileJobId = object.compileJobId ?? "";
    message.compileDetail = (object.compileDetail !== undefined && object.compileDetail !== null)
      ? CompileDetail.fromPartial(object.compileDetail)
      : undefined;
    return message;
  },
};

function createBaseLinkJobResult(): LinkJobResult {
  return { linkJobId: "", linkDetail: undefined };
}

export const LinkJobResult: MessageFns<LinkJobResult> = {
  encode(message: LinkJobResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.linkJobId !== "") {
      writer.uint32(10).string(message.linkJobId);
    }
    if (message.linkDetail !== undefined) {
      LinkDetail.encode(message.linkDetail, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LinkJobResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLinkJobResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkJobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.linkDetail = LinkDetail.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LinkJobResult {
    return {
      linkJobId: isSet(object.linkJobId) ? globalThis.String(object.linkJobId) : "",
      linkDetail: isSet(object.linkDetail) ? LinkDetail.fromJSON(object.linkDetail) : undefined,
    };
  },

  toJSON(message: LinkJobResult): unknown {
    const obj: any = {};
    if (message.linkJobId !== "") {
      obj.linkJobId = message.linkJobId;
    }
    if (message.linkDetail !== undefined) {
      obj.linkDetail = LinkDetail.toJSON(message.linkDetail);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LinkJobResult>, I>>(base?: I): LinkJobResult {
    return LinkJobResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LinkJobResult>, I>>(object: I): LinkJobResult {
    const message = createBaseLinkJobResult();
    message.linkJobId = object.linkJobId ?? "";
    message.linkDetail = (object.linkDetail !== undefined && object.linkDetail !== null)
      ? LinkDetail.fromPartial(object.linkDetail)
      : undefined;
    return message;
  },
};

function createBaseQuantizeJobResult(): QuantizeJobResult {
  return { quantizeJobId: "", quantizeDetail: undefined };
}

export const QuantizeJobResult: MessageFns<QuantizeJobResult> = {
  encode(message: QuantizeJobResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.quantizeJobId !== "") {
      writer.uint32(10).string(message.quantizeJobId);
    }
    if (message.quantizeDetail !== undefined) {
      QuantizeDetail.encode(message.quantizeDetail, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QuantizeJobResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantizeJobResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quantizeJobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.quantizeDetail = QuantizeDetail.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantizeJobResult {
    return {
      quantizeJobId: isSet(object.quantizeJobId) ? globalThis.String(object.quantizeJobId) : "",
      quantizeDetail: isSet(object.quantizeDetail) ? QuantizeDetail.fromJSON(object.quantizeDetail) : undefined,
    };
  },

  toJSON(message: QuantizeJobResult): unknown {
    const obj: any = {};
    if (message.quantizeJobId !== "") {
      obj.quantizeJobId = message.quantizeJobId;
    }
    if (message.quantizeDetail !== undefined) {
      obj.quantizeDetail = QuantizeDetail.toJSON(message.quantizeDetail);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantizeJobResult>, I>>(base?: I): QuantizeJobResult {
    return QuantizeJobResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QuantizeJobResult>, I>>(object: I): QuantizeJobResult {
    const message = createBaseQuantizeJobResult();
    message.quantizeJobId = object.quantizeJobId ?? "";
    message.quantizeDetail = (object.quantizeDetail !== undefined && object.quantizeDetail !== null)
      ? QuantizeDetail.fromPartial(object.quantizeDetail)
      : undefined;
    return message;
  },
};

function createBaseProfileJobResult(): ProfileJobResult {
  return { profileJobId: "", profile: undefined };
}

export const ProfileJobResult: MessageFns<ProfileJobResult> = {
  encode(message: ProfileJobResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.profileJobId !== "") {
      writer.uint32(10).string(message.profileJobId);
    }
    if (message.profile !== undefined) {
      ProfileDetail.encode(message.profile, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ProfileJobResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProfileJobResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.profileJobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.profile = ProfileDetail.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProfileJobResult {
    return {
      profileJobId: isSet(object.profileJobId) ? globalThis.String(object.profileJobId) : "",
      profile: isSet(object.profile) ? ProfileDetail.fromJSON(object.profile) : undefined,
    };
  },

  toJSON(message: ProfileJobResult): unknown {
    const obj: any = {};
    if (message.profileJobId !== "") {
      obj.profileJobId = message.profileJobId;
    }
    if (message.profile !== undefined) {
      obj.profile = ProfileDetail.toJSON(message.profile);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProfileJobResult>, I>>(base?: I): ProfileJobResult {
    return ProfileJobResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProfileJobResult>, I>>(object: I): ProfileJobResult {
    const message = createBaseProfileJobResult();
    message.profileJobId = object.profileJobId ?? "";
    message.profile = (object.profile !== undefined && object.profile !== null)
      ? ProfileDetail.fromPartial(object.profile)
      : undefined;
    return message;
  },
};

function createBaseInferenceJobResult(): InferenceJobResult {
  return { inferenceJobId: "", outputDatasetId: "", detail: undefined };
}

export const InferenceJobResult: MessageFns<InferenceJobResult> = {
  encode(message: InferenceJobResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.inferenceJobId !== "") {
      writer.uint32(10).string(message.inferenceJobId);
    }
    if (message.outputDatasetId !== "") {
      writer.uint32(18).string(message.outputDatasetId);
    }
    if (message.detail !== undefined) {
      ProfileDetail.encode(message.detail, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): InferenceJobResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInferenceJobResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.inferenceJobId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.outputDatasetId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.detail = ProfileDetail.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InferenceJobResult {
    return {
      inferenceJobId: isSet(object.inferenceJobId) ? globalThis.String(object.inferenceJobId) : "",
      outputDatasetId: isSet(object.outputDatasetId) ? globalThis.String(object.outputDatasetId) : "",
      detail: isSet(object.detail) ? ProfileDetail.fromJSON(object.detail) : undefined,
    };
  },

  toJSON(message: InferenceJobResult): unknown {
    const obj: any = {};
    if (message.inferenceJobId !== "") {
      obj.inferenceJobId = message.inferenceJobId;
    }
    if (message.outputDatasetId !== "") {
      obj.outputDatasetId = message.outputDatasetId;
    }
    if (message.detail !== undefined) {
      obj.detail = ProfileDetail.toJSON(message.detail);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InferenceJobResult>, I>>(base?: I): InferenceJobResult {
    return InferenceJobResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InferenceJobResult>, I>>(object: I): InferenceJobResult {
    const message = createBaseInferenceJobResult();
    message.inferenceJobId = object.inferenceJobId ?? "";
    message.outputDatasetId = object.outputDatasetId ?? "";
    message.detail = (object.detail !== undefined && object.detail !== null)
      ? ProfileDetail.fromPartial(object.detail)
      : undefined;
    return message;
  },
};

function createBaseJobResult(): JobResult {
  return {
    profileJobResult: undefined,
    inferenceJobResult: undefined,
    compileJobResult: undefined,
    quantizeJobResult: undefined,
    linkJobResult: undefined,
  };
}

export const JobResult: MessageFns<JobResult> = {
  encode(message: JobResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.profileJobResult !== undefined) {
      ProfileJobResult.encode(message.profileJobResult, writer.uint32(10).fork()).join();
    }
    if (message.inferenceJobResult !== undefined) {
      InferenceJobResult.encode(message.inferenceJobResult, writer.uint32(18).fork()).join();
    }
    if (message.compileJobResult !== undefined) {
      CompileJobResult.encode(message.compileJobResult, writer.uint32(26).fork()).join();
    }
    if (message.quantizeJobResult !== undefined) {
      QuantizeJobResult.encode(message.quantizeJobResult, writer.uint32(34).fork()).join();
    }
    if (message.linkJobResult !== undefined) {
      LinkJobResult.encode(message.linkJobResult, writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): JobResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.profileJobResult = ProfileJobResult.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.inferenceJobResult = InferenceJobResult.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.compileJobResult = CompileJobResult.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.quantizeJobResult = QuantizeJobResult.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.linkJobResult = LinkJobResult.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobResult {
    return {
      profileJobResult: isSet(object.profileJobResult) ? ProfileJobResult.fromJSON(object.profileJobResult) : undefined,
      inferenceJobResult: isSet(object.inferenceJobResult)
        ? InferenceJobResult.fromJSON(object.inferenceJobResult)
        : undefined,
      compileJobResult: isSet(object.compileJobResult) ? CompileJobResult.fromJSON(object.compileJobResult) : undefined,
      quantizeJobResult: isSet(object.quantizeJobResult)
        ? QuantizeJobResult.fromJSON(object.quantizeJobResult)
        : undefined,
      linkJobResult: isSet(object.linkJobResult) ? LinkJobResult.fromJSON(object.linkJobResult) : undefined,
    };
  },

  toJSON(message: JobResult): unknown {
    const obj: any = {};
    if (message.profileJobResult !== undefined) {
      obj.profileJobResult = ProfileJobResult.toJSON(message.profileJobResult);
    }
    if (message.inferenceJobResult !== undefined) {
      obj.inferenceJobResult = InferenceJobResult.toJSON(message.inferenceJobResult);
    }
    if (message.compileJobResult !== undefined) {
      obj.compileJobResult = CompileJobResult.toJSON(message.compileJobResult);
    }
    if (message.quantizeJobResult !== undefined) {
      obj.quantizeJobResult = QuantizeJobResult.toJSON(message.quantizeJobResult);
    }
    if (message.linkJobResult !== undefined) {
      obj.linkJobResult = LinkJobResult.toJSON(message.linkJobResult);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobResult>, I>>(base?: I): JobResult {
    return JobResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JobResult>, I>>(object: I): JobResult {
    const message = createBaseJobResult();
    message.profileJobResult = (object.profileJobResult !== undefined && object.profileJobResult !== null)
      ? ProfileJobResult.fromPartial(object.profileJobResult)
      : undefined;
    message.inferenceJobResult = (object.inferenceJobResult !== undefined && object.inferenceJobResult !== null)
      ? InferenceJobResult.fromPartial(object.inferenceJobResult)
      : undefined;
    message.compileJobResult = (object.compileJobResult !== undefined && object.compileJobResult !== null)
      ? CompileJobResult.fromPartial(object.compileJobResult)
      : undefined;
    message.quantizeJobResult = (object.quantizeJobResult !== undefined && object.quantizeJobResult !== null)
      ? QuantizeJobResult.fromPartial(object.quantizeJobResult)
      : undefined;
    message.linkJobResult = (object.linkJobResult !== undefined && object.linkJobResult !== null)
      ? LinkJobResult.fromPartial(object.linkJobResult)
      : undefined;
    return message;
  },
};

function createBaseVizGraph(): VizGraph {
  return { graphType: 0, subgraphs: [], parameters: {}, tensors: {} };
}

export const VizGraph: MessageFns<VizGraph> = {
  encode(message: VizGraph, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.graphType !== 0) {
      writer.uint32(8).int32(message.graphType);
    }
    for (const v of message.subgraphs) {
      VizSubgraph.encode(v!, writer.uint32(26).fork()).join();
    }
    Object.entries(message.parameters).forEach(([key, value]) => {
      VizGraph_ParametersEntry.encode({ key: key as any, value }, writer.uint32(34).fork()).join();
    });
    Object.entries(message.tensors).forEach(([key, value]) => {
      VizGraph_TensorsEntry.encode({ key: key as any, value }, writer.uint32(42).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VizGraph {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizGraph();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.graphType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.subgraphs.push(VizSubgraph.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = VizGraph_ParametersEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.parameters[entry4.key] = entry4.value;
          }
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          const entry5 = VizGraph_TensorsEntry.decode(reader, reader.uint32());
          if (entry5.value !== undefined) {
            message.tensors[entry5.key] = entry5.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizGraph {
    return {
      graphType: isSet(object.graphType) ? vizGraphTypeFromJSON(object.graphType) : 0,
      subgraphs: globalThis.Array.isArray(object?.subgraphs)
        ? object.subgraphs.map((e: any) => VizSubgraph.fromJSON(e))
        : [],
      parameters: isObject(object.parameters)
        ? Object.entries(object.parameters).reduce<{ [key: string]: VizValue }>((acc, [key, value]) => {
          acc[key] = VizValue.fromJSON(value);
          return acc;
        }, {})
        : {},
      tensors: isObject(object.tensors)
        ? Object.entries(object.tensors).reduce<{ [key: string]: VizTensor }>((acc, [key, value]) => {
          acc[key] = VizTensor.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: VizGraph): unknown {
    const obj: any = {};
    if (message.graphType !== 0) {
      obj.graphType = vizGraphTypeToJSON(message.graphType);
    }
    if (message.subgraphs?.length) {
      obj.subgraphs = message.subgraphs.map((e) => VizSubgraph.toJSON(e));
    }
    if (message.parameters) {
      const entries = Object.entries(message.parameters);
      if (entries.length > 0) {
        obj.parameters = {};
        entries.forEach(([k, v]) => {
          obj.parameters[k] = VizValue.toJSON(v);
        });
      }
    }
    if (message.tensors) {
      const entries = Object.entries(message.tensors);
      if (entries.length > 0) {
        obj.tensors = {};
        entries.forEach(([k, v]) => {
          obj.tensors[k] = VizTensor.toJSON(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizGraph>, I>>(base?: I): VizGraph {
    return VizGraph.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizGraph>, I>>(object: I): VizGraph {
    const message = createBaseVizGraph();
    message.graphType = object.graphType ?? 0;
    message.subgraphs = object.subgraphs?.map((e) => VizSubgraph.fromPartial(e)) || [];
    message.parameters = Object.entries(object.parameters ?? {}).reduce<{ [key: string]: VizValue }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = VizValue.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.tensors = Object.entries(object.tensors ?? {}).reduce<{ [key: string]: VizTensor }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = VizTensor.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseVizGraph_ParametersEntry(): VizGraph_ParametersEntry {
  return { key: "", value: undefined };
}

export const VizGraph_ParametersEntry: MessageFns<VizGraph_ParametersEntry> = {
  encode(message: VizGraph_ParametersEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      VizValue.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VizGraph_ParametersEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizGraph_ParametersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = VizValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizGraph_ParametersEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? VizValue.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: VizGraph_ParametersEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = VizValue.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizGraph_ParametersEntry>, I>>(base?: I): VizGraph_ParametersEntry {
    return VizGraph_ParametersEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizGraph_ParametersEntry>, I>>(object: I): VizGraph_ParametersEntry {
    const message = createBaseVizGraph_ParametersEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? VizValue.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseVizGraph_TensorsEntry(): VizGraph_TensorsEntry {
  return { key: "", value: undefined };
}

export const VizGraph_TensorsEntry: MessageFns<VizGraph_TensorsEntry> = {
  encode(message: VizGraph_TensorsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      VizTensor.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VizGraph_TensorsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizGraph_TensorsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = VizTensor.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizGraph_TensorsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? VizTensor.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: VizGraph_TensorsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = VizTensor.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizGraph_TensorsEntry>, I>>(base?: I): VizGraph_TensorsEntry {
    return VizGraph_TensorsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizGraph_TensorsEntry>, I>>(object: I): VizGraph_TensorsEntry {
    const message = createBaseVizGraph_TensorsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? VizTensor.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseVizSubgraph(): VizSubgraph {
  return { name: "", nodes: [] };
}

export const VizSubgraph: MessageFns<VizSubgraph> = {
  encode(message: VizSubgraph, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    for (const v of message.nodes) {
      VizNode.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VizSubgraph {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizSubgraph();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodes.push(VizNode.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizSubgraph {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      nodes: globalThis.Array.isArray(object?.nodes) ? object.nodes.map((e: any) => VizNode.fromJSON(e)) : [],
    };
  },

  toJSON(message: VizSubgraph): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.nodes?.length) {
      obj.nodes = message.nodes.map((e) => VizNode.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizSubgraph>, I>>(base?: I): VizSubgraph {
    return VizSubgraph.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizSubgraph>, I>>(object: I): VizSubgraph {
    const message = createBaseVizSubgraph();
    message.name = object.name ?? "";
    message.nodes = object.nodes?.map((e) => VizNode.fromPartial(e)) || [];
    return message;
  },
};

function createBaseVizShape(): VizShape {
  return { dim: [] };
}

export const VizShape: MessageFns<VizShape> = {
  encode(message: VizShape, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    writer.uint32(10).fork();
    for (const v of message.dim) {
      writer.int64(v.toString());
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VizShape {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizShape();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.dim.push(Long.fromString(reader.int64().toString()));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.dim.push(Long.fromString(reader.int64().toString()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizShape {
    return { dim: globalThis.Array.isArray(object?.dim) ? object.dim.map((e: any) => Long.fromValue(e)) : [] };
  },

  toJSON(message: VizShape): unknown {
    const obj: any = {};
    if (message.dim?.length) {
      obj.dim = message.dim.map((e) => (e || Long.ZERO).toString());
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizShape>, I>>(base?: I): VizShape {
    return VizShape.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizShape>, I>>(object: I): VizShape {
    const message = createBaseVizShape();
    message.dim = object.dim?.map((e) => Long.fromValue(e)) || [];
    return message;
  },
};

function createBaseVizTensor(): VizTensor {
  return { dtype: 0, shape: undefined, parameters: {}, data: undefined };
}

export const VizTensor: MessageFns<VizTensor> = {
  encode(message: VizTensor, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.dtype !== 0) {
      writer.uint32(16).int32(message.dtype);
    }
    if (message.shape !== undefined) {
      VizShape.encode(message.shape, writer.uint32(26).fork()).join();
    }
    Object.entries(message.parameters).forEach(([key, value]) => {
      VizTensor_ParametersEntry.encode({ key: key as any, value }, writer.uint32(34).fork()).join();
    });
    if (message.data !== undefined) {
      VizValue.encode(message.data, writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VizTensor {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizTensor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.dtype = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.shape = VizShape.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = VizTensor_ParametersEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.parameters[entry4.key] = entry4.value;
          }
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.data = VizValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizTensor {
    return {
      dtype: isSet(object.dtype) ? vizDtypeFromJSON(object.dtype) : 0,
      shape: isSet(object.shape) ? VizShape.fromJSON(object.shape) : undefined,
      parameters: isObject(object.parameters)
        ? Object.entries(object.parameters).reduce<{ [key: string]: VizValue }>((acc, [key, value]) => {
          acc[key] = VizValue.fromJSON(value);
          return acc;
        }, {})
        : {},
      data: isSet(object.data) ? VizValue.fromJSON(object.data) : undefined,
    };
  },

  toJSON(message: VizTensor): unknown {
    const obj: any = {};
    if (message.dtype !== 0) {
      obj.dtype = vizDtypeToJSON(message.dtype);
    }
    if (message.shape !== undefined) {
      obj.shape = VizShape.toJSON(message.shape);
    }
    if (message.parameters) {
      const entries = Object.entries(message.parameters);
      if (entries.length > 0) {
        obj.parameters = {};
        entries.forEach(([k, v]) => {
          obj.parameters[k] = VizValue.toJSON(v);
        });
      }
    }
    if (message.data !== undefined) {
      obj.data = VizValue.toJSON(message.data);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizTensor>, I>>(base?: I): VizTensor {
    return VizTensor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizTensor>, I>>(object: I): VizTensor {
    const message = createBaseVizTensor();
    message.dtype = object.dtype ?? 0;
    message.shape = (object.shape !== undefined && object.shape !== null)
      ? VizShape.fromPartial(object.shape)
      : undefined;
    message.parameters = Object.entries(object.parameters ?? {}).reduce<{ [key: string]: VizValue }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = VizValue.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.data = (object.data !== undefined && object.data !== null) ? VizValue.fromPartial(object.data) : undefined;
    return message;
  },
};

function createBaseVizTensor_ParametersEntry(): VizTensor_ParametersEntry {
  return { key: "", value: undefined };
}

export const VizTensor_ParametersEntry: MessageFns<VizTensor_ParametersEntry> = {
  encode(message: VizTensor_ParametersEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      VizValue.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VizTensor_ParametersEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizTensor_ParametersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = VizValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizTensor_ParametersEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? VizValue.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: VizTensor_ParametersEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = VizValue.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizTensor_ParametersEntry>, I>>(base?: I): VizTensor_ParametersEntry {
    return VizTensor_ParametersEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizTensor_ParametersEntry>, I>>(object: I): VizTensor_ParametersEntry {
    const message = createBaseVizTensor_ParametersEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? VizValue.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseVizNode(): VizNode {
  return {
    name: "",
    opType: "",
    inputs: [],
    outputs: [],
    inputKeys: [],
    outputKeys: [],
    attributes: {},
    subgraph: Long.ZERO,
  };
}

export const VizNode: MessageFns<VizNode> = {
  encode(message: VizNode, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.opType !== "") {
      writer.uint32(18).string(message.opType);
    }
    for (const v of message.inputs) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.outputs) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.inputKeys) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.outputKeys) {
      writer.uint32(50).string(v!);
    }
    Object.entries(message.attributes).forEach(([key, value]) => {
      VizNode_AttributesEntry.encode({ key: key as any, value }, writer.uint32(58).fork()).join();
    });
    if (!message.subgraph.equals(Long.ZERO)) {
      writer.uint32(64).int64(message.subgraph.toString());
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VizNode {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizNode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.opType = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.inputs.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.outputs.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.inputKeys.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.outputKeys.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = VizNode_AttributesEntry.decode(reader, reader.uint32());
          if (entry7.value !== undefined) {
            message.attributes[entry7.key] = entry7.value;
          }
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.subgraph = Long.fromString(reader.int64().toString());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizNode {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      opType: isSet(object.opType) ? globalThis.String(object.opType) : "",
      inputs: globalThis.Array.isArray(object?.inputs) ? object.inputs.map((e: any) => globalThis.String(e)) : [],
      outputs: globalThis.Array.isArray(object?.outputs) ? object.outputs.map((e: any) => globalThis.String(e)) : [],
      inputKeys: globalThis.Array.isArray(object?.inputKeys)
        ? object.inputKeys.map((e: any) => globalThis.String(e))
        : [],
      outputKeys: globalThis.Array.isArray(object?.outputKeys)
        ? object.outputKeys.map((e: any) => globalThis.String(e))
        : [],
      attributes: isObject(object.attributes)
        ? Object.entries(object.attributes).reduce<{ [key: string]: VizAttribute }>((acc, [key, value]) => {
          acc[key] = VizAttribute.fromJSON(value);
          return acc;
        }, {})
        : {},
      subgraph: isSet(object.subgraph) ? Long.fromValue(object.subgraph) : Long.ZERO,
    };
  },

  toJSON(message: VizNode): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.opType !== "") {
      obj.opType = message.opType;
    }
    if (message.inputs?.length) {
      obj.inputs = message.inputs;
    }
    if (message.outputs?.length) {
      obj.outputs = message.outputs;
    }
    if (message.inputKeys?.length) {
      obj.inputKeys = message.inputKeys;
    }
    if (message.outputKeys?.length) {
      obj.outputKeys = message.outputKeys;
    }
    if (message.attributes) {
      const entries = Object.entries(message.attributes);
      if (entries.length > 0) {
        obj.attributes = {};
        entries.forEach(([k, v]) => {
          obj.attributes[k] = VizAttribute.toJSON(v);
        });
      }
    }
    if (!message.subgraph.equals(Long.ZERO)) {
      obj.subgraph = (message.subgraph || Long.ZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizNode>, I>>(base?: I): VizNode {
    return VizNode.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizNode>, I>>(object: I): VizNode {
    const message = createBaseVizNode();
    message.name = object.name ?? "";
    message.opType = object.opType ?? "";
    message.inputs = object.inputs?.map((e) => e) || [];
    message.outputs = object.outputs?.map((e) => e) || [];
    message.inputKeys = object.inputKeys?.map((e) => e) || [];
    message.outputKeys = object.outputKeys?.map((e) => e) || [];
    message.attributes = Object.entries(object.attributes ?? {}).reduce<{ [key: string]: VizAttribute }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = VizAttribute.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.subgraph = (object.subgraph !== undefined && object.subgraph !== null)
      ? Long.fromValue(object.subgraph)
      : Long.ZERO;
    return message;
  },
};

function createBaseVizNode_AttributesEntry(): VizNode_AttributesEntry {
  return { key: "", value: undefined };
}

export const VizNode_AttributesEntry: MessageFns<VizNode_AttributesEntry> = {
  encode(message: VizNode_AttributesEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      VizAttribute.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VizNode_AttributesEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizNode_AttributesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = VizAttribute.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizNode_AttributesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? VizAttribute.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: VizNode_AttributesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = VizAttribute.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizNode_AttributesEntry>, I>>(base?: I): VizNode_AttributesEntry {
    return VizNode_AttributesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizNode_AttributesEntry>, I>>(object: I): VizNode_AttributesEntry {
    const message = createBaseVizNode_AttributesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? VizAttribute.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseVizValue(): VizValue {
  return {
    literalValue: undefined,
    stringValue: undefined,
    integerValue: undefined,
    floatValue: undefined,
    boolValue: undefined,
    stringList: undefined,
    integerList: undefined,
    floatList: undefined,
    boolList: undefined,
  };
}

export const VizValue: MessageFns<VizValue> = {
  encode(message: VizValue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.literalValue !== undefined) {
      writer.uint32(10).string(message.literalValue);
    }
    if (message.stringValue !== undefined) {
      writer.uint32(18).string(message.stringValue);
    }
    if (message.integerValue !== undefined) {
      writer.uint32(24).int64(message.integerValue.toString());
    }
    if (message.floatValue !== undefined) {
      writer.uint32(37).float(message.floatValue);
    }
    if (message.boolValue !== undefined) {
      writer.uint32(40).bool(message.boolValue);
    }
    if (message.stringList !== undefined) {
      VizValue_StringList.encode(message.stringList, writer.uint32(50).fork()).join();
    }
    if (message.integerList !== undefined) {
      VizValue_IntegerList.encode(message.integerList, writer.uint32(58).fork()).join();
    }
    if (message.floatList !== undefined) {
      VizValue_FloatList.encode(message.floatList, writer.uint32(66).fork()).join();
    }
    if (message.boolList !== undefined) {
      VizValue_BoolList.encode(message.boolList, writer.uint32(74).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VizValue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.literalValue = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.stringValue = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.integerValue = Long.fromString(reader.int64().toString());
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.floatValue = reader.float();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.boolValue = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.stringList = VizValue_StringList.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.integerList = VizValue_IntegerList.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.floatList = VizValue_FloatList.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.boolList = VizValue_BoolList.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizValue {
    return {
      literalValue: isSet(object.literalValue) ? globalThis.String(object.literalValue) : undefined,
      stringValue: isSet(object.stringValue) ? globalThis.String(object.stringValue) : undefined,
      integerValue: isSet(object.integerValue) ? Long.fromValue(object.integerValue) : undefined,
      floatValue: isSet(object.floatValue) ? globalThis.Number(object.floatValue) : undefined,
      boolValue: isSet(object.boolValue) ? globalThis.Boolean(object.boolValue) : undefined,
      stringList: isSet(object.stringList) ? VizValue_StringList.fromJSON(object.stringList) : undefined,
      integerList: isSet(object.integerList) ? VizValue_IntegerList.fromJSON(object.integerList) : undefined,
      floatList: isSet(object.floatList) ? VizValue_FloatList.fromJSON(object.floatList) : undefined,
      boolList: isSet(object.boolList) ? VizValue_BoolList.fromJSON(object.boolList) : undefined,
    };
  },

  toJSON(message: VizValue): unknown {
    const obj: any = {};
    if (message.literalValue !== undefined) {
      obj.literalValue = message.literalValue;
    }
    if (message.stringValue !== undefined) {
      obj.stringValue = message.stringValue;
    }
    if (message.integerValue !== undefined) {
      obj.integerValue = (message.integerValue || Long.ZERO).toString();
    }
    if (message.floatValue !== undefined) {
      obj.floatValue = message.floatValue;
    }
    if (message.boolValue !== undefined) {
      obj.boolValue = message.boolValue;
    }
    if (message.stringList !== undefined) {
      obj.stringList = VizValue_StringList.toJSON(message.stringList);
    }
    if (message.integerList !== undefined) {
      obj.integerList = VizValue_IntegerList.toJSON(message.integerList);
    }
    if (message.floatList !== undefined) {
      obj.floatList = VizValue_FloatList.toJSON(message.floatList);
    }
    if (message.boolList !== undefined) {
      obj.boolList = VizValue_BoolList.toJSON(message.boolList);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizValue>, I>>(base?: I): VizValue {
    return VizValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizValue>, I>>(object: I): VizValue {
    const message = createBaseVizValue();
    message.literalValue = object.literalValue ?? undefined;
    message.stringValue = object.stringValue ?? undefined;
    message.integerValue = (object.integerValue !== undefined && object.integerValue !== null)
      ? Long.fromValue(object.integerValue)
      : undefined;
    message.floatValue = object.floatValue ?? undefined;
    message.boolValue = object.boolValue ?? undefined;
    message.stringList = (object.stringList !== undefined && object.stringList !== null)
      ? VizValue_StringList.fromPartial(object.stringList)
      : undefined;
    message.integerList = (object.integerList !== undefined && object.integerList !== null)
      ? VizValue_IntegerList.fromPartial(object.integerList)
      : undefined;
    message.floatList = (object.floatList !== undefined && object.floatList !== null)
      ? VizValue_FloatList.fromPartial(object.floatList)
      : undefined;
    message.boolList = (object.boolList !== undefined && object.boolList !== null)
      ? VizValue_BoolList.fromPartial(object.boolList)
      : undefined;
    return message;
  },
};

function createBaseVizValue_StringList(): VizValue_StringList {
  return { list: [] };
}

export const VizValue_StringList: MessageFns<VizValue_StringList> = {
  encode(message: VizValue_StringList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.list) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VizValue_StringList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizValue_StringList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.list.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizValue_StringList {
    return { list: globalThis.Array.isArray(object?.list) ? object.list.map((e: any) => globalThis.String(e)) : [] };
  },

  toJSON(message: VizValue_StringList): unknown {
    const obj: any = {};
    if (message.list?.length) {
      obj.list = message.list;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizValue_StringList>, I>>(base?: I): VizValue_StringList {
    return VizValue_StringList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizValue_StringList>, I>>(object: I): VizValue_StringList {
    const message = createBaseVizValue_StringList();
    message.list = object.list?.map((e) => e) || [];
    return message;
  },
};

function createBaseVizValue_IntegerList(): VizValue_IntegerList {
  return { list: [] };
}

export const VizValue_IntegerList: MessageFns<VizValue_IntegerList> = {
  encode(message: VizValue_IntegerList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    writer.uint32(10).fork();
    for (const v of message.list) {
      writer.int64(v.toString());
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VizValue_IntegerList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizValue_IntegerList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.list.push(Long.fromString(reader.int64().toString()));

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.list.push(Long.fromString(reader.int64().toString()));
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizValue_IntegerList {
    return { list: globalThis.Array.isArray(object?.list) ? object.list.map((e: any) => Long.fromValue(e)) : [] };
  },

  toJSON(message: VizValue_IntegerList): unknown {
    const obj: any = {};
    if (message.list?.length) {
      obj.list = message.list.map((e) => (e || Long.ZERO).toString());
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizValue_IntegerList>, I>>(base?: I): VizValue_IntegerList {
    return VizValue_IntegerList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizValue_IntegerList>, I>>(object: I): VizValue_IntegerList {
    const message = createBaseVizValue_IntegerList();
    message.list = object.list?.map((e) => Long.fromValue(e)) || [];
    return message;
  },
};

function createBaseVizValue_FloatList(): VizValue_FloatList {
  return { list: [] };
}

export const VizValue_FloatList: MessageFns<VizValue_FloatList> = {
  encode(message: VizValue_FloatList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    writer.uint32(10).fork();
    for (const v of message.list) {
      writer.float(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VizValue_FloatList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizValue_FloatList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 13) {
            message.list.push(reader.float());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.list.push(reader.float());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizValue_FloatList {
    return { list: globalThis.Array.isArray(object?.list) ? object.list.map((e: any) => globalThis.Number(e)) : [] };
  },

  toJSON(message: VizValue_FloatList): unknown {
    const obj: any = {};
    if (message.list?.length) {
      obj.list = message.list;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizValue_FloatList>, I>>(base?: I): VizValue_FloatList {
    return VizValue_FloatList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizValue_FloatList>, I>>(object: I): VizValue_FloatList {
    const message = createBaseVizValue_FloatList();
    message.list = object.list?.map((e) => e) || [];
    return message;
  },
};

function createBaseVizValue_BoolList(): VizValue_BoolList {
  return { list: [] };
}

export const VizValue_BoolList: MessageFns<VizValue_BoolList> = {
  encode(message: VizValue_BoolList, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    writer.uint32(10).fork();
    for (const v of message.list) {
      writer.bool(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VizValue_BoolList {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizValue_BoolList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.list.push(reader.bool());

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.list.push(reader.bool());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizValue_BoolList {
    return { list: globalThis.Array.isArray(object?.list) ? object.list.map((e: any) => globalThis.Boolean(e)) : [] };
  },

  toJSON(message: VizValue_BoolList): unknown {
    const obj: any = {};
    if (message.list?.length) {
      obj.list = message.list;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizValue_BoolList>, I>>(base?: I): VizValue_BoolList {
    return VizValue_BoolList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizValue_BoolList>, I>>(object: I): VizValue_BoolList {
    const message = createBaseVizValue_BoolList();
    message.list = object.list?.map((e) => e) || [];
    return message;
  },
};

function createBaseVizAttribute(): VizAttribute {
  return { value: undefined, tensor: undefined };
}

export const VizAttribute: MessageFns<VizAttribute> = {
  encode(message: VizAttribute, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.value !== undefined) {
      VizValue.encode(message.value, writer.uint32(10).fork()).join();
    }
    if (message.tensor !== undefined) {
      VizTensor.encode(message.tensor, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VizAttribute {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVizAttribute();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = VizValue.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tensor = VizTensor.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VizAttribute {
    return {
      value: isSet(object.value) ? VizValue.fromJSON(object.value) : undefined,
      tensor: isSet(object.tensor) ? VizTensor.fromJSON(object.tensor) : undefined,
    };
  },

  toJSON(message: VizAttribute): unknown {
    const obj: any = {};
    if (message.value !== undefined) {
      obj.value = VizValue.toJSON(message.value);
    }
    if (message.tensor !== undefined) {
      obj.tensor = VizTensor.toJSON(message.tensor);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VizAttribute>, I>>(base?: I): VizAttribute {
    return VizAttribute.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VizAttribute>, I>>(object: I): VizAttribute {
    const message = createBaseVizAttribute();
    message.value = (object.value !== undefined && object.value !== null)
      ? VizValue.fromPartial(object.value)
      : undefined;
    message.tensor = (object.tensor !== undefined && object.tensor !== null)
      ? VizTensor.fromPartial(object.tensor)
      : undefined;
    return message;
  },
};

function createBaseJobAssociatedModel(): JobAssociatedModel {
  return { modelId: "", jobId: "", modelType: 0, modelRelation: 0, hasVizgraph: false };
}

export const JobAssociatedModel: MessageFns<JobAssociatedModel> = {
  encode(message: JobAssociatedModel, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.modelId !== "") {
      writer.uint32(10).string(message.modelId);
    }
    if (message.jobId !== "") {
      writer.uint32(18).string(message.jobId);
    }
    if (message.modelType !== 0) {
      writer.uint32(24).int32(message.modelType);
    }
    if (message.modelRelation !== 0) {
      writer.uint32(32).int32(message.modelRelation);
    }
    if (message.hasVizgraph !== false) {
      writer.uint32(40).bool(message.hasVizgraph);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): JobAssociatedModel {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobAssociatedModel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modelId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.jobId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.modelType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.modelRelation = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.hasVizgraph = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobAssociatedModel {
    return {
      modelId: isSet(object.modelId) ? globalThis.String(object.modelId) : "",
      jobId: isSet(object.jobId) ? globalThis.String(object.jobId) : "",
      modelType: isSet(object.modelType) ? modelTypeFromJSON(object.modelType) : 0,
      modelRelation: isSet(object.modelRelation) ? modelRelationFromJSON(object.modelRelation) : 0,
      hasVizgraph: isSet(object.hasVizgraph) ? globalThis.Boolean(object.hasVizgraph) : false,
    };
  },

  toJSON(message: JobAssociatedModel): unknown {
    const obj: any = {};
    if (message.modelId !== "") {
      obj.modelId = message.modelId;
    }
    if (message.jobId !== "") {
      obj.jobId = message.jobId;
    }
    if (message.modelType !== 0) {
      obj.modelType = modelTypeToJSON(message.modelType);
    }
    if (message.modelRelation !== 0) {
      obj.modelRelation = modelRelationToJSON(message.modelRelation);
    }
    if (message.hasVizgraph !== false) {
      obj.hasVizgraph = message.hasVizgraph;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobAssociatedModel>, I>>(base?: I): JobAssociatedModel {
    return JobAssociatedModel.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JobAssociatedModel>, I>>(object: I): JobAssociatedModel {
    const message = createBaseJobAssociatedModel();
    message.modelId = object.modelId ?? "";
    message.jobId = object.jobId ?? "";
    message.modelType = object.modelType ?? 0;
    message.modelRelation = object.modelRelation ?? 0;
    message.hasVizgraph = object.hasVizgraph ?? false;
    return message;
  },
};

function createBaseJobAssociatedModels(): JobAssociatedModels {
  return { associatedModels: [] };
}

export const JobAssociatedModels: MessageFns<JobAssociatedModels> = {
  encode(message: JobAssociatedModels, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.associatedModels) {
      JobAssociatedModel.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): JobAssociatedModels {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJobAssociatedModels();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.associatedModels.push(JobAssociatedModel.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JobAssociatedModels {
    return {
      associatedModels: globalThis.Array.isArray(object?.associatedModels)
        ? object.associatedModels.map((e: any) => JobAssociatedModel.fromJSON(e))
        : [],
    };
  },

  toJSON(message: JobAssociatedModels): unknown {
    const obj: any = {};
    if (message.associatedModels?.length) {
      obj.associatedModels = message.associatedModels.map((e) => JobAssociatedModel.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JobAssociatedModels>, I>>(base?: I): JobAssociatedModels {
    return JobAssociatedModels.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JobAssociatedModels>, I>>(object: I): JobAssociatedModels {
    const message = createBaseJobAssociatedModels();
    message.associatedModels = object.associatedModels?.map((e) => JobAssociatedModel.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLayerDetail(): LayerDetail {
  return {
    name: "",
    computeUnit: 0,
    layerTypeName: "",
    id: "",
    delegateName: "",
    delegateExtraInfo: "",
    executionTime: undefined,
    segmentId: undefined,
    delegateReportedOps: [],
    executionCycles: undefined,
  };
}

export const LayerDetail: MessageFns<LayerDetail> = {
  encode(message: LayerDetail, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.computeUnit !== 0) {
      writer.uint32(16).int32(message.computeUnit);
    }
    if (message.layerTypeName !== "") {
      writer.uint32(26).string(message.layerTypeName);
    }
    if (message.id !== "") {
      writer.uint32(34).string(message.id);
    }
    if (message.delegateName !== "") {
      writer.uint32(42).string(message.delegateName);
    }
    if (message.delegateExtraInfo !== "") {
      writer.uint32(50).string(message.delegateExtraInfo);
    }
    if (message.executionTime !== undefined) {
      writer.uint32(56).uint64(message.executionTime.toString());
    }
    if (message.segmentId !== undefined) {
      writer.uint32(66).string(message.segmentId);
    }
    for (const v of message.delegateReportedOps) {
      writer.uint32(74).string(v!);
    }
    if (message.executionCycles !== undefined) {
      writer.uint32(80).uint64(message.executionCycles.toString());
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LayerDetail {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLayerDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.computeUnit = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.layerTypeName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.id = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.delegateName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.delegateExtraInfo = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.executionTime = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.segmentId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.delegateReportedOps.push(reader.string());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.executionCycles = Long.fromString(reader.uint64().toString(), true);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LayerDetail {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      computeUnit: isSet(object.computeUnit) ? computeUnitFromJSON(object.computeUnit) : 0,
      layerTypeName: isSet(object.layerTypeName) ? globalThis.String(object.layerTypeName) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      delegateName: isSet(object.delegateName) ? globalThis.String(object.delegateName) : "",
      delegateExtraInfo: isSet(object.delegateExtraInfo) ? globalThis.String(object.delegateExtraInfo) : "",
      executionTime: isSet(object.executionTime) ? Long.fromValue(object.executionTime) : undefined,
      segmentId: isSet(object.segmentId) ? globalThis.String(object.segmentId) : undefined,
      delegateReportedOps: globalThis.Array.isArray(object?.delegateReportedOps)
        ? object.delegateReportedOps.map((e: any) => globalThis.String(e))
        : [],
      executionCycles: isSet(object.executionCycles) ? Long.fromValue(object.executionCycles) : undefined,
    };
  },

  toJSON(message: LayerDetail): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.computeUnit !== 0) {
      obj.computeUnit = computeUnitToJSON(message.computeUnit);
    }
    if (message.layerTypeName !== "") {
      obj.layerTypeName = message.layerTypeName;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.delegateName !== "") {
      obj.delegateName = message.delegateName;
    }
    if (message.delegateExtraInfo !== "") {
      obj.delegateExtraInfo = message.delegateExtraInfo;
    }
    if (message.executionTime !== undefined) {
      obj.executionTime = (message.executionTime || Long.UZERO).toString();
    }
    if (message.segmentId !== undefined) {
      obj.segmentId = message.segmentId;
    }
    if (message.delegateReportedOps?.length) {
      obj.delegateReportedOps = message.delegateReportedOps;
    }
    if (message.executionCycles !== undefined) {
      obj.executionCycles = (message.executionCycles || Long.UZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LayerDetail>, I>>(base?: I): LayerDetail {
    return LayerDetail.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LayerDetail>, I>>(object: I): LayerDetail {
    const message = createBaseLayerDetail();
    message.name = object.name ?? "";
    message.computeUnit = object.computeUnit ?? 0;
    message.layerTypeName = object.layerTypeName ?? "";
    message.id = object.id ?? "";
    message.delegateName = object.delegateName ?? "";
    message.delegateExtraInfo = object.delegateExtraInfo ?? "";
    message.executionTime = (object.executionTime !== undefined && object.executionTime !== null)
      ? Long.fromValue(object.executionTime)
      : undefined;
    message.segmentId = object.segmentId ?? undefined;
    message.delegateReportedOps = object.delegateReportedOps?.map((e) => e) || [];
    message.executionCycles = (object.executionCycles !== undefined && object.executionCycles !== null)
      ? Long.fromValue(object.executionCycles)
      : undefined;
    return message;
  },
};

function createBaseSegmentDetail(): SegmentDetail {
  return { id: "", computeUnit: 0, delegateName: "", delegateExtraInfo: "", executionTime: undefined };
}

export const SegmentDetail: MessageFns<SegmentDetail> = {
  encode(message: SegmentDetail, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.computeUnit !== 0) {
      writer.uint32(16).int32(message.computeUnit);
    }
    if (message.delegateName !== "") {
      writer.uint32(26).string(message.delegateName);
    }
    if (message.delegateExtraInfo !== "") {
      writer.uint32(34).string(message.delegateExtraInfo);
    }
    if (message.executionTime !== undefined) {
      writer.uint32(40).uint64(message.executionTime.toString());
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SegmentDetail {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSegmentDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.computeUnit = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.delegateName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.delegateExtraInfo = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.executionTime = Long.fromString(reader.uint64().toString(), true);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SegmentDetail {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      computeUnit: isSet(object.computeUnit) ? computeUnitFromJSON(object.computeUnit) : 0,
      delegateName: isSet(object.delegateName) ? globalThis.String(object.delegateName) : "",
      delegateExtraInfo: isSet(object.delegateExtraInfo) ? globalThis.String(object.delegateExtraInfo) : "",
      executionTime: isSet(object.executionTime) ? Long.fromValue(object.executionTime) : undefined,
    };
  },

  toJSON(message: SegmentDetail): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.computeUnit !== 0) {
      obj.computeUnit = computeUnitToJSON(message.computeUnit);
    }
    if (message.delegateName !== "") {
      obj.delegateName = message.delegateName;
    }
    if (message.delegateExtraInfo !== "") {
      obj.delegateExtraInfo = message.delegateExtraInfo;
    }
    if (message.executionTime !== undefined) {
      obj.executionTime = (message.executionTime || Long.UZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SegmentDetail>, I>>(base?: I): SegmentDetail {
    return SegmentDetail.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SegmentDetail>, I>>(object: I): SegmentDetail {
    const message = createBaseSegmentDetail();
    message.id = object.id ?? "";
    message.computeUnit = object.computeUnit ?? 0;
    message.delegateName = object.delegateName ?? "";
    message.delegateExtraInfo = object.delegateExtraInfo ?? "";
    message.executionTime = (object.executionTime !== undefined && object.executionTime !== null)
      ? Long.fromValue(object.executionTime)
      : undefined;
    return message;
  },
};

function createBaseRange(): Range {
  return { lower: Long.UZERO, upper: Long.UZERO };
}

export const Range: MessageFns<Range> = {
  encode(message: Range, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (!message.lower.equals(Long.UZERO)) {
      writer.uint32(8).uint64(message.lower.toString());
    }
    if (!message.upper.equals(Long.UZERO)) {
      writer.uint32(16).uint64(message.upper.toString());
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Range {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.lower = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.upper = Long.fromString(reader.uint64().toString(), true);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Range {
    return {
      lower: isSet(object.lower) ? Long.fromValue(object.lower) : Long.UZERO,
      upper: isSet(object.upper) ? Long.fromValue(object.upper) : Long.UZERO,
    };
  },

  toJSON(message: Range): unknown {
    const obj: any = {};
    if (!message.lower.equals(Long.UZERO)) {
      obj.lower = (message.lower || Long.UZERO).toString();
    }
    if (!message.upper.equals(Long.UZERO)) {
      obj.upper = (message.upper || Long.UZERO).toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Range>, I>>(base?: I): Range {
    return Range.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Range>, I>>(object: I): Range {
    const message = createBaseRange();
    message.lower = (object.lower !== undefined && object.lower !== null) ? Long.fromValue(object.lower) : Long.UZERO;
    message.upper = (object.upper !== undefined && object.upper !== null) ? Long.fromValue(object.upper) : Long.UZERO;
    return message;
  },
};

function createBaseRuntimeConfigProperty(): RuntimeConfigProperty {
  return { key: "", value: "" };
}

export const RuntimeConfigProperty: MessageFns<RuntimeConfigProperty> = {
  encode(message: RuntimeConfigProperty, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RuntimeConfigProperty {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuntimeConfigProperty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RuntimeConfigProperty {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: RuntimeConfigProperty): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RuntimeConfigProperty>, I>>(base?: I): RuntimeConfigProperty {
    return RuntimeConfigProperty.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RuntimeConfigProperty>, I>>(object: I): RuntimeConfigProperty {
    const message = createBaseRuntimeConfigProperty();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseRuntimeConfig(): RuntimeConfig {
  return { name: "", properties: [] };
}

export const RuntimeConfig: MessageFns<RuntimeConfig> = {
  encode(message: RuntimeConfig, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    for (const v of message.properties) {
      RuntimeConfigProperty.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RuntimeConfig {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRuntimeConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.properties.push(RuntimeConfigProperty.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RuntimeConfig {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      properties: globalThis.Array.isArray(object?.properties)
        ? object.properties.map((e: any) => RuntimeConfigProperty.fromJSON(e))
        : [],
    };
  },

  toJSON(message: RuntimeConfig): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.properties?.length) {
      obj.properties = message.properties.map((e) => RuntimeConfigProperty.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RuntimeConfig>, I>>(base?: I): RuntimeConfig {
    return RuntimeConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RuntimeConfig>, I>>(object: I): RuntimeConfig {
    const message = createBaseRuntimeConfig();
    message.name = object.name ?? "";
    message.properties = object.properties?.map((e) => RuntimeConfigProperty.fromPartial(e)) || [];
    return message;
  },
};

function createBaseToolVersion(): ToolVersion {
  return { name: "", version: "" };
}

export const ToolVersion: MessageFns<ToolVersion> = {
  encode(message: ToolVersion, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.version !== "") {
      writer.uint32(18).string(message.version);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ToolVersion {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseToolVersion();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.version = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ToolVersion {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "",
    };
  },

  toJSON(message: ToolVersion): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ToolVersion>, I>>(base?: I): ToolVersion {
    return ToolVersion.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ToolVersion>, I>>(object: I): ToolVersion {
    const message = createBaseToolVersion();
    message.name = object.name ?? "";
    message.version = object.version ?? "";
    return message;
  },
};

function createBaseProfileDetail(): ProfileDetail {
  return {
    executionTime: Long.UZERO,
    peakMemoryUsage: Long.UZERO,
    loadTime: undefined,
    layerDetails: [],
    majorVersion: Long.UZERO,
    minorVersion: Long.UZERO,
    afterColdLoadPeakMemory: Long.UZERO,
    afterCompilePeakMemory: Long.UZERO,
    afterExecutionPeakMemory: Long.UZERO,
    afterWarmLoadPeakMemory: Long.UZERO,
    beforeColdLoadCurrentMemory: Long.UZERO,
    beforeColdLoadPeakMemory: Long.UZERO,
    beforeCompileCurrentMemory: Long.UZERO,
    beforeCompilePeakMemory: Long.UZERO,
    beforeExecutionCurrentMemory: Long.UZERO,
    beforeExecutionPeakMemory: Long.UZERO,
    beforeWarmLoadCurrentMemory: Long.UZERO,
    beforeWarmLoadPeakMemory: Long.UZERO,
    coldLoadTime: Long.UZERO,
    compileTime: Long.UZERO,
    warmLoadTime: Long.UZERO,
    afterColdLoadCurrentMemory: Long.UZERO,
    afterWarmLoadCurrentMemory: Long.UZERO,
    afterCompileCurrentMemory: Long.UZERO,
    afterExecutionCurrentMemory: Long.UZERO,
    compileMemory: undefined,
    coldLoadMemory: undefined,
    warmLoadMemory: undefined,
    executionMemory: undefined,
    allCompileTimes: [],
    allColdLoadTimes: [],
    allWarmLoadTimes: [],
    allExecutionTimes: [],
    segmentDetails: [],
    runtimeConfig: [],
    toolVersions: [],
  };
}

export const ProfileDetail: MessageFns<ProfileDetail> = {
  encode(message: ProfileDetail, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (!message.executionTime.equals(Long.UZERO)) {
      writer.uint32(8).uint64(message.executionTime.toString());
    }
    if (!message.peakMemoryUsage.equals(Long.UZERO)) {
      writer.uint32(16).uint64(message.peakMemoryUsage.toString());
    }
    if (message.loadTime !== undefined) {
      writer.uint32(24).uint64(message.loadTime.toString());
    }
    for (const v of message.layerDetails) {
      LayerDetail.encode(v!, writer.uint32(34).fork()).join();
    }
    if (!message.majorVersion.equals(Long.UZERO)) {
      writer.uint32(40).uint64(message.majorVersion.toString());
    }
    if (!message.minorVersion.equals(Long.UZERO)) {
      writer.uint32(48).uint64(message.minorVersion.toString());
    }
    if (!message.afterColdLoadPeakMemory.equals(Long.UZERO)) {
      writer.uint32(56).uint64(message.afterColdLoadPeakMemory.toString());
    }
    if (!message.afterCompilePeakMemory.equals(Long.UZERO)) {
      writer.uint32(64).uint64(message.afterCompilePeakMemory.toString());
    }
    if (!message.afterExecutionPeakMemory.equals(Long.UZERO)) {
      writer.uint32(72).uint64(message.afterExecutionPeakMemory.toString());
    }
    if (!message.afterWarmLoadPeakMemory.equals(Long.UZERO)) {
      writer.uint32(80).uint64(message.afterWarmLoadPeakMemory.toString());
    }
    if (!message.beforeColdLoadCurrentMemory.equals(Long.UZERO)) {
      writer.uint32(88).uint64(message.beforeColdLoadCurrentMemory.toString());
    }
    if (!message.beforeColdLoadPeakMemory.equals(Long.UZERO)) {
      writer.uint32(96).uint64(message.beforeColdLoadPeakMemory.toString());
    }
    if (!message.beforeCompileCurrentMemory.equals(Long.UZERO)) {
      writer.uint32(104).uint64(message.beforeCompileCurrentMemory.toString());
    }
    if (!message.beforeCompilePeakMemory.equals(Long.UZERO)) {
      writer.uint32(112).uint64(message.beforeCompilePeakMemory.toString());
    }
    if (!message.beforeExecutionCurrentMemory.equals(Long.UZERO)) {
      writer.uint32(120).uint64(message.beforeExecutionCurrentMemory.toString());
    }
    if (!message.beforeExecutionPeakMemory.equals(Long.UZERO)) {
      writer.uint32(128).uint64(message.beforeExecutionPeakMemory.toString());
    }
    if (!message.beforeWarmLoadCurrentMemory.equals(Long.UZERO)) {
      writer.uint32(136).uint64(message.beforeWarmLoadCurrentMemory.toString());
    }
    if (!message.beforeWarmLoadPeakMemory.equals(Long.UZERO)) {
      writer.uint32(144).uint64(message.beforeWarmLoadPeakMemory.toString());
    }
    if (!message.coldLoadTime.equals(Long.UZERO)) {
      writer.uint32(152).uint64(message.coldLoadTime.toString());
    }
    if (!message.compileTime.equals(Long.UZERO)) {
      writer.uint32(160).uint64(message.compileTime.toString());
    }
    if (!message.warmLoadTime.equals(Long.UZERO)) {
      writer.uint32(168).uint64(message.warmLoadTime.toString());
    }
    if (!message.afterColdLoadCurrentMemory.equals(Long.UZERO)) {
      writer.uint32(176).uint64(message.afterColdLoadCurrentMemory.toString());
    }
    if (!message.afterWarmLoadCurrentMemory.equals(Long.UZERO)) {
      writer.uint32(184).uint64(message.afterWarmLoadCurrentMemory.toString());
    }
    if (!message.afterCompileCurrentMemory.equals(Long.UZERO)) {
      writer.uint32(192).uint64(message.afterCompileCurrentMemory.toString());
    }
    if (!message.afterExecutionCurrentMemory.equals(Long.UZERO)) {
      writer.uint32(200).uint64(message.afterExecutionCurrentMemory.toString());
    }
    if (message.compileMemory !== undefined) {
      ProfileDetail_MemoryUsage.encode(message.compileMemory, writer.uint32(210).fork()).join();
    }
    if (message.coldLoadMemory !== undefined) {
      ProfileDetail_MemoryUsage.encode(message.coldLoadMemory, writer.uint32(218).fork()).join();
    }
    if (message.warmLoadMemory !== undefined) {
      ProfileDetail_MemoryUsage.encode(message.warmLoadMemory, writer.uint32(226).fork()).join();
    }
    if (message.executionMemory !== undefined) {
      ProfileDetail_MemoryUsage.encode(message.executionMemory, writer.uint32(234).fork()).join();
    }
    writer.uint32(242).fork();
    for (const v of message.allCompileTimes) {
      writer.uint64(v.toString());
    }
    writer.join();
    writer.uint32(250).fork();
    for (const v of message.allColdLoadTimes) {
      writer.uint64(v.toString());
    }
    writer.join();
    writer.uint32(258).fork();
    for (const v of message.allWarmLoadTimes) {
      writer.uint64(v.toString());
    }
    writer.join();
    writer.uint32(266).fork();
    for (const v of message.allExecutionTimes) {
      writer.uint64(v.toString());
    }
    writer.join();
    for (const v of message.segmentDetails) {
      SegmentDetail.encode(v!, writer.uint32(274).fork()).join();
    }
    for (const v of message.runtimeConfig) {
      RuntimeConfig.encode(v!, writer.uint32(282).fork()).join();
    }
    for (const v of message.toolVersions) {
      ToolVersion.encode(v!, writer.uint32(290).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ProfileDetail {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProfileDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.executionTime = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.peakMemoryUsage = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.loadTime = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.layerDetails.push(LayerDetail.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.majorVersion = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.minorVersion = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.afterColdLoadPeakMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.afterCompilePeakMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.afterExecutionPeakMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.afterWarmLoadPeakMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.beforeColdLoadCurrentMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.beforeColdLoadPeakMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.beforeCompileCurrentMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.beforeCompilePeakMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.beforeExecutionCurrentMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.beforeExecutionPeakMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.beforeWarmLoadCurrentMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.beforeWarmLoadPeakMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.coldLoadTime = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.compileTime = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.warmLoadTime = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.afterColdLoadCurrentMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.afterWarmLoadCurrentMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.afterCompileCurrentMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.afterExecutionCurrentMemory = Long.fromString(reader.uint64().toString(), true);
          continue;
        case 26:
          if (tag !== 210) {
            break;
          }

          message.compileMemory = ProfileDetail_MemoryUsage.decode(reader, reader.uint32());
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.coldLoadMemory = ProfileDetail_MemoryUsage.decode(reader, reader.uint32());
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.warmLoadMemory = ProfileDetail_MemoryUsage.decode(reader, reader.uint32());
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.executionMemory = ProfileDetail_MemoryUsage.decode(reader, reader.uint32());
          continue;
        case 30:
          if (tag === 240) {
            message.allCompileTimes.push(Long.fromString(reader.uint64().toString(), true));

            continue;
          }

          if (tag === 242) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.allCompileTimes.push(Long.fromString(reader.uint64().toString(), true));
            }

            continue;
          }

          break;
        case 31:
          if (tag === 248) {
            message.allColdLoadTimes.push(Long.fromString(reader.uint64().toString(), true));

            continue;
          }

          if (tag === 250) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.allColdLoadTimes.push(Long.fromString(reader.uint64().toString(), true));
            }

            continue;
          }

          break;
        case 32:
          if (tag === 256) {
            message.allWarmLoadTimes.push(Long.fromString(reader.uint64().toString(), true));

            continue;
          }

          if (tag === 258) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.allWarmLoadTimes.push(Long.fromString(reader.uint64().toString(), true));
            }

            continue;
          }

          break;
        case 33:
          if (tag === 264) {
            message.allExecutionTimes.push(Long.fromString(reader.uint64().toString(), true));

            continue;
          }

          if (tag === 266) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.allExecutionTimes.push(Long.fromString(reader.uint64().toString(), true));
            }

            continue;
          }

          break;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.segmentDetails.push(SegmentDetail.decode(reader, reader.uint32()));
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.runtimeConfig.push(RuntimeConfig.decode(reader, reader.uint32()));
          continue;
        case 36:
          if (tag !== 290) {
            break;
          }

          message.toolVersions.push(ToolVersion.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProfileDetail {
    return {
      executionTime: isSet(object.executionTime) ? Long.fromValue(object.executionTime) : Long.UZERO,
      peakMemoryUsage: isSet(object.peakMemoryUsage) ? Long.fromValue(object.peakMemoryUsage) : Long.UZERO,
      loadTime: isSet(object.loadTime) ? Long.fromValue(object.loadTime) : undefined,
      layerDetails: globalThis.Array.isArray(object?.layerDetails)
        ? object.layerDetails.map((e: any) => LayerDetail.fromJSON(e))
        : [],
      majorVersion: isSet(object.majorVersion) ? Long.fromValue(object.majorVersion) : Long.UZERO,
      minorVersion: isSet(object.minorVersion) ? Long.fromValue(object.minorVersion) : Long.UZERO,
      afterColdLoadPeakMemory: isSet(object.afterColdLoadPeakMemory)
        ? Long.fromValue(object.afterColdLoadPeakMemory)
        : Long.UZERO,
      afterCompilePeakMemory: isSet(object.afterCompilePeakMemory)
        ? Long.fromValue(object.afterCompilePeakMemory)
        : Long.UZERO,
      afterExecutionPeakMemory: isSet(object.afterExecutionPeakMemory)
        ? Long.fromValue(object.afterExecutionPeakMemory)
        : Long.UZERO,
      afterWarmLoadPeakMemory: isSet(object.afterWarmLoadPeakMemory)
        ? Long.fromValue(object.afterWarmLoadPeakMemory)
        : Long.UZERO,
      beforeColdLoadCurrentMemory: isSet(object.beforeColdLoadCurrentMemory)
        ? Long.fromValue(object.beforeColdLoadCurrentMemory)
        : Long.UZERO,
      beforeColdLoadPeakMemory: isSet(object.beforeColdLoadPeakMemory)
        ? Long.fromValue(object.beforeColdLoadPeakMemory)
        : Long.UZERO,
      beforeCompileCurrentMemory: isSet(object.beforeCompileCurrentMemory)
        ? Long.fromValue(object.beforeCompileCurrentMemory)
        : Long.UZERO,
      beforeCompilePeakMemory: isSet(object.beforeCompilePeakMemory)
        ? Long.fromValue(object.beforeCompilePeakMemory)
        : Long.UZERO,
      beforeExecutionCurrentMemory: isSet(object.beforeExecutionCurrentMemory)
        ? Long.fromValue(object.beforeExecutionCurrentMemory)
        : Long.UZERO,
      beforeExecutionPeakMemory: isSet(object.beforeExecutionPeakMemory)
        ? Long.fromValue(object.beforeExecutionPeakMemory)
        : Long.UZERO,
      beforeWarmLoadCurrentMemory: isSet(object.beforeWarmLoadCurrentMemory)
        ? Long.fromValue(object.beforeWarmLoadCurrentMemory)
        : Long.UZERO,
      beforeWarmLoadPeakMemory: isSet(object.beforeWarmLoadPeakMemory)
        ? Long.fromValue(object.beforeWarmLoadPeakMemory)
        : Long.UZERO,
      coldLoadTime: isSet(object.coldLoadTime) ? Long.fromValue(object.coldLoadTime) : Long.UZERO,
      compileTime: isSet(object.compileTime) ? Long.fromValue(object.compileTime) : Long.UZERO,
      warmLoadTime: isSet(object.warmLoadTime) ? Long.fromValue(object.warmLoadTime) : Long.UZERO,
      afterColdLoadCurrentMemory: isSet(object.afterColdLoadCurrentMemory)
        ? Long.fromValue(object.afterColdLoadCurrentMemory)
        : Long.UZERO,
      afterWarmLoadCurrentMemory: isSet(object.afterWarmLoadCurrentMemory)
        ? Long.fromValue(object.afterWarmLoadCurrentMemory)
        : Long.UZERO,
      afterCompileCurrentMemory: isSet(object.afterCompileCurrentMemory)
        ? Long.fromValue(object.afterCompileCurrentMemory)
        : Long.UZERO,
      afterExecutionCurrentMemory: isSet(object.afterExecutionCurrentMemory)
        ? Long.fromValue(object.afterExecutionCurrentMemory)
        : Long.UZERO,
      compileMemory: isSet(object.compileMemory) ? ProfileDetail_MemoryUsage.fromJSON(object.compileMemory) : undefined,
      coldLoadMemory: isSet(object.coldLoadMemory)
        ? ProfileDetail_MemoryUsage.fromJSON(object.coldLoadMemory)
        : undefined,
      warmLoadMemory: isSet(object.warmLoadMemory)
        ? ProfileDetail_MemoryUsage.fromJSON(object.warmLoadMemory)
        : undefined,
      executionMemory: isSet(object.executionMemory)
        ? ProfileDetail_MemoryUsage.fromJSON(object.executionMemory)
        : undefined,
      allCompileTimes: globalThis.Array.isArray(object?.allCompileTimes)
        ? object.allCompileTimes.map((e: any) => Long.fromValue(e))
        : [],
      allColdLoadTimes: globalThis.Array.isArray(object?.allColdLoadTimes)
        ? object.allColdLoadTimes.map((e: any) => Long.fromValue(e))
        : [],
      allWarmLoadTimes: globalThis.Array.isArray(object?.allWarmLoadTimes)
        ? object.allWarmLoadTimes.map((e: any) => Long.fromValue(e))
        : [],
      allExecutionTimes: globalThis.Array.isArray(object?.allExecutionTimes)
        ? object.allExecutionTimes.map((e: any) => Long.fromValue(e))
        : [],
      segmentDetails: globalThis.Array.isArray(object?.segmentDetails)
        ? object.segmentDetails.map((e: any) => SegmentDetail.fromJSON(e))
        : [],
      runtimeConfig: globalThis.Array.isArray(object?.runtimeConfig)
        ? object.runtimeConfig.map((e: any) => RuntimeConfig.fromJSON(e))
        : [],
      toolVersions: globalThis.Array.isArray(object?.toolVersions)
        ? object.toolVersions.map((e: any) => ToolVersion.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProfileDetail): unknown {
    const obj: any = {};
    if (!message.executionTime.equals(Long.UZERO)) {
      obj.executionTime = (message.executionTime || Long.UZERO).toString();
    }
    if (!message.peakMemoryUsage.equals(Long.UZERO)) {
      obj.peakMemoryUsage = (message.peakMemoryUsage || Long.UZERO).toString();
    }
    if (message.loadTime !== undefined) {
      obj.loadTime = (message.loadTime || Long.UZERO).toString();
    }
    if (message.layerDetails?.length) {
      obj.layerDetails = message.layerDetails.map((e) => LayerDetail.toJSON(e));
    }
    if (!message.majorVersion.equals(Long.UZERO)) {
      obj.majorVersion = (message.majorVersion || Long.UZERO).toString();
    }
    if (!message.minorVersion.equals(Long.UZERO)) {
      obj.minorVersion = (message.minorVersion || Long.UZERO).toString();
    }
    if (!message.afterColdLoadPeakMemory.equals(Long.UZERO)) {
      obj.afterColdLoadPeakMemory = (message.afterColdLoadPeakMemory || Long.UZERO).toString();
    }
    if (!message.afterCompilePeakMemory.equals(Long.UZERO)) {
      obj.afterCompilePeakMemory = (message.afterCompilePeakMemory || Long.UZERO).toString();
    }
    if (!message.afterExecutionPeakMemory.equals(Long.UZERO)) {
      obj.afterExecutionPeakMemory = (message.afterExecutionPeakMemory || Long.UZERO).toString();
    }
    if (!message.afterWarmLoadPeakMemory.equals(Long.UZERO)) {
      obj.afterWarmLoadPeakMemory = (message.afterWarmLoadPeakMemory || Long.UZERO).toString();
    }
    if (!message.beforeColdLoadCurrentMemory.equals(Long.UZERO)) {
      obj.beforeColdLoadCurrentMemory = (message.beforeColdLoadCurrentMemory || Long.UZERO).toString();
    }
    if (!message.beforeColdLoadPeakMemory.equals(Long.UZERO)) {
      obj.beforeColdLoadPeakMemory = (message.beforeColdLoadPeakMemory || Long.UZERO).toString();
    }
    if (!message.beforeCompileCurrentMemory.equals(Long.UZERO)) {
      obj.beforeCompileCurrentMemory = (message.beforeCompileCurrentMemory || Long.UZERO).toString();
    }
    if (!message.beforeCompilePeakMemory.equals(Long.UZERO)) {
      obj.beforeCompilePeakMemory = (message.beforeCompilePeakMemory || Long.UZERO).toString();
    }
    if (!message.beforeExecutionCurrentMemory.equals(Long.UZERO)) {
      obj.beforeExecutionCurrentMemory = (message.beforeExecutionCurrentMemory || Long.UZERO).toString();
    }
    if (!message.beforeExecutionPeakMemory.equals(Long.UZERO)) {
      obj.beforeExecutionPeakMemory = (message.beforeExecutionPeakMemory || Long.UZERO).toString();
    }
    if (!message.beforeWarmLoadCurrentMemory.equals(Long.UZERO)) {
      obj.beforeWarmLoadCurrentMemory = (message.beforeWarmLoadCurrentMemory || Long.UZERO).toString();
    }
    if (!message.beforeWarmLoadPeakMemory.equals(Long.UZERO)) {
      obj.beforeWarmLoadPeakMemory = (message.beforeWarmLoadPeakMemory || Long.UZERO).toString();
    }
    if (!message.coldLoadTime.equals(Long.UZERO)) {
      obj.coldLoadTime = (message.coldLoadTime || Long.UZERO).toString();
    }
    if (!message.compileTime.equals(Long.UZERO)) {
      obj.compileTime = (message.compileTime || Long.UZERO).toString();
    }
    if (!message.warmLoadTime.equals(Long.UZERO)) {
      obj.warmLoadTime = (message.warmLoadTime || Long.UZERO).toString();
    }
    if (!message.afterColdLoadCurrentMemory.equals(Long.UZERO)) {
      obj.afterColdLoadCurrentMemory = (message.afterColdLoadCurrentMemory || Long.UZERO).toString();
    }
    if (!message.afterWarmLoadCurrentMemory.equals(Long.UZERO)) {
      obj.afterWarmLoadCurrentMemory = (message.afterWarmLoadCurrentMemory || Long.UZERO).toString();
    }
    if (!message.afterCompileCurrentMemory.equals(Long.UZERO)) {
      obj.afterCompileCurrentMemory = (message.afterCompileCurrentMemory || Long.UZERO).toString();
    }
    if (!message.afterExecutionCurrentMemory.equals(Long.UZERO)) {
      obj.afterExecutionCurrentMemory = (message.afterExecutionCurrentMemory || Long.UZERO).toString();
    }
    if (message.compileMemory !== undefined) {
      obj.compileMemory = ProfileDetail_MemoryUsage.toJSON(message.compileMemory);
    }
    if (message.coldLoadMemory !== undefined) {
      obj.coldLoadMemory = ProfileDetail_MemoryUsage.toJSON(message.coldLoadMemory);
    }
    if (message.warmLoadMemory !== undefined) {
      obj.warmLoadMemory = ProfileDetail_MemoryUsage.toJSON(message.warmLoadMemory);
    }
    if (message.executionMemory !== undefined) {
      obj.executionMemory = ProfileDetail_MemoryUsage.toJSON(message.executionMemory);
    }
    if (message.allCompileTimes?.length) {
      obj.allCompileTimes = message.allCompileTimes.map((e) => (e || Long.UZERO).toString());
    }
    if (message.allColdLoadTimes?.length) {
      obj.allColdLoadTimes = message.allColdLoadTimes.map((e) => (e || Long.UZERO).toString());
    }
    if (message.allWarmLoadTimes?.length) {
      obj.allWarmLoadTimes = message.allWarmLoadTimes.map((e) => (e || Long.UZERO).toString());
    }
    if (message.allExecutionTimes?.length) {
      obj.allExecutionTimes = message.allExecutionTimes.map((e) => (e || Long.UZERO).toString());
    }
    if (message.segmentDetails?.length) {
      obj.segmentDetails = message.segmentDetails.map((e) => SegmentDetail.toJSON(e));
    }
    if (message.runtimeConfig?.length) {
      obj.runtimeConfig = message.runtimeConfig.map((e) => RuntimeConfig.toJSON(e));
    }
    if (message.toolVersions?.length) {
      obj.toolVersions = message.toolVersions.map((e) => ToolVersion.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProfileDetail>, I>>(base?: I): ProfileDetail {
    return ProfileDetail.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProfileDetail>, I>>(object: I): ProfileDetail {
    const message = createBaseProfileDetail();
    message.executionTime = (object.executionTime !== undefined && object.executionTime !== null)
      ? Long.fromValue(object.executionTime)
      : Long.UZERO;
    message.peakMemoryUsage = (object.peakMemoryUsage !== undefined && object.peakMemoryUsage !== null)
      ? Long.fromValue(object.peakMemoryUsage)
      : Long.UZERO;
    message.loadTime = (object.loadTime !== undefined && object.loadTime !== null)
      ? Long.fromValue(object.loadTime)
      : undefined;
    message.layerDetails = object.layerDetails?.map((e) => LayerDetail.fromPartial(e)) || [];
    message.majorVersion = (object.majorVersion !== undefined && object.majorVersion !== null)
      ? Long.fromValue(object.majorVersion)
      : Long.UZERO;
    message.minorVersion = (object.minorVersion !== undefined && object.minorVersion !== null)
      ? Long.fromValue(object.minorVersion)
      : Long.UZERO;
    message.afterColdLoadPeakMemory =
      (object.afterColdLoadPeakMemory !== undefined && object.afterColdLoadPeakMemory !== null)
        ? Long.fromValue(object.afterColdLoadPeakMemory)
        : Long.UZERO;
    message.afterCompilePeakMemory =
      (object.afterCompilePeakMemory !== undefined && object.afterCompilePeakMemory !== null)
        ? Long.fromValue(object.afterCompilePeakMemory)
        : Long.UZERO;
    message.afterExecutionPeakMemory =
      (object.afterExecutionPeakMemory !== undefined && object.afterExecutionPeakMemory !== null)
        ? Long.fromValue(object.afterExecutionPeakMemory)
        : Long.UZERO;
    message.afterWarmLoadPeakMemory =
      (object.afterWarmLoadPeakMemory !== undefined && object.afterWarmLoadPeakMemory !== null)
        ? Long.fromValue(object.afterWarmLoadPeakMemory)
        : Long.UZERO;
    message.beforeColdLoadCurrentMemory =
      (object.beforeColdLoadCurrentMemory !== undefined && object.beforeColdLoadCurrentMemory !== null)
        ? Long.fromValue(object.beforeColdLoadCurrentMemory)
        : Long.UZERO;
    message.beforeColdLoadPeakMemory =
      (object.beforeColdLoadPeakMemory !== undefined && object.beforeColdLoadPeakMemory !== null)
        ? Long.fromValue(object.beforeColdLoadPeakMemory)
        : Long.UZERO;
    message.beforeCompileCurrentMemory =
      (object.beforeCompileCurrentMemory !== undefined && object.beforeCompileCurrentMemory !== null)
        ? Long.fromValue(object.beforeCompileCurrentMemory)
        : Long.UZERO;
    message.beforeCompilePeakMemory =
      (object.beforeCompilePeakMemory !== undefined && object.beforeCompilePeakMemory !== null)
        ? Long.fromValue(object.beforeCompilePeakMemory)
        : Long.UZERO;
    message.beforeExecutionCurrentMemory =
      (object.beforeExecutionCurrentMemory !== undefined && object.beforeExecutionCurrentMemory !== null)
        ? Long.fromValue(object.beforeExecutionCurrentMemory)
        : Long.UZERO;
    message.beforeExecutionPeakMemory =
      (object.beforeExecutionPeakMemory !== undefined && object.beforeExecutionPeakMemory !== null)
        ? Long.fromValue(object.beforeExecutionPeakMemory)
        : Long.UZERO;
    message.beforeWarmLoadCurrentMemory =
      (object.beforeWarmLoadCurrentMemory !== undefined && object.beforeWarmLoadCurrentMemory !== null)
        ? Long.fromValue(object.beforeWarmLoadCurrentMemory)
        : Long.UZERO;
    message.beforeWarmLoadPeakMemory =
      (object.beforeWarmLoadPeakMemory !== undefined && object.beforeWarmLoadPeakMemory !== null)
        ? Long.fromValue(object.beforeWarmLoadPeakMemory)
        : Long.UZERO;
    message.coldLoadTime = (object.coldLoadTime !== undefined && object.coldLoadTime !== null)
      ? Long.fromValue(object.coldLoadTime)
      : Long.UZERO;
    message.compileTime = (object.compileTime !== undefined && object.compileTime !== null)
      ? Long.fromValue(object.compileTime)
      : Long.UZERO;
    message.warmLoadTime = (object.warmLoadTime !== undefined && object.warmLoadTime !== null)
      ? Long.fromValue(object.warmLoadTime)
      : Long.UZERO;
    message.afterColdLoadCurrentMemory =
      (object.afterColdLoadCurrentMemory !== undefined && object.afterColdLoadCurrentMemory !== null)
        ? Long.fromValue(object.afterColdLoadCurrentMemory)
        : Long.UZERO;
    message.afterWarmLoadCurrentMemory =
      (object.afterWarmLoadCurrentMemory !== undefined && object.afterWarmLoadCurrentMemory !== null)
        ? Long.fromValue(object.afterWarmLoadCurrentMemory)
        : Long.UZERO;
    message.afterCompileCurrentMemory =
      (object.afterCompileCurrentMemory !== undefined && object.afterCompileCurrentMemory !== null)
        ? Long.fromValue(object.afterCompileCurrentMemory)
        : Long.UZERO;
    message.afterExecutionCurrentMemory =
      (object.afterExecutionCurrentMemory !== undefined && object.afterExecutionCurrentMemory !== null)
        ? Long.fromValue(object.afterExecutionCurrentMemory)
        : Long.UZERO;
    message.compileMemory = (object.compileMemory !== undefined && object.compileMemory !== null)
      ? ProfileDetail_MemoryUsage.fromPartial(object.compileMemory)
      : undefined;
    message.coldLoadMemory = (object.coldLoadMemory !== undefined && object.coldLoadMemory !== null)
      ? ProfileDetail_MemoryUsage.fromPartial(object.coldLoadMemory)
      : undefined;
    message.warmLoadMemory = (object.warmLoadMemory !== undefined && object.warmLoadMemory !== null)
      ? ProfileDetail_MemoryUsage.fromPartial(object.warmLoadMemory)
      : undefined;
    message.executionMemory = (object.executionMemory !== undefined && object.executionMemory !== null)
      ? ProfileDetail_MemoryUsage.fromPartial(object.executionMemory)
      : undefined;
    message.allCompileTimes = object.allCompileTimes?.map((e) => Long.fromValue(e)) || [];
    message.allColdLoadTimes = object.allColdLoadTimes?.map((e) => Long.fromValue(e)) || [];
    message.allWarmLoadTimes = object.allWarmLoadTimes?.map((e) => Long.fromValue(e)) || [];
    message.allExecutionTimes = object.allExecutionTimes?.map((e) => Long.fromValue(e)) || [];
    message.segmentDetails = object.segmentDetails?.map((e) => SegmentDetail.fromPartial(e)) || [];
    message.runtimeConfig = object.runtimeConfig?.map((e) => RuntimeConfig.fromPartial(e)) || [];
    message.toolVersions = object.toolVersions?.map((e) => ToolVersion.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProfileDetail_MemoryUsage(): ProfileDetail_MemoryUsage {
  return { increase: undefined, peak: undefined };
}

export const ProfileDetail_MemoryUsage: MessageFns<ProfileDetail_MemoryUsage> = {
  encode(message: ProfileDetail_MemoryUsage, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.increase !== undefined) {
      Range.encode(message.increase, writer.uint32(10).fork()).join();
    }
    if (message.peak !== undefined) {
      Range.encode(message.peak, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ProfileDetail_MemoryUsage {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProfileDetail_MemoryUsage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.increase = Range.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.peak = Range.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProfileDetail_MemoryUsage {
    return {
      increase: isSet(object.increase) ? Range.fromJSON(object.increase) : undefined,
      peak: isSet(object.peak) ? Range.fromJSON(object.peak) : undefined,
    };
  },

  toJSON(message: ProfileDetail_MemoryUsage): unknown {
    const obj: any = {};
    if (message.increase !== undefined) {
      obj.increase = Range.toJSON(message.increase);
    }
    if (message.peak !== undefined) {
      obj.peak = Range.toJSON(message.peak);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProfileDetail_MemoryUsage>, I>>(base?: I): ProfileDetail_MemoryUsage {
    return ProfileDetail_MemoryUsage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProfileDetail_MemoryUsage>, I>>(object: I): ProfileDetail_MemoryUsage {
    const message = createBaseProfileDetail_MemoryUsage();
    message.increase = (object.increase !== undefined && object.increase !== null)
      ? Range.fromPartial(object.increase)
      : undefined;
    message.peak = (object.peak !== undefined && object.peak !== null) ? Range.fromPartial(object.peak) : undefined;
    return message;
  },
};

function createBaseCompileDetail(): CompileDetail {
  return { toolVersions: [] };
}

export const CompileDetail: MessageFns<CompileDetail> = {
  encode(message: CompileDetail, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.toolVersions) {
      ToolVersion.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CompileDetail {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompileDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.toolVersions.push(ToolVersion.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompileDetail {
    return {
      toolVersions: globalThis.Array.isArray(object?.toolVersions)
        ? object.toolVersions.map((e: any) => ToolVersion.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CompileDetail): unknown {
    const obj: any = {};
    if (message.toolVersions?.length) {
      obj.toolVersions = message.toolVersions.map((e) => ToolVersion.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompileDetail>, I>>(base?: I): CompileDetail {
    return CompileDetail.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompileDetail>, I>>(object: I): CompileDetail {
    const message = createBaseCompileDetail();
    message.toolVersions = object.toolVersions?.map((e) => ToolVersion.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLinkDetail(): LinkDetail {
  return { toolVersions: [] };
}

export const LinkDetail: MessageFns<LinkDetail> = {
  encode(message: LinkDetail, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.toolVersions) {
      ToolVersion.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LinkDetail {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLinkDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.toolVersions.push(ToolVersion.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LinkDetail {
    return {
      toolVersions: globalThis.Array.isArray(object?.toolVersions)
        ? object.toolVersions.map((e: any) => ToolVersion.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LinkDetail): unknown {
    const obj: any = {};
    if (message.toolVersions?.length) {
      obj.toolVersions = message.toolVersions.map((e) => ToolVersion.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LinkDetail>, I>>(base?: I): LinkDetail {
    return LinkDetail.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LinkDetail>, I>>(object: I): LinkDetail {
    const message = createBaseLinkDetail();
    message.toolVersions = object.toolVersions?.map((e) => ToolVersion.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQuantizeDetail(): QuantizeDetail {
  return { toolVersions: [], psnr: {} };
}

export const QuantizeDetail: MessageFns<QuantizeDetail> = {
  encode(message: QuantizeDetail, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.toolVersions) {
      ToolVersion.encode(v!, writer.uint32(10).fork()).join();
    }
    Object.entries(message.psnr).forEach(([key, value]) => {
      QuantizeDetail_PsnrEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QuantizeDetail {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantizeDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.toolVersions.push(ToolVersion.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = QuantizeDetail_PsnrEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.psnr[entry2.key] = entry2.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantizeDetail {
    return {
      toolVersions: globalThis.Array.isArray(object?.toolVersions)
        ? object.toolVersions.map((e: any) => ToolVersion.fromJSON(e))
        : [],
      psnr: isObject(object.psnr)
        ? Object.entries(object.psnr).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: QuantizeDetail): unknown {
    const obj: any = {};
    if (message.toolVersions?.length) {
      obj.toolVersions = message.toolVersions.map((e) => ToolVersion.toJSON(e));
    }
    if (message.psnr) {
      const entries = Object.entries(message.psnr);
      if (entries.length > 0) {
        obj.psnr = {};
        entries.forEach(([k, v]) => {
          obj.psnr[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantizeDetail>, I>>(base?: I): QuantizeDetail {
    return QuantizeDetail.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QuantizeDetail>, I>>(object: I): QuantizeDetail {
    const message = createBaseQuantizeDetail();
    message.toolVersions = object.toolVersions?.map((e) => ToolVersion.fromPartial(e)) || [];
    message.psnr = Object.entries(object.psnr ?? {}).reduce<{ [key: string]: number }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.Number(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseQuantizeDetail_PsnrEntry(): QuantizeDetail_PsnrEntry {
  return { key: "", value: 0 };
}

export const QuantizeDetail_PsnrEntry: MessageFns<QuantizeDetail_PsnrEntry> = {
  encode(message: QuantizeDetail_PsnrEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(21).float(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QuantizeDetail_PsnrEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantizeDetail_PsnrEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.value = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QuantizeDetail_PsnrEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: QuantizeDetail_PsnrEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuantizeDetail_PsnrEntry>, I>>(base?: I): QuantizeDetail_PsnrEntry {
    return QuantizeDetail_PsnrEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QuantizeDetail_PsnrEntry>, I>>(object: I): QuantizeDetail_PsnrEntry {
    const message = createBaseQuantizeDetail_PsnrEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Long ? string | number | Long : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = numberToLong(Math.trunc(date.getTime() / 1_000));
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds.toNumber() || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function numberToLong(number: number) {
  return Long.fromNumber(number);
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
